import { getReportHeaders } from '../../../Utils/ImportDetailsResult';
import { getFormattedDate, calculateDateFromIndex } from '../../../Utils/DateFormatter';
import { getPrintFormattedData } from '../../../Utils/PrintPopup';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { uiConstants } from '../../../Constants/UI.Constants';
var $ = require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

$.Datatable = require('datatables.net-bs4');

class ReportDetailsSummary extends Component {

    componentDidMount() {

        $.fn.DataTable.ext.pager.numbers_length = 5;

        //GETTING DATE PROPS AND REPORT TYPE
        let reportType;
        let category;
        let fromDate = calculateDateFromIndex(this.props.dashboard.selectedDateRange).fromDate;
        let toDate = calculateDateFromIndex(this.props.dashboard.selectedDateRange).toDate;
        reportType = this.props.reportType;
        category = this.props.reportCategory;
        let formattedDates = getFormattedDate(fromDate, toDate);
        let reportHeader = getReportHeaders(reportType, category);
        let dateRange = formattedDates.fDateVal + ' through ' + formattedDates.tDateVal;
        let importReport = this.props.importReport;
        let reportGen = importReport.summaryData.data.UserTimeZone;
        let titleActionSuccess = this.props.resSecHeader;
        let institutionDetails = this.props.login;
        let institutionName = institutionDetails.orgNodeData.OrgNodeName;
        let statusDetails = this.props.statusData;
        this.$statusSummary = $(this.statusData);
        this.$statusSummary.DataTable(
            {
                "data": statusDetails,
                "columns": uiConstants.ReportDetailStatusHeaders,
                "pageLength": 25,
                "responsive": true,
                "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
                "dom":
                     "Bft" +
                     "<'ml-auto w-100 d-md-flex pt-4'lp>",

                "language": {
                    "search": "",
                    "searchPlaceholder": "Quick Search",
                    "sLengthMenu": "Show _MENU_ records",
                    "paginate": {
                        "next": '<i class="icon-caret-right"></i>',
                        "previous": '<i class="icon-caret-left"></i>'
                    }
                },
                "autoWidth": false,
                "columnDefs": [
                    { targets: 0, type: 'date' }
                ],
                "buttons": [
                    {
                        //PRINT BUTTON EXTENSION AND CUSTOMIZING
                        extend: 'print', className: "buttonsToHide", footer: true,
                        title: $(uiConstants.StatusSectionHeader)[0].toUpperCase() + "<hr>",
                        customize: function (printWindow) {
                            //Condition to check whether the Action/Error details require to show on print.
                            category = (Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val === reportType.toString()) && category === uiConstants.ImportStatus.Success) === true ? uiConstants.ImportStatus.InProgress : category;
                            getPrintFormattedData(titleActionSuccess, printWindow, institutionName, dateRange, reportGen,
                                statusDataTable, reportHeader, category, reportType, statusDetails.length);
                        }
                    },
                ],
                select: true
            }
        );
        //printDataTable.buttons().container().appendTo(".btn-toolbar");
        if (!(this.props.reportCategory === uiConstants.ImportStatus.InProgress)) {
            this.$actionSummary = $(this.actionData);
            //Condition to check whether the actionSummary Datatable require to initialize.
            if (!((Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val === this.props.reportType.toString()) && this.props.reportCategory === uiConstants.ImportStatus.Success))) {
                this.$actionSummary.DataTable(
                    {
                        "data": this.props.actionData,
                        "columns": this.props.actionHeader,
                        "autoWidth": false,
                        "columnDefs": [
                            {
                                "targets": 0, type: "date", responsivePriority: 1
                            },
                            { responsivePriority: 2, targets: 1 },
                            { responsivePriority: 3, targets: 2 },
                            { responsivePriority: 4, targets: 3 }
                        ],
                        "pageLength": 25,
                        "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
                        "dom": "ft" +
                            "<'ml-auto w-100 d-md-flex pt-4'lp>",
                        "language": {
                            "search": "",
                            "searchPlaceholder": "Quick Search",
                            "sLengthMenu": "Show _MENU_ records",
                            "paginate": {
                                "next": '<i class="icon-caret-right"></i>',
                                "previous": '<i class="icon-caret-left"></i>'
                            }
                        }
                    }
                );
            }

        }
        //TO HIDE DEFAULT PRINT BUTTON  
        var statusDataTable = this.$statusSummary.DataTable();
        statusDataTable.buttons('.buttonsToHide').nodes().addClass('hidden');
    }

    render() {
        //Condition to check whether the actionSummary Datatable require to show.
        let showActionSectionTable = (!
            (this.props.reportCategory === uiConstants.ImportStatus.InProgress || ((Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val === this.props.reportType.toString())) && this.props.reportCategory === uiConstants.ImportStatus.Success)))

        return (
            <div>
                
                <div className="dataTable-container">
                <div className="tableHeader"> {uiConstants.StatusSectionHeader}</div>
                    {
                        <table id="status" className="table table-striped dt-responsive" ref={statusData => this.statusData = statusData}>
                        </table>
                    }
                    <div className="top-records-msg">{uiConstants.DataTableMessageInfo}</div>

                </div>
                {
                    showActionSectionTable &&
                    <>
                        
                        <div className="dataTable-container">
                        <div className="tableHeader"> {this.props.resSecHeader}</div>
                            {
                                <table id="action" className="table table-striped dt-responsive" ref={actionData => this.actionData = actionData}></table>
                            }
                            <div className="top-records-msg">{uiConstants.DataTableMessageInfo}</div>
                        </div>
                    </>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { dashboard, importReport, login } = state;
    return {
        dashboard,
        importReport,
        login
    };
}
export default connect(mapStateToProps)(ReportDetailsSummary);
