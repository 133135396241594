import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'datatables.net-buttons';
import $ from 'jquery';
import { scheduleTaskAction } from '../../Actions/ScheduledTask.Action';
import moment from 'moment';
import '../../Assets/Css/app.min.css';
$.Datatable = require('datatables.net-bs4');


$.noConflict();
class ScheduleManageDetails extends Component {

    constructor(props) {
        super(props);
        this.handleReportScheduleCreate = this.handleReportScheduleCreate.bind(this);
    }

    handleReportScheduleCreate() {
        this.props.dispatch(scheduleTaskAction.toggleScheduleCreateModal(this.props.dashboard.reportType));
    }

    componentDidMount() {
        let ScheduleIdgen;
        let data = this.props.dashboard.scheduleData;
        let a = data.map(item => [item.ScheduleId, item.Description, moment(new Date(item.CreateDate)).format("MM/DD/YYYY"), this.bindFrequencyType(item.FrequencyType)]);
        this.state = { data: a };

        document.getElementById("btnCreateSchedule").style.display = "block";
        document.getElementById("deleteconfirm").style.display = "none";
        var x = document.getElementById("deleteconfirm");
        let scheduleDetails = this.props.scheduleData;
        $.noConflict();

        this.$el = $(this.el);
       var abc = this.$el.DataTable({
            "data": this.state.data,
            "paging": false,
            "bFilter": false,
            "bInfo": false,
            "searching": false,
            "ordering": false,
            "autoWidth": true,
            "columnDefs": [
                { targets: [0], title: "Schedule Id", "visible": false, },
                { targets: [1], title: "Report Name", className: "text-scheduleManage text-left wrapName" },
                { targets: [2], title: "Date Created", className: "text-scheduleManage text-left" },
                { targets: [3], title: "Frequency", className: "text-scheduleManage text-left" },
                {
                    targets: [4], title: "Manage",
                    data: null,
                    className: "text-center text-scheduleManage",
                    defaultContent: '<i id="edit" class="icon-pencil" ></i> <i id="delete" class="icon-trash-o"></i>'
                }
            ]
        });
     
        // Delete a record
        $('#manageSchedule').on('click', 'i#delete', function (e) {
            e.preventDefault();
            var selectedRow = $(this).closest('tr');
            if (selectedRow.hasClass('child')) {
                selectedRow = selectedRow.prev();
            }
            selectedRow.addClass('selected');
            x.style.display = "inline-block";
        });

        $('#manageSchedule tbody').on('click', 'td #delete', function (e) {

            e.preventDefault();
            if ($('tr.selected').hasClass('selected')) {

                $('tr.selected').removeClass('selected');
            }

            $(this).closest('tr').addClass('selected');
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        });

        $('#manageSchedule').on('click', 'i#edit', function (e) {
            e.preventDefault();

            var selectedRow = $(this).closest('tr');
            if (selectedRow.hasClass('child')) {
                selectedRow = selectedRow.prev();
            }
            selectedRow.addClass('selected');
            ScheduleIdgen = $('#manageSchedule').DataTable().row('.selected').data()[0];

            $('#btnCreateSchedule').trigger('click');
        });

        $('#btnYes').on('click', (e) => {

            let scheduleId = $('#manageSchedule').DataTable().row('.selected').data()[0];

            this.props.dispatch(scheduleTaskAction.deleteScheduledTasksReportDetails(this.props.dashboard.reportType, scheduleId));

            $('#manageSchedule').DataTable().row('.selected').remove().draw(false);
            x.style.display = "none";
        });

        $('#btnNo').on('click', function (e) {
            x.style.display = "none";
            $('#manageSchedule').DataTable().$('tr.selected').removeClass('selected');
        });

        $('#btnCreateSchedule').on('click', (e) => {
            if (ScheduleIdgen !== undefined) {
                this.props.dispatch(scheduleTaskAction.toggleScheduleCreateModal(this.props.dashboard.reportType, ScheduleIdgen));
            }
        });
    }

    bindFrequencyType(frequency) {
        if (frequency == '4')
            return "Daily";
        else if (frequency == '8')
            return "Weekly";
        else if (frequency == '16' || frequency == '32')
            return "Monthly";
        else if (frequency == '64' || frequency == '128')
            return "Quarterly";
    }

    render() {
        const fullWidth = {
            width: "100%"
        };
        return <div id="manageScheduleForm" className={"container-fluid"}>
            <table className="tabHeight1">
                <tr>
                    <div className="alert alert-danger" id="deleteconfirm">
                        <div className="alert-heading" style={{height:'34px'}}>
                        <i className={"text-danger icon-exclamation-triangle"} style={{ position: 'relative!important', marginTop: '15px'}}></i>  Are you sure you want to delete your scheduled report?
                        <button type="button" id="btnNo" className="btn btn-danger btn-align float-right">No</button>
                        <button type="button" id="btnYes" className="btn btn-success float-right">Yes</button>
                    </div>
                    </div>
                </tr>
            </table>
            <div className="row status-add-new-schedule flex-row-reverse" style={{ marginRight: '0px' }}>
                <button id="btnCreateSchedule" name="btnCreateSchedule" className="btn btn-success float-right" onClick={this.handleReportScheduleCreate}>Add New Schedule</button>
            </div>

            {
                <div className={""}>
                    <table id="manageSchedule" style={fullWidth} ref={el => this.el = el} className="table table-striped dt-responsive"></table>
                </div>
            }

            <div className="modal-footer">
                <div className="pull-right">
                    <button id="closeBtn" className="btn btn-secondary" data-dismiss="modal"
                        onClick={this.props.closeData} >Close</button>
                </div>
            </div>

        </div>
    }
}

function mapStateToProps(state) {
    const { dashboard, importReport } = state;
    return {
        dashboard,
        importReport
    };
}

export default connect(mapStateToProps)(ScheduleManageDetails);