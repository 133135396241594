import React from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from "react-bootstrap";
import { importSummaryActions } from '../../../Actions/ImportSummary.Action';
import { uiConstants } from '../../../Constants/UI.Constants';

class SummaryWidgetcontent extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleShowReportModal = this.handleShowReportModal.bind(this);
    }

    handleShowReportModal(event) {
        let importStatus = event.currentTarget.getAttribute('data-status');
        this.props.dispatch(importSummaryActions.showReportModal(this.props.content.ReportTypeId, importStatus));
    }

    calculatePercentage(total, reportCount) {
        let percentage = 0;
        percentage = Math.round((reportCount / total) * 100);
        return percentage || 0;
    }

    render() {

        const importData = this.props.content;

        let successPercentage = this.calculatePercentage(importData.TotalCount, importData.SuccessCount);
        let errorPercentage = this.calculatePercentage(importData.TotalCount, importData.FailureCount);
        let inProgressPercentage = this.calculatePercentage(importData.TotalCount, importData.InProgressCount);

        return (

            <div className="import-result-section row pr-0 hoverWidget">
                {/* Loading overlay for widget */}
                {importData.loading && <div className={"overlay d-flex col-12 py-4 justify-content-center"}><i className="icon-spinner spin slow"></i></div>}
                <div className="col-sm-12 col-md-4 pr-xs-4">
                    {/* Inprogress widget disable overlay */}
                    {importData.InProgressCount === 0 && <div className="overlay-inprogress"></div>}
                    <div onClick={this.handleShowReportModal} data-status={uiConstants.ImportStatus.InProgress}
                        data-toggle="modal" className="import-result-container import-result-inprogress">
                        <div className="row px-3">
                            <div className="col px-0">
                                <div className="import-result">{uiConstants.ImportStatusInProgress}</div>
                            </div>
                            <div className="col px-0">
                                <div className="import-result-value">{importData.InProgressCount}</div>
                            </div>
                        </div>
                        <div>
                            <ProgressBar
                                variant="warning"
                                now={inProgressPercentage}
                            />
                            <div className="import-result-percent">{inProgressPercentage}%</div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 pr-xs-4">
                    {/* Success widget disable overlay */}
                    {importData.SuccessCount === 0 && <div className="overlay-success"></div>}
                    <div onClick={this.handleShowReportModal} data-status={uiConstants.ImportStatus.Success} data-toggle="modal"
                        className="import-result-container import-result-success">
                        <div className="row px-3">
                            <div className="col px-0">
                                <div className="import-result">{uiConstants.ImportStatusSuccess}</div>
                            </div>
                            <div className="col px-0">
                                <div className="import-result-value">{importData.SuccessCount}</div>
                            </div>
                        </div>
                        <div>
                            <ProgressBar
                                variant="success"
                                now={successPercentage}
                            />
                            <div className="import-result-percent">{successPercentage}%</div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 pr-xs-4">
                    {/* Error widget disable overlay */}
                    {importData.FailureCount === 0 && <div className="overlay-error"></div>}
                    <div onClick={this.handleShowReportModal} data-status={uiConstants.ImportStatus.Error} data-toggle="modal"
                        className="import-result-container import-result-error">
                        <div className="row px-3">
                            <div className="col px-0">
                                <div className="import-result">{uiConstants.ImportStatusError}</div>
                            </div>
                            <div className="col px-0">
                                <div className="import-result-value">{importData.FailureCount}</div>
                            </div>
                        </div>
                        <div>
                            <ProgressBar
                                variant="danger"
                                now={errorPercentage}
                            />
                            <div className="import-result-percent">{errorPercentage}%</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { dashboard } = state;
    return {
        dashboard
    };
}

export default connect(mapStateToProps)(SummaryWidgetcontent);
