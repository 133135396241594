import axios from 'axios';
import { config } from '../../config';

export const scheduledTaskService = {
    scheduleTaskInsert,
    getScheduledTasks,
    getScheduledTaskbyId,
    deleteScheduledTask
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

function scheduleTaskInsert(data,token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.post(config.api + '/scheduledreport/insert',data ,{
        headers: headerValues,
    }); 
    
}

function getScheduledTasks(reportType, token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    
    return axios.get((config.api + '/scheduledreport/get?reportType=' + reportType), {
        headers: headerValues,
    });
}

function getScheduledTaskbyId(scheduleId , token){

    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/scheduledreport/getbyid?scheduleId=' + scheduleId), {
        headers: headerValues,
    });
}

function deleteScheduledTask(reportType, scheduleId, token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    
    let data = {
        "scheduleId" : scheduleId
    }
    
    return axios.delete((config.api + '/scheduledreport/delete?scheduleId=' + scheduleId), {
        headers: headerValues,
    });
}
