import React from 'react';
import { uiConstants } from '../../Constants/UI.Constants'

const PageError = (show) => {
    return (
        show &&
        <div className="center-block">
            <h2 className="no-data-found">
                {uiConstants.ServiceNotAvailable}
            </h2>
            <p className="subtitle-lg text-center">
                {uiConstants.TryAgainLater}
            </p>
        </div>
    )
}

export default PageError;