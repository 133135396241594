import React from 'react';
import { uiConstants } from '../../../Constants/UI.Constants';
import { connect } from 'react-redux';
const ImportError = (content) => {
    const loading = content.loading;
    const error = content.content.hasError ? true : false;

    var message = error ? uiConstants.ServiceNotAvailable : uiConstants.NoImportData;
    var note = error ? uiConstants.TryAgainLater : null;

    return (
        <div className="report loading import-result-section">
            {
                loading && <div className={"overlay d-flex col-12 py-4 justify-content-center"}><i className="icon-spinner spin slow"></i></div>
            }
            <div className="clearfix">
                <div className="import-result-container-single import-result-noresults">
                    <div className={note ? 'service-unavailable' : 'text-center no-data-found'}>
                        {message}
                    </div>
                    <div className="subtitle-md">
                        {note && note}
                    </div>
                </div>
            </div>
        </div >
    )
}

function mapStateToProps(state) {
    const { dashboard } = state;
    return {
        dashboard
    };
}

export default connect(mapStateToProps)(ImportError);