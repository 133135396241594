import { uiConstants } from "../Constants/UI.Constants";
import moment from 'moment';

function calculateDateFromIndex(index) {

    var now = new Date();
    var current_datetime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    let fromDate;
    let newDate;

    let toDate = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds();
    if (index === 0) {
        current_datetime = current_datetime.setDate(current_datetime.getDate() - 1);
        newDate = new Date(current_datetime);
        fromDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
    }
    else if (index === 1) {
        current_datetime = current_datetime.setDate(current_datetime.getDate() - 3);
        newDate = new Date(current_datetime);
        fromDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
    }
    else if (index === 2) {
        current_datetime = current_datetime.setDate(current_datetime.getDate() - 7);
        newDate = new Date(current_datetime);
        fromDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
    }
    else if (index === 3) {
        current_datetime = current_datetime.setDate(current_datetime.getDate() - 14);
        newDate = new Date(current_datetime);
        fromDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
    }
    else if (index === 4) {
        current_datetime = current_datetime.setDate(current_datetime.getDate() - 30);
        newDate = new Date(current_datetime);
        fromDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
    }
    else if (index === uiConstants.DateRangeFilters.length - 1) {
        let customDates = JSON.parse(sessionStorage.getItem('customDates'));
        fromDate = customDates.fromDate;
        toDate = customDates.toDate;
    }
    else {
        current_datetime = current_datetime.setDate(current_datetime.getDate() - 1);
        newDate = new Date(current_datetime);
        fromDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
    }  
    return { fromDate, toDate };
}

function getFormattedDate(fromDate, toDate) {
    let fDateVal, tDateVal, curDateVal, convtfDate, convtDate,
        fDateTime, fDateTime2, tDateTime, tDateTime2,
        fdateMon, fDateDay,
        tdateMon, tDateDay,
        fdateHr, tdateHr,
        fdateMin, tdateMin;
    var currentDateTime = new Date();
    var currentDate = new Date(currentDateTime).toUTCString();

    //fromDate Month
    fdateMon = fromDate.split(" ")[0];
    let fActualdateMon = fdateMon.split("-")[1].length < 2 ? "0" + fdateMon.split("-")[1] : fdateMon.split("-")[1];

    //fromDate Day
    fDateDay = fromDate.split(" ")[0];
    let fActualdateDay = fDateDay.split("-")[2].length < 2 ? "0" + fDateDay.split("-")[2] : fDateDay.split("-")[2];

    //toDate Month
    tdateMon = toDate.split(" ")[0];
    let tActualdateMon = tdateMon.split("-")[1].length < 2 ? "0" + tdateMon.split("-")[1] : tdateMon.split("-")[1];

    //toDate Day
    tDateDay = toDate.split(" ")[0];
    let tActualDateDay = tDateDay.split("-")[2].length < 2 ? "0" + tDateDay.split("-")[2] : tDateDay.split("-")[2];

    //fromDate Hrs
    fdateHr = fromDate.split(" ")[1];
    let fActualdateHr = fdateHr.split(":")[0].length < 2 ? "0" + fdateHr.split(":")[0] : fdateHr.split(":")[0];

    //toDate Hrs
    tdateHr = toDate.split(" ")[1];
    let tActualdateHr = tdateHr.split(":")[0].length < 2 ? "0" + tdateHr.split(":")[0] : tdateHr.split(":")[0];

    //fromDate Min
    fdateMin = fromDate.split(" ")[1];
    let fActualdateMin = fdateMin.split(":")[1].length < 2 ? "0" + fdateMin.split(":")[1] : fdateMin.split(":")[1];

    //toDate Min
    tdateMin = fromDate.split(" ")[1];
    let tActualdateMin = tdateMin.split(":")[1].length < 2 ? "0" + tdateMin.split(":")[1] : tdateMin.split(":")[1];

    //fromdate sec
    fDateTime = fromDate.split(" ")[1];
    fDateTime2 = fDateTime.split(":")[2].length < 2 ? "0" + fDateTime.split(":")[2] : fDateTime.split(":")[2]

    //toDate sec
    tDateTime = toDate.split(" ")[1];
    tDateTime2 = tDateTime.split(":")[2].length < 2 ? "0" + tDateTime.split(":")[2] : tDateTime.split(":")[2];

    //main.
    convtfDate = fdateMon.split("-")[0] + "-" + fActualdateMon + "-" + fActualdateDay + "T" + fActualdateHr + ":" + fActualdateMin + ":" + fDateTime2 + ".000Z";
    convtDate = tdateMon.split("-")[0] + "-" + tActualdateMon + "-" + tActualDateDay + "T" + tActualdateHr + ":" + tActualdateMin + ":" + tDateTime2 + ".000Z";

    var fDate = new Date(convtfDate).toUTCString();
    var tDate = new Date(convtDate).toUTCString();
    var timePeriod = currentDateTime.toLocaleTimeString().split('').slice(8, 11).join('');

    curDateVal = currentDate.split('').slice(8, 11).join('') + currentDate.split('').slice(4, 7).join('') + ', ' + currentDate.split('').slice(12, 16).join('') + ', ' + currentDateTime.toLocaleTimeString().split('').slice(0, 4).join('') + ' ' + timePeriod.toLowerCase() + ' ET';
    fDateVal = fDate.split('').slice(8, 11).join('') + fDate.split('').slice(4, 7).join('') + ', ' + fDate.split('').slice(12, 16).join('');
    tDateVal = tDate.split('').slice(8, 11).join('') + tDate.split('').slice(4, 7).join('') + ', ' + tDate.split('').slice(12, 16).join('');

    return { fDateVal, tDateVal, curDateVal };
}


function getExportFileDate() {
    var reportGen = JSON.parse(sessionStorage.getItem("reportGenTime"));
    var current_date = new Date();
    var dateValue = reportGen.reportGenTime.split(',')[0].split(' ')[1];
    var monSplit = reportGen.reportGenTime.split(',')[0].substring(0, 3);
    var datetimeSplit = reportGen.reportGenTime.split(':');
    var timeSplit = datetimeSplit[0].split(' ');

    let monthVal = new Date().toString().split(" ");
    monthVal[1] = monSplit;
    monthVal = new Date(monthVal.join(' ')).getMonth() + 1;
    monthVal = monthVal.toString().length < 2 ? "0"+ monthVal : monthVal;

    var exportDate;
    var timeConvert = reportGen.reportGenTime.split(':')[1].substring(3, 5) === "pm" ? parseInt(timeSplit[3]) + 12 : parseInt(timeSplit[3]);
    var hourValue = timeConvert.toString().length< 2 ? "0" + timeConvert : timeConvert;    
    var secValue = current_date.getSeconds().toString().length < 2 ? "0" + current_date.getSeconds() : current_date.getSeconds();
    var minValue = datetimeSplit[1].substring(0, 2).toString().length < 2 ? "0" + datetimeSplit[1].substring(0, 2) : datetimeSplit[1].substring(0, 2);

    exportDate = monthVal + dateValue + String(current_date.getFullYear()).substring(2, 4) + "-" + hourValue + minValue + secValue;

    return exportDate;
}

function formatAndStoreCustomDates(fromDate, toDate) {
    sessionStorage.removeItem('customDates');
    let formattedFromDate, formattedToDate;

    formattedFromDate = moment.utc((fromDate)).format('YYYY-MM-DD').toString() + ' 00:00:00';
    formattedToDate = moment.utc((toDate)).format('YYYY-MM-DD').toString() + ' 23:59:59';

    sessionStorage.setItem('customDates', JSON.stringify({
        fromDate: formattedFromDate,
        toDate: formattedToDate
    }));
}

export { calculateDateFromIndex, getFormattedDate, getExportFileDate, formatAndStoreCustomDates }
