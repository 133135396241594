import React from 'react';
import SummaryWidgetHeader from './SummaryWidgetHeader';
import SummaryWidgetContent from './SummaryWidgetContent';
import ImportError from './ImportError';

class SummaryWidget extends React.Component {

    render() {

        const reportSummaryData = this.props.reportSummaryData;
        const showContent = (reportSummaryData.TotalCount === 0 || reportSummaryData.hasError) ? true : false;

        return (
            <div className={reportSummaryData.loading ? "report loading" : "report"}>
                <SummaryWidgetHeader
                    activeDateRange={this.props.activeDateRange}
                    loading={reportSummaryData.loading}
                    widgetData={reportSummaryData}
                />
                {
                    !(showContent) &&
                    <SummaryWidgetContent
                        content={reportSummaryData}
                    />
                }
                {
                    showContent &&
                    <ImportError
                        content={reportSummaryData}
                        loading={reportSummaryData.loading}
                    />
                }
            </div>
        );
    }
}

export default SummaryWidget;