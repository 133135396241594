import { studentImportService } from '../Services/ImportReports/StudentImport.Service';
import { importConstants } from '../Constants/Import.Constants';
import * as TransformData from '../Utils/ReportDataTransform';
import { studentJobFunctionService } from '../Services/ImportReports/StudentJobFunction.Service';
import { studentLicenseImportService } from '../Services/ImportReports/StudentLicenseImport.Service';
import { roleImportService } from '../Services/ImportReports/RoleImport.Service';
import { supervisorImportService } from '../Services/ImportReports/SupervisorImport.Service';
import { loginService } from '../Services/Dashboard/Login.Service';
import { tokenService } from '../Services/Dashboard/Token.Service';
import { uiConstants } from '../Constants/UI.Constants';
import { studentCertificationImportService } from '../Services/ImportReports/StudentCertificationImport.Service';
import { studentBadgeImportService } from '../Services/ImportReports/StudentBadgeImport.Service';
import { studEduHistoryImportService } from '../Services/ImportReports/StudEduHistoryImport.Service';
import { jobTitleImportService } from '../Services/ImportReports/JobTitleImport.Service';
import { supplementalManagerImportService } from '../Services/ImportReports/SupplementalManagerImport.Service';
import { studPortfolioEduImportService } from '../Services/ImportReports/StudPortfolioEduImport.Service';
export const importActions = {
    getImportReportSummaryDetails
}

let responseData;
function getImportReportSummaryDetails(dates, isExport, importStatus, reportTypeId, exportSummaryReport) {

    if (importStatus === uiConstants.ImportStatus.InProgress) {
        return getInProgressSummaryReportDetails(dates, reportTypeId, isExport, exportSummaryReport);
    }
    else if (importStatus === uiConstants.ImportStatus.Success) {
        return getSuccessSummaryReportDetails(dates, reportTypeId, isExport, exportSummaryReport);
    }
    else {
        return getErrorSummaryReportDetails(dates, reportTypeId, isExport, exportSummaryReport);
    }
}

//Gets summary of in progress details for Summary popup as well as export.
function getInProgressSummaryReportDetails(selectedDates, reportTypeId, isExport, exportSummaryReport) {
    let dataObject;
    return dispatch => {
        dispatch(request());

        tokenService.getAuthToken()
            .then(
                response => {
                    getInProgressData(selectedDates.fromDate, selectedDates.toDate, reportTypeId, isExport, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {
                                    responseData = (isExport) ? res.data : transformReportInProgressData(res.data, reportTypeId);
                                    dataObject = {
                                        reportTypeId: reportTypeId,
                                        importStatus: uiConstants.ImportStatus.InProgress,
                                        data: responseData,
                                    }
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));
                                if (exportSummaryReport) exportSummaryReport();
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure(error));
                        });
                }
            );
    };

    function request() {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_REQUEST } : { type: importConstants.GET_IMPORT_REPORT_REQUEST };
        return actionType;
    }
    function success(reportsData) {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_INPROGRESS, reportsData } : { type: importConstants.GET_IMPORT_REPORT_INPROGRESS, reportsData };
        return actionType;
    }
    function failure(error) {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_ERROR_DETAIL, error } : { type: importConstants.GET_IMPORT_REPORT_ERROR_DETAIL, error };
        return actionType;
    }
}

//Gets summary of Success details for Summary popup as well as export.
function getSuccessSummaryReportDetails(selectedDates, reportTypeId, isExport, exportSummaryReport) {
    let dataObject;
    return dispatch => {
        dispatch(request());

        tokenService.getAuthToken()
            .then(
                response => {
                    getSuccessData(selectedDates.fromDate, selectedDates.toDate, reportTypeId, isExport, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {
                                    responseData = (isExport) ? res.data : transformReportSuccessData(res.data, reportTypeId);
                                    dataObject = {
                                        reportTypeId: reportTypeId,
                                        importStatus: uiConstants.ImportStatus.Success,
                                        data: responseData,
                                    }
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));
                                if (exportSummaryReport) exportSummaryReport();
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure(error));
                        });
                }
            );
    };

    function request() {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_REQUEST } : { type: importConstants.GET_IMPORT_REPORT_REQUEST };
        return actionType;
    }
    function success(reportsData) {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_SUCCESS, reportsData } : { type: importConstants.GET_IMPORT_REPORT_SUCCESS, reportsData };
        return actionType;
    }
    function failure(error) {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_ERROR_DETAIL, error } : { type: importConstants.GET_IMPORT_REPORT_ERROR_DETAIL, error };
        return actionType;
    }
}

//Gets summary of Error details for Summary popup as well as Export.
function getErrorSummaryReportDetails(selectedDates, reportTypeId, isExport, exportSummaryReport) {
    let dataObject;
    return dispatch => {
        dispatch(request());

        tokenService.getAuthToken()
            .then(
                response => {
                    getErrorData(selectedDates.fromDate, selectedDates.toDate, reportTypeId, isExport, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {
                                    responseData = (isExport) ? res.data : transformReportErrorData(res.data, reportTypeId);
                                    dataObject = {
                                        reportTypeId: reportTypeId,
                                        importStatus: uiConstants.ImportStatus.Error,
                                        data: responseData,
                                    };
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));
                                if (exportSummaryReport) exportSummaryReport();
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure(error));
                        });
                }
            );
    };

    function request() {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_REQUEST } : { type: importConstants.GET_IMPORT_REPORT_REQUEST }
        return actionType;
    }
    function success(reportsData) {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_ERROR, reportsData } : { type: importConstants.GET_IMPORT_REPORT_ERROR, reportsData }
        return actionType;
    }
    function failure(error) {
        let actionType = (isExport) ? { type: importConstants.GET_EXPORT_REPORT_ERROR_DETAIL, error } : { type: importConstants.GET_IMPORT_REPORT_ERROR_DETAIL, error }
        return actionType;
    }
}

function transformReportInProgressData(responseData, reportType) {
    switch (reportType) {
        case 1:
            return TransformData.transformStudentImportInProgressData(responseData);
        case 2:
            return TransformData.transformStudentJobFunctionInProgressData(responseData);
        case 3:
            return TransformData.transformStudentLicenseInProgressData(responseData);
        case 4:
            return TransformData.transformRoleImportInProgressData(responseData);
        case 5:
            return TransformData.transformSupervisorImportInProgressData(responseData);
        case 6:
            return TransformData.transformStudentCertificationImportInProgressData(responseData);
        case 7:
            return TransformData.transformStudentBadgeImportInProgressData(responseData);
        case 8:
            return TransformData.transformStudEduHistoryImportInProgressData(responseData);
        case 9:
            return TransformData.transformJobTitleImportInProgressData(responseData);
        case 10:
            return TransformData.transformSupplementalManagerImportInProgressData(responseData);
        case 11: 
            return TransformData.transformStudPortfolioEduImportInProgressData(responseData);
        default:
            return;
    }
}

function transformReportSuccessData(responseData, reportType) {
    switch (reportType) {
        case 1:
            return TransformData.transformStudentImportSuccessData(responseData);
        case 2:
            return TransformData.transformStudentJobFunctionSuccessData(responseData);
        case 3:
            return TransformData.transformStudentLicenseSuccessData(responseData);
        case 4:
            return TransformData.transformRoleImportSuccessData(responseData);
        case 5:
            return TransformData.transformSupervisorImportSuccessData(responseData);
        case 6:
            return TransformData.transformStudentCertificationSuccessData(responseData);
        case 7:
            return TransformData.transformStudentBadgeImportSuccessData(responseData);
        case 8:
            return TransformData.transformStuEduHistoryImportSuccessData(responseData);
        case 9:
            return TransformData.transformJobTitleImportSuccessData(responseData);
        case 10:
            return TransformData.transformSupplementalManagerImportInProgressData(responseData);
        case 11:
            return TransformData.transformStudPortfolioEduImportSuccessData(responseData);
        default:
            return;
    }
}

function transformReportErrorData(responseData, reportType) {
    switch (reportType) {
        case 1:
            return TransformData.transformStudentImportErrorData(responseData);
        case 2:
            return TransformData.transformStudentJobFunctionErrorData(responseData);
        case 3:
            return TransformData.transformStudentLicenseErrorData(responseData);
        case 4:
            return TransformData.transformRoleImportErrorData(responseData);
        case 5:
            return TransformData.transformSupervisorImportErrorData(responseData);
        case 6:
            return TransformData.transformStudentcertificationImportErrorData(responseData);
        case 7:
            return TransformData.transformStudentBadgeImportErrorData(responseData);
        case 8:
            return TransformData.transformStudEduHistoryImportErrorData(responseData);
        case 9:
            return TransformData.transformJobTitleImportErrorData(responseData);
        case 10:
            return TransformData.transformSupplementalManagerImportErrorData(responseData);
        case 11: 
            return TransformData.transformStudPortfolioEduImportErrorData(responseData);  
        default:
            return;
    }
}

function getInProgressData(fromDate, toDate, reportType, isExport, token) {
    switch (reportType) {
        case 1:
            return studentImportService.getStudentImportInProgressData(fromDate, toDate, isExport, token);
        case 2:
            return studentJobFunctionService.getStudentJobFunctionInProgressData(fromDate, toDate, isExport, token);
        case 3:
            return studentLicenseImportService.getStudentLicenseImportInProgressData(fromDate, toDate, isExport, token);
        case 4:
            return roleImportService.getRoleImportInProgressData(fromDate, toDate, isExport, token);
        case 5:
            return supervisorImportService.getSupervisorImportInProgressData(fromDate, toDate, isExport, token);
        case 6:
            return studentCertificationImportService.getStudentCertificationImportInProgressData(fromDate, toDate, isExport, token);
        case 7:
            return studentBadgeImportService.getStudentBadgeImportInProgressData(fromDate, toDate, isExport, token);
        case 8:
            return studEduHistoryImportService.getStudEduHistoryImportInProgressData(fromDate, toDate, isExport, token);
        case 9:
            return jobTitleImportService.getJobTitleImportInProgressData(fromDate, toDate, isExport, token);
        case 10:
            return supplementalManagerImportService.getSupplementalManagerImportInProgressData(fromDate, toDate, isExport, token);
        case 11:
            return studPortfolioEduImportService.getStudPortfolioEduImportInProgressData(fromDate, toDate, isExport, token);
        default:
            return;
    }
}

function getSuccessData(fromDate, toDate, reportType, isExport, token) {
    switch (reportType) {
        case 1:
            return studentImportService.getStudentImportSuccessData(fromDate, toDate, isExport, token);
        case 2:
            return studentJobFunctionService.getStudentJobFunctionSuccessData(fromDate, toDate, isExport, token);
        case 3:
            return studentLicenseImportService.getStudentLicenseImportSuccessData(fromDate, toDate, isExport, token);
        case 4:
            return roleImportService.getRoleImportSuccessData(fromDate, toDate, isExport, token);
        case 5:
            return supervisorImportService.getSupervisorImportSuccessData(fromDate, toDate, isExport, token);
        case 6:
            return studentCertificationImportService.getStudentCertificationImportSuccessData(fromDate, toDate, isExport, token);
        case 7:
            return studentBadgeImportService.getStudentBadgeImportSuccessData(fromDate, toDate, isExport, token);
        case 8:
            return studEduHistoryImportService.getStudEduHistoryImportSuccessData(fromDate, toDate, isExport, token);
        case 9:
            return jobTitleImportService.getJobTitleImportSuccessData(fromDate, toDate, isExport, token);
        case 10:
            return supplementalManagerImportService.getSupplementalManagerImportSuccessData(fromDate, toDate, isExport, token);
        case 11:
            return studPortfolioEduImportService.getStudPortfolioEduImportSuccessData(fromDate, toDate, isExport, token);       
        default:
            return;
    }
}

function getErrorData(fromDate, toDate, reportType, isExport, token) {
    switch (reportType) {
        case 1:
            return studentImportService.getStudentImportErrorData(fromDate, toDate, isExport, token);
        case 2:
            return studentJobFunctionService.getStudentJobFunctionErrorData(fromDate, toDate, isExport, token);
        case 3:
            return studentLicenseImportService.getStudentLicenseImportErrorData(fromDate, toDate, isExport, token)
        case 4:
            return roleImportService.getRoleImportErrorData(fromDate, toDate, isExport, token)
        case 5:
            return supervisorImportService.getSupervisorImportErrorData(fromDate, toDate, isExport, token);
        case 6:
            return studentCertificationImportService.getStudentCertificationImportErrorData(fromDate, toDate, isExport, token);
        case 7:
            return studentBadgeImportService.getStudentBadgeImportErrorData(fromDate, toDate, isExport, token);
        case 8:
            return studEduHistoryImportService.getStudEduHistoryImportErrorData(fromDate, toDate, isExport, token);
        case 9:
            return jobTitleImportService.getJobTitleImportErrorData(fromDate, toDate, isExport, token);
        case 10:
            return supplementalManagerImportService.getSupplementalManagerImportErrorData(fromDate, toDate, isExport, token);
        case 11:
            return studPortfolioEduImportService.getStudPortfolioEduImportErrorData(fromDate, toDate, isExport, token);
        default:
            return;
    }
}