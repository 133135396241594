import React from 'react';
import { connect } from 'react-redux';
import { importSummaryActions } from '../../../Actions/ImportSummary.Action';
import { exportReports } from '../../../Utils/ExportReports';
import { calculateDateFromIndex } from '../../../Utils/DateFormatter';
import { uiConstants } from '../../../Constants/UI.Constants';

class SummaryWidgetHeader extends React.Component {

  constructor(props) {
    super(props);

    this.handleRefreshWidget = this.handleRefreshWidget.bind(this);
    this.handleDisableRefreshWidget = this.handleDisableRefreshWidget.bind(this);
    this.handleReportDownload = this.handleReportDownload.bind(this);
    this.handleReportScheduleCreate = this.handleReportScheduleCreate.bind(this);
    this.handleReportScheduleManage = this.handleReportScheduleManage.bind(this);
  }

  handleDisableRefreshWidget(event) {
    event.preventDefault();
  }

  handleRefreshWidget() {
    let widgetData = this.props.widgetData;
    let activeDateRange = this.props.activeDateRange;
    let reportTypeId = widgetData.ReportTypeId;
    this.props.dispatch(importSummaryActions.getReportWidgetById(activeDateRange, reportTypeId));
  }

  handleReportDownload() {
    let widgetData = this.props.widgetData;
    let reportTypeId = widgetData.ReportTypeId;
    let rangeDate = calculateDateFromIndex(this.props.activeDateRange);
    if (widgetData.TotalCount !== 0) {
      this.props.dispatch(importSummaryActions.getImportSummaryDownloadDetails(rangeDate, reportTypeId, this.downloadSummaryReport));
    }
  }

  handleReportScheduleCreate() {
    if(this.props.dashboard.isRefereshed === undefined || this.props.dashboard.isRefereshed === true) {
      let widgetData = this.props.widgetData;
      let reportTypeId = widgetData.ReportTypeId;
      this.props.dispatch(importSummaryActions.toggleScheduleCreateModal(reportTypeId));
    }
  }

  handleReportScheduleManage() {
    if(this.props.dashboard.isRefereshed === undefined || this.props.dashboard.isRefereshed === true) {
      this.props.dispatch(importSummaryActions.toggleScheduleManageModal(this.props.widgetData.ReportTypeId));
    }
  }

  downloadSummaryReport = () => {
    const { dashboard } = this.props;
    const widgetData = this.props.widgetData;
    if (dashboard.downloadData !== undefined && dashboard.downloadData.data !== undefined) {
      exportReports(widgetData.ReportTypeId, dashboard.downloadData.data, false, true, dashboard.downloadData.importStatus);
    }
  }


  renderingSrcForCalendar() {
    let cls;
    //case to select Appropriate icon for Schedule icon
    switch (this.props.widgetData.ScheduledCount) {
      case -1:
        cls = cls;
        break;
      case 0:
        cls = 'icon-calendar-plus-o';
        break;
      case 1:
        cls = 'icon-calendar-plus-1';
        break;
      case 2:
        cls = 'icon-calendar-plus-2';
        break;
      case 3:
        cls = 'icon-calendar-plus-3';
        break;
      case 4:
        cls = 'icon-calendar-plus-4';
        break;
      case 5:
        cls = 'icon-calendar-plus-5';
        break;
      case 6:
        cls = 'icon-calendar-plus-6';
        break;
      case 7:
        cls = 'icon-calendar-plus-7';
        break;
      case 8:
        cls = 'icon-calendar-plus-8';
        break;
      case 9:
        cls = 'icon-calendar-plus-9';
        break;
      default:
        cls = 'icon-calendar-plus-9-plus';
        break;
    }
    return cls;
  }

  render() {
    const widgetData = this.props.widgetData;
    let isWidgetLoading = this.props.loading;

    let cls = this.renderingSrcForCalendar();
    let showScheduleCalendar = widgetData.ScheduledCount >= 0 ? true : false;
    return (
      <div
        className="d-flex import-result-heading p-0 mt-4 col-sm"> {uiConstants.ReportTypes[widgetData.ReportTypeId - 1].label}
        {/* Uncomment once file count is clarified - <small> ({widgetData.FileCount} reports)</small> */}
        <div className="ml-auto">
          {/*To show Calendar Icon */}
          {showScheduleCalendar &&
            <span
              onClick={cls === 'icon-calendar-plus-o' ? this.handleReportScheduleCreate : this.handleReportScheduleManage}
              data-toggle="modal"
              className={cls} style={{ cursor: 'pointer', float: 'right' }} alt={this.props.widgetData.ScheduledCount}>
            </span>
          }
          <span
            className={isWidgetLoading ? "refresh disabled" : "refresh enabled"}
            onClick={isWidgetLoading ? this.handleDisableRefreshWidget : this.handleRefreshWidget}
          >
            <span className="icon-refresh float-right"></span>
          </span>
          <span
            className={(widgetData.TotalCount === 0) || isWidgetLoading ? "download disable-icon" : "download enabled"}
            onClick={this.handleReportDownload}
          >
            <span className="icon-download float-right"></span>
          </span>
        </div>
      </div>
    ); 
  }
}

function mapStateToProps(state) {
  const { dashboard, importReport } = state;
  return {
    dashboard, importReport
  };
}

export default connect(mapStateToProps)(SummaryWidgetHeader);