import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Dropdown, ButtonToolbar } from 'react-bootstrap';
import $ from 'jquery';
import { exportReports } from '../../Utils/ExportReports';
import { importActions } from '../../Actions/Import.Action';

let isExcel;
let activeDateRange;
let reportTypeId;

class PopupFooter extends Component {
    constructor(props) {
        super(props);
        this.handleExportSelect = this.handleExportSelect.bind(this);
    }

    componentDidMount() {
        this.handlePrint = this.handlePrint.bind(this);
    }

    //DEFAULT PRINT BUTTON IS TRIGGERING HERE.
    handlePrint() {
        $(".buttons-print")[0].click();
    }

    handleExportSelect(eventKey) {
        activeDateRange = this.props.dateRange;
        reportTypeId = this.props.reportType;
        let importStatus = this.props.importStatus;
        isExcel = (eventKey === "1") ? true : false;
        this.props.dispatch(importActions.getImportReportSummaryDetails(activeDateRange, true, importStatus, reportTypeId, this.exportSummaryReport));
    }

    exportSummaryReport = () => {
        const { importReport } = this.props;
        if (importReport.exportData !== undefined && importReport.exportData.data !== undefined) {
            exportReports(reportTypeId, importReport.exportData.data, isExcel, false, importReport.exportData.importStatus, activeDateRange);
        }
    }

    render() {
        const disableBtns = this.props.importReport.loading;

        return (
            <Modal.Footer className={"justify-content-start"}>
                <ButtonToolbar>
                    <Dropdown id="ExportDropDown" disabled={disableBtns} dropup={"true"}>
                        <Dropdown.Toggle nocaret={"true"}>
                            Export
                              <i className="icon-caret-up mr-0 ml-2"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" onSelect={this.handleExportSelect}>Excel</Dropdown.Item >
                            <Dropdown.Item eventKey="2" onSelect={this.handleExportSelect}>CSV</Dropdown.Item >
                        </Dropdown.Menu>
                    </Dropdown>
                    {<Button onClick={this.handlePrint} className={"ml-2"} disabled={disableBtns}>Print</Button>}
                    <Button className={"btn-default ml-2"} onClick={this.props.onHide}>Close</Button>
                </ButtonToolbar>
            </Modal.Footer >
        );
    };
}

function mapStateToProps(state) {
    const { importReport } = state;
    return {
        importReport
    };
}

export default connect(mapStateToProps)(PopupFooter);