
import { tokenService } from "../Services/Dashboard/Token.Service";
import { scheduledTaskService } from '../Services/Dashboard/ScheduledTask.Service';
import { importConstants } from '../Constants/Import.Constants';
import { loginService } from '../Services/Dashboard/Login.Service';
export const scheduleTaskAction = {
    insert,
    getScheduledTasksReportDetails,
    getScheduledTasksReportDetailsbyId,
    deleteScheduledTasksReportDetails,
    toggleScheduleCreateModal,
    toggleScheduleManageModal
};

function insert(data) {
    return insertSchedultedTask(data);
}

function insertSchedultedTask(data) {
    return dispatch => {
        dispatch(request());
        tokenService.getAuthToken()
            .then(
                response => {
                    scheduledTaskService.scheduleTaskInsert(data, response.data)
                        .then(
                            response => {
                                if (response.status === 201) {
                                    dispatch(success(importConstants.SCHEDULE_SUCCESSFUL_MESSAGE));
                                }
                                else {
                                    dispatch(failure(importConstants.SCHEDULE_UNSUCCESSFUL_MESSAGE));
                                }
                            }
                        ).catch(error => {
                            dispatch(failure(importConstants.SCHEDULE_UNSUCCESSFUL_MESSAGE));
                        });
                }
            );

    };

}

function request() {
    return {
        type: importConstants.GET_REQUEST,
    }
}
function success(successfulMessage) {
    return {
        type: importConstants.SCHEDULE_ID, successfulMessage,

    }
}
function failure(unSuccessfulMessage) { return { type: importConstants.ERROR, unSuccessfulMessage } }


function getScheduledTasksReportDetails(reportTypeId, reportType) {
    
    let dataObject;
    return dispatch => {
        dispatch(request());

        tokenService.getAuthToken()
            .then(
                response => {
                    scheduledTaskService.getScheduledTasks(reportType, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {                                    
                                    dataObject = {
                                        reportTypeId: reportTypeId,
                                        reportType:reportType,
                                        importStatus: "GetScheduleSuccess",
                                        data: res.data,
                                    };
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));                                
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure(error));
                        });
                }
            );
    };
    function request() {
        let actionType = { type: importConstants.GET_SCHEDULED_TASKS_REQUEST } 
        return actionType;
    }
    function success(reportsData) {
        let actionType = { type: importConstants.GET_SCHEDULED_TASKS_SUCCESS, reportsData }
        return actionType;
    }
    
    function failure(error) {
        let actionType = { type: importConstants.GET_SCHEDULED_TASKS_ERROR, error }
        return actionType;
    }
    
}

function getScheduledTasksReportDetailsbyId(reportTypeId, scheduleId) {
    
    let dataObject;
    return dispatch => {
        dispatch(request());

        tokenService.getAuthToken()
            .then(
                response => {
                    scheduledTaskService.getScheduledTaskbyId(scheduleId, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {                                    
                                    dataObject = {
                                        reportTypeId: reportTypeId,                                        
                                        importStatus: "GetScheduleSuccess",
                                        data: res.data,
                                    };
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));                                
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure(error));
                        });
                }
            );
    };
    function request() {
        let actionType = { type: importConstants.GET_SCHEDULED_TASK_REQUEST } 
        return actionType;
    }
    function success(reportsData) {
        let actionType = { type: importConstants.GET_SCHEDULED_TASK_SUCCESS, reportsData }
        return actionType;
    }
    
    function failure(error) {
        let actionType = { type: importConstants.GET_SCHEDULED_TASK_ERROR, error }
        return actionType;
    }
    
}

function deleteScheduledTasksReportDetails(reportTypeId, scheduleId) {
    
    let dataObject;
    return dispatch => {
        dispatch(request());

        tokenService.getAuthToken()
            .then(
                response => {
                    scheduledTaskService.deleteScheduledTask(reportTypeId, scheduleId, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {                                    
                                    dataObject = {
                                        reportTypeId: reportTypeId,
                                        importStatus: "GetScheduleSuccess",
                                        data: res.data,
                                    };
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));                                
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure(error));
                        });
                }
            );
    };
    function request() {
        let actionType = { type: importConstants.DELETE_SCHEDULED_TASKS_REQUEST } 
        return actionType;
    }
    function success(reportsData) {
        let actionType = { type: importConstants.DELETE_SCHEDULED_TASKS_SUCCESS, reportsData }
        return actionType;
    }
    
    function failure(error) {
        let actionType = { type: importConstants.DELETE_SCHEDULED_TASKS_ERROR, error }
        return actionType;
    }
    
}

function toggleScheduleCreateModal(reportType, scheduleId) {
    return dispatch => {
        dispatch(toggleScheduleCreateModal());
    };

     function toggleScheduleCreateModal() { 
         return { type: importConstants.TOGGLE_SCHEDULE_CREATE_MODAL ,
         reportType: reportType,
         scheduleId: scheduleId
     }} 
}

function toggleScheduleManageModal(reportType) {
    return dispatch => {
        dispatch(toggleScheduleManageModal(reportType));
    };
 
    function toggleScheduleManageModal() { return { type: importConstants.TOGGLE_SCHEDULE_MANAGE_MODAL, reportType: reportType} }
}
