import React from 'react';
import { getFormattedDate } from '../../Utils/DateFormatter';
import { getReportHeaders } from '../../Utils/ImportDetailsResult';
import { connect } from 'react-redux';

class PopupHeader extends React.Component {

    render() {
        let reportType;
        let category;
        const { dat } = this.props,
            fromDate = dat !== undefined ? dat.fromDate : "",
            toDate = dat !== undefined ? dat.toDate : "";

        reportType = this.props.reportType;
        category = this.props.reportCategory;

        let formattedDates = getFormattedDate(fromDate, toDate);
        let reportHeader = getReportHeaders(reportType, category);
        var importReport  = this.props.importReport;
        var institutionDetails = this.props.login;
        return (
            <div className="text-center report-header">
                <div className="h3">{institutionDetails.orgNodeData.OrgNodeName}</div>
                <div className="h2">{reportHeader}</div>
                <div className="info-header-text-block">
                    <strong>Date Range:</strong> <span id="lblDateRange">{formattedDates.fDateVal} through {formattedDates.tDateVal}</span>
                </div>
                {
                (importReport.summaryData !== undefined) &&
                <div className="info-header-text-block">
                    <strong>Report Generated:</strong> <span id="lblReportGenerated">{importReport.summaryData.data.UserTimeZone}</span>
                </div>
                }
                <div className="additional-data"></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { importReport ,login} = state;
    return {
        importReport,
        login
    };
}
export default connect(mapStateToProps)(PopupHeader);