import axios from 'axios';
import { config } from '../../config';

export const studentCertificationImportService ={
    getStudentCertificationImportErrorData,
    getStudentCertificationImportInProgressData,
    getStudentCertificationImportDownloadData,
    getStudentCertificationImportSuccessData

}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

// supervisor import inprogress Service call
function getStudentCertificationImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentcertificationimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

// Student Certification Import Success Service call
function getStudentCertificationImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
     return axios.get((config.api + '/studentcertificationimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

//service call to fetch error details.
function getStudentCertificationImportErrorData(fromDate, toDate,isExport,token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentcertificationimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });

}

//service call to fetch error details.
function getStudentCertificationImportDownloadData(fromDate, toDate,token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentcertificationimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });

}