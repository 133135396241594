import axios from 'axios';
import { config } from '../../config';

export const studentLicenseImportService = {
    getStudentLicenseImportDownloadData,
    getStudentLicenseImportSuccessData,
    getStudentLicenseImportErrorData,
    getStudentLicenseImportInProgressData
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

function getStudentLicenseImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentlicense/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}

function getStudentLicenseImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentlicense/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

//StudentLicenseImport-success-service call
function getStudentLicenseImportSuccessData(fromDate, toDate, isExport, token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentlicense/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });

} 

function getStudentLicenseImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentlicense/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}