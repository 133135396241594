import axios from 'axios';
import { config } from '../../config';

export const supplementalManagerImportService = {
    getSupplementalManagerImportDownloadData,
    getSupplementalManagerImportSuccessData,
    getSupplementalManagerImportErrorData,
    getSupplementalManagerImportInProgressData
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

//SupplementalManagerImport-inprogress-service call
function getSupplementalManagerImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/supplementalmanagerimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

//SupplementalManagerImport-success-service call
function getSupplementalManagerImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/supplementalmanagerimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });

}

//SupplementalManagerImport-error-service call
function getSupplementalManagerImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/supplementalmanagerimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

//SupplementalManagerImport-download-service call
function getSupplementalManagerImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/supplementalmanagerimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}





