import axios from 'axios';
import { config } from '../../config';

export const jobTitleImportService = {
    getJobTitleImportSuccessData,
    getJobTitleImportInProgressData,
    getJobTitleImportErrorData,
    getJobTitleImportDownloadData

}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

//service call to fetch Job Title Import Success Details
function getJobTitleImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/JobTitleImport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Job Title Import In Progress Details
function getJobTitleImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/JobTitleImport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Job Title Import Error Details
function getJobTitleImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/JobTitleImport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Job Title Import Download Details
function getJobTitleImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/JobTitleImport/download?fromDate=' + fromDate + '&toDate=' + toDate ), {
        headers: headerValues
    });
}