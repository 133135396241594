import { loginConstants, userLogoConstants, footerDetailsConstants} from '../Constants/Login.Constants';

export function LoginReducer(state = {}, action) {
    switch (action.type) {
        case loginConstants.GET_ORG_NODE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case loginConstants.GET_ORG_NODE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                orgNodeData: action.data,
                error: false,
            };
        case loginConstants.GET_ORG_NODE_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                orgNodeData: {},
                error: true,
            };

        case userLogoConstants.GET_ADMIN_LOGO_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case userLogoConstants.GET_ADMIN_LOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                userLogo: action.data.UserLogo,
                isDefaultHstm: action.data.IsDefaultHstm,
                error: false,
            };
        case userLogoConstants.GET_ADMIN_LOGO_ERROR:
            return {
                ...state,
                loading: false,
                userLogo: {},
                error: true,
            };

            case footerDetailsConstants.GET_FOOTER_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: false,
                }
            case footerDetailsConstants.GET_FOOTER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    userTimeZone: action.data.UserTimeZone,
                    currentApiServerName: action.data.CurrentServerName,
                    buildNumber: action.data.BuildNumber,
                    copyWritesDetails: action.data.CopyRightsDetails,
                    isAuthorizedToPageFooter: action.data.isAuthorizedToPageFooter,
                    error: false,
                };
            case footerDetailsConstants.GET_FOOTER_ERROR:
                return {
                    ...state,
                    loading: false,
                    userLogo: {},
                    error: true,
                };
        default:
            return state
    }
}