import { importConstants } from '../Constants/Import.Constants';

export function ImportReducer(state = {}, action) {
    switch (action.type) {
        case importConstants.GET_IMPORT_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                error: { hasError: false },
                summaryData: undefined
            };
        case importConstants.GET_IMPORT_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                summaryData: action.reportsData,
                error: { hasError: false },
            };
        case importConstants.GET_IMPORT_REPORT_INPROGRESS:
                return {
                    ...state,
                    loading: false,
                    summaryData: action.reportsData,
                    error: { hasError: false },
                };
        case importConstants.GET_IMPORT_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                summaryData: action.reportsData,
                error: { hasError: false },
            };
        case importConstants.GET_IMPORT_REPORT_ERROR_DETAIL:
            return {
                ...state,
                loading: false,
                error: { hasError: true, error: action.error }
            };
        case importConstants.GET_EXPORT_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                error: { hasError: false },
                exportData: undefined
            };
        case importConstants.GET_EXPORT_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                exportData: action.reportsData,
                error: { hasError: false },
            };
        case importConstants.GET_EXPORT_REPORT_INPROGRESS:
                return {
                    ...state,
                    loading: false,
                    exportData: action.reportsData,
                    error: { hasError: false },
                };
        case importConstants.GET_EXPORT_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                exportData: action.reportsData,
                error: { hasError: false },
            };
        case importConstants.GET_EXPORT_REPORT_ERROR_DETAIL:
            return {
                ...state,
                loading: false,
                error: { hasError: true, error: action.error }
            };
        default:
            return state
    }
}