import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ScheduleCreateDetails from '../Common/ScheduleCreateDetails';
import { scheduleTaskAction } from '../../Actions/ScheduledTask.Action';
import { connect } from 'react-redux';

class ScheduleCreate extends Component {

    componentDidMount() {
        if (this.props.scheduleId !== undefined) {
            this.props.dispatch(scheduleTaskAction.getScheduledTasksReportDetailsbyId(this.props.reportType, this.props.scheduleId));
        }
    }
    renderReportDetails() {
        if (this.props.scheduleId === undefined) {
            return (<ScheduleCreateDetails closeData={this.props.onHide} />)
        }
        else {
            let scheduleDataUpdate = [];
            if (this.props.dashboard.scheduleDataUpdate !== undefined) {
                scheduleDataUpdate = this.props.dashboard.scheduleDataUpdate ? this.props.dashboard.scheduleDataUpdate : [];
            }
            return (scheduleDataUpdate.ScheduleId !== undefined && <ScheduleCreateDetails closeData={this.props.onHide} />)
        }
    }
    render() {
        return (
            <Modal
                size="lg"
                centered
                dialogClassName="custom-modal modal-xl"
                {...this.props}
            >
                <Modal.Body style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                    <div>
                        <Button variant={"light"} className="close" onClick={this.props.onHide}>
                            <i className="icon-close"></i>
                        </Button>
                        {this.renderReportDetails()}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { dashboard, login } = state;
    return {
        dashboard,
        login
    };
}

export default connect(mapStateToProps)(ScheduleCreate);