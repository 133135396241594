import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportStore from './store';
import App from './Components/App/App';
import './Assets/Css/app.min.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { loginActions } from './Actions/Login.Action'
import IdleTimer from 'react-idle-timer'

import LogOut from './Components/Common/LogOut'
class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.logout = this._logout.bind();
    this.state = {isLoggedOut : false};
  }

  _logout = () => {
    this.setState({isLoggedOut : true})
  }

  render() {
    return (
      <Provider store={reportStore}>
        <Router>
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={() =>  this.logout(this.idleTimer)}
            debounce={250}
            timeout= {1000 * 60 * 30} //30 minutes idle time
          />
          <Route path="/" render={(props) => (
            this.state.isLoggedOut === true ? (
              //This will go to the logout page if the user is idle
              <LogOut />
            ) : (
              loginActions.setHeaders() === true ? (
                  <App />)
                : (
                  //This will clear the application state and move to hlc
                  <App />
                )
            )
          )} />
        </Router>
      </Provider>
    );
  }
}


ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
