import React from 'react';
import { connect } from 'react-redux';
import { Col, Form, Button } from 'react-bootstrap';
import { uiConstants } from '../../Constants/UI.Constants';
import { importSummaryActions } from '../../Actions/ImportSummary.Action';

class DateSelector extends React.Component {
    constructor(props) {
        super(props);

        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleCustomRangeChange = this.handleCustomRangeChange.bind(this);
    }

    handleDateRangeChange(e) {
        const index = uiConstants.DateRangeFilters.findIndex(item => item.value === e.target.value);
        this.props.dispatch(importSummaryActions.setDateRangeFilter(index));
    }

    handleCustomRangeChange() {
        this.props.dispatch(importSummaryActions.toggleDatePickerModal());
    }

    render() {
        const { dashboard } = this.props;
        const activeDateRangeValue = dashboard.hasOwnProperty('dateRangeFilter') ? dashboard.dateRangeFilter.value : 0;

        return (
          <Form>
              <Form.Group className="row pr-3">
                  <Col md={6} className={"text-sm-left text-md-right"}>
                      <Form.Label className="date-selector">
                          Date Range:
                      </Form.Label>
                  </Col>
                  <Col md={6} className={"p-md-0 p-sm-2"}>
                      <Form.Control
                        as="select"
                        onChange={this.handleDateRangeChange}
                        value={activeDateRangeValue}
                        style={{ fontWeight: 'bold' }}
                      >
                          {
                              uiConstants.DateRangeFilters.map(val => {
                                  return <option
                                    label={val.label}
                                    value={val.value}
                                    key={val.value}>
                                      {val.label}
                                  </option>
                              })
                          }
                      </Form.Control>
                  </Col>
              </Form.Group>
              <div className="row">
                  {
                      dashboard.showChangeCustomDatesBtn &&
                      <Col className={"text-right"}>
                          <Button id="changeCustomDate" onClick={this.handleCustomRangeChange}>Change</Button>
                      </Col>
                  }
              </div>
          </Form>
        );
    }
}

function mapStateToProps(state) {
    const { dashboard } = state;
    return {
        dashboard
    };
}

export default connect(mapStateToProps)(DateSelector);