export const config = {
    'api': process.env.REACT_APP_REPORTS_CUSTOMER_API_URL,
    'apikey': process.env.REACT_APP_REPORTS_CUSTOMER_REACT_API_KEY,
    'buildnumber': process.env.REACT_APP_REPORTS_CUSTOMER_BUILD_NUMBER,
    'stsService' : process.env.REACT_APP_STS_AUTHORITY,
    'stsTokenPath' : process.env.REACT_APP_STS_TOKEN_PATH_VALUE,
    'stsScope' : process.env.REACT_APP_STS_SCOPE,
    'stsGrantType' : process.env.REACT_APP_STS_GRANT_TYPE, 
    'recordcount': 100,
    'hlcUrl' : process.env.REACT_APP_HLC_URL,
    'domainValue' : process.env.REACT_APP_COOKIE_DOMAIN_VALUE,
    'buildVersion' : process.env.REACT_APP_BUILD_VERSION
}