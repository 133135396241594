import { uiConstants } from '../Constants/UI.Constants';
import { getExportFileDate } from '../Utils/DateFormatter';

function getStatusSectionDetails(reportData, reportTypeId) {
    let statusData;
    let statusSectionDetails;

    switch (reportTypeId) {
        case 1:
            statusData = reportData.StudentImportStatusDetails;
            break;
        case 2:
            statusData = reportData.StudentJobFunctionStatusDetails;
            break;
        case 3:
            statusData = reportData.StudentLicenseImportStatusDetails;
            break;
        case 4:
            statusData = reportData.RoleImportStatusDetails;
            break;
        case 5:
            statusData = reportData.SupervisorImportStatusDetails;
            break;
        case 6:
            statusData = reportData.StudentCertificationImportStatusDetails;
            break;
        case 7:
            statusData = reportData.StudentBadgeImportStatusDetails;
            break;
        case 8:
            statusData = reportData.StudEduHistoryImportStatusDetails;
            break;
        case 9:
            statusData = reportData.JobTitleImportStatusDetails;
            break;
        case 10:
            statusData = reportData.SupplementalManagerImportStatusDetails;
            break;
        case 11:
            statusData = reportData.StudPortfolioEduImportStatusDetails;
            break;
        default:
            return;
    }
    statusSectionDetails = getFormattedStatusReportData(statusData);
    return statusSectionDetails;
}

function getStatusTotals(reportData, isExcel) {
    let statusTotalsData;
    if (isExcel) {
        statusTotalsData = [{ Total: "Total:", EmptyColumnOne: "", EmptyColumnTwo: "", TotalRecords: reportData.RecordsTotal }];
    }
    else {
        statusTotalsData = ['"Total:"', uiConstants.EmptyString, uiConstants.EmptyString, '"' + reportData.RecordsTotal + '"'];
    }
    return statusTotalsData;
}

function getActionTotalsForCsv(reportData, reportTypeId) {

    let actionTotalsData;

    if (reportTypeId === 1) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.TotalStudentsAdded + '"', '"' + reportData.TotalStudentsUpdated + '"', '"' + reportData.TotalStudentsDeleted + '"',
            '"' + reportData.TotalDepartmentsAdded + '"', '"' + reportData.TotalJobTitlesAdded + '"', '"' + reportData.TotalCustomValuesAdded + '"'];
    }
    else if (reportTypeId === 2) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.Inserts + '"', '"' + reportData.Updates + '"', '"' + reportData.Deletes + '"']
    }
    else if (reportTypeId === 3) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.LicensesAdded + '"', '"' + reportData.LicensesUpdated + '"', '"' + reportData.LicensesRemoved + '"']
    }
    else if (reportTypeId === 4) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.TotalRolesAdded + '"', '"' + reportData.TotalRolesUpdated + '"', '"' + reportData.TotalRolesDeleted + '"']
    }
    else if (reportTypeId === 5) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.TotalSupervisorsAdded + '"', '"' + reportData.TotalSupervisorsUpdated + '"', '"' + reportData.TotalSupervisorsDeleted + '"']
    }
    else if (reportTypeId === 6) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.TotalCertificationsAdded + '"', '"' + reportData.TotalCertificationsUpdated + '"', '"' + reportData.TotalCertificationsDeleted + '"']
    }
    else if (reportTypeId === 7) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.TotalBadgesAdded + '"', '"' + reportData.TotalBadgesUpdated + '"', '"' + reportData.TotalBadgesDeleted + '"']
    }
    else if (reportTypeId === 8) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.TotalRecordsAdded + '"', '"' + reportData.TotalRecordsUpdated + '"', '"' + reportData.TotalRecordsRemoved + '"']
    }
    else if (reportTypeId === 9) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.Inserts + '"', '"' + reportData.Updates + '"', '"' + reportData.Deletes + '"']
    }
    else if (reportTypeId === 11) {
        actionTotalsData = ['"Total:"', uiConstants.EmptyString, '"' + reportData.Inserts + '"', '"' + reportData.Updates + '"', '"' + reportData.Deletes + '"']
    }
   
    return actionTotalsData;
}


function getActionTotals(reportData, reportTypeId) {
    let actionTotalsData;
    if (reportTypeId === 1) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            StudentsAdded: reportData.TotalStudentsAdded,
            StudentsUpdated: reportData.TotalStudentsUpdated,
            UsersRemoved: reportData.TotalStudentsDeleted,
            DepartmentsAdded: reportData.TotalDepartmentsAdded,
            JobTitlesAdded: reportData.TotalJobTitlesAdded,
            CustomFieldValues: reportData.TotalCustomValuesAdded
        }];
    }
    else if (reportTypeId === 2) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            UserStudentJobFunctionInserts: reportData.Inserts,
            UserStudentJobFunctionUpdates: reportData.Updates,
            UserStudentJobFunctionDeletes: reportData.Deletes
        }];
    }
    else if (reportTypeId === 3) {
        actionTotalsData = [{
            Totals: "Total:", EmptyColumn: "",
            LicensesAdded: reportData.LicensesAdded,
            LicensesUpdated: reportData.LicensesUpdated,
            LicensesRemoved: reportData.LicensesRemoved,
        }];
    }
    else if (reportTypeId === 4) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalRolesAdded: reportData.TotalRolesAdded,
            TotalRolesUpdated: reportData.TotalRolesUpdated,
            TotalRolesDeleted: reportData.TotalRolesDeleted,
        }];
    }
    else if (reportTypeId === 5) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalSupervisorsAdded: reportData.TotalSupervisorsAdded,
            TotalSupervisorsUpdated: reportData.TotalSupervisorsUpdated,
            TotalSupervisorsDeleted: reportData.TotalSupervisorsDeleted,
        }];
    }
    else if (reportTypeId === 6) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalCertificationsAdded: reportData.TotalCertificationsAdded,
            TotalCertificationsUpdated: reportData.TotalCertificationsUpdated,
            TotalCertificationsDeleted: reportData.TotalCertificationsDeleted,
        }];
    }
    else if (reportTypeId === 7) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalBadgesAdded: reportData.TotalBadgesAdded,
            TotalBadgesUpdated: reportData.TotalBadgesUpdated,
            TotalBadgesDeleted: reportData.TotalBadgesDeleted,
        }];
    }
    else if (reportTypeId === 8) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalRecordsAdded: reportData.TotalRecordsAdded,
            TotalRecordsUpdated: reportData.TotalRecordsUpdated,
            TotalRecordsRemoved: reportData.TotalRecordsRemoved,
        }];
    }
    else if (reportTypeId === 9) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalJobTitleAdded: reportData.Inserts,
            TotalJobTitleUpdated: reportData.Updates,
            TotalJobTitleRemoved: reportData.Deletes,
        }];
    }
    else if (reportTypeId === 11) {
        actionTotalsData = [{
            Total: "Total:", EmptyColumn: "",
            TotalEducationAdded: reportData.Inserts,
            TotalEducationUpdated: reportData.Updates,
            TotalEducationRemoved: reportData.Deletes,
        }];
    }
    return actionTotalsData;
}

function getDownloadReportFileName(reportTypeId) {
    let exportDate = getExportFileDate();

    switch (reportTypeId) {
        case 1:
            return uiConstants.StudentImportFile + exportDate;
        case 2:
            return uiConstants.StudentJobFunctionFile + exportDate;
        case 3:
            return uiConstants.StudentLicenseImportFile + exportDate;
        case 4:
            return uiConstants.RoleImportFile + exportDate;
        case 5:
            return uiConstants.SupervisorImportFile + exportDate;
        case 6:
            return uiConstants.StudentCertificationImportFile + exportDate;
        case 7:
            return uiConstants.StudentBadgeImportFile + exportDate;
        case 8:
            return uiConstants.StudEduHistoryImportFile + exportDate;
        case 9:
            return uiConstants.JobTitleImportFile + exportDate;
        case 10:
            return uiConstants.SuppMgrImportFile + exportDate;
        case 11:
            return uiConstants.StudPortFolioEduImportFile + exportDate;
        default:
            return;
    }
}

function getActionSectionDetails(reportData, reportTypeId, importStatus) {
    let reportsData;
    let formattedActionData;

    if (reportTypeId === 1) {
        reportsData = reportData.StudentImportActionDetails;
    }
    else if (reportTypeId === 2) {
        reportsData = reportData.StudentJobFunctionSuccessDetails;
    }
    else if (reportTypeId === 3) {
        reportsData = reportData.StudentLicenseImportActionDetails;
    }
    else if (reportTypeId === 4) {
        reportsData = reportData.RoleImportActionDetails;
    }
    else if (reportTypeId === 5) {
        reportsData = reportData.SupervisorImportActionDetails;
    }
    else if (reportTypeId === 6) {
        reportsData = reportData.StudentCertificationImportActionDetails;
    }
    else if (reportTypeId === 7) {
        reportsData = reportData.StudentBadgeImportActionDetails;
    }
    else if (reportTypeId === 8) {
        reportsData = reportData.StudEduHistoryImportActionDetails;
    }
    else if (reportTypeId === 9) {
        reportsData = reportData.JobTitleImportSuccessDetails;
    }
    else if (reportTypeId === 10) {
        reportsData = reportData.SupplementalManagerImportSuccessDetails;
    }
    else if(reportTypeId === 11){
        reportsData = reportData.StudPortfolioEduImportSuccessDetails;
    }
    formattedActionData = getFormattedActionReportData(reportsData, reportTypeId, importStatus)
    return formattedActionData;
}

function getCsvStatusSectionDetails(reportData, importStatus, reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudentImportStatusDetails;
        case 2:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudentJobFunctionStatusDetails;
        case 3:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudentLicenseImportStatusDetails;
        case 4:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.RoleImportStatusDetails;
        case 5:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.SupervisorImportStatusDetails;
        case 6:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudentCertificationImportStatusDetails;
        case 7:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudentBadgeImportStatusDetails;
        case 8:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudEduHistoryImportStatusDetails;
        case 9:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.JobTitleImportStatusDetails;
        case 10:
            return (importStatus !== uiConstants.ImportStatus.Error) ? reportData.ImportStatusDetails : reportData.SupplementalManagerImportStatusDetails;
        case 11:
            return (importStatus === uiConstants.ImportStatus.InProgress) ? reportData.ImportStatusDetails : reportData.StudPortfolioEduImportStatusDetails;
        default:
            return;
    }
}

function getCsvActionSectionDetails(reportData, reportTypeId) {
    let reportsData;

    if (reportTypeId === 1) {
        reportsData = reportData.StudentImportActionDetails;
    }
    else if (reportTypeId === 2) {
        reportsData = reportData.StudentJobFunctionSuccessDetails;
    }
    else if (reportTypeId === 3) {
        reportsData = reportData.StudentLicenseImportActionDetails;
    }
    else if (reportTypeId === 4) {
        reportsData = reportData.RoleImportActionDetails;
    }
    else if (reportTypeId === 5) {
        reportsData = reportData.SupervisorImportActionDetails;
    }
    else if (reportTypeId === 6) {
        reportsData = reportData.StudentCertificationImportActionDetails;
    }
    else if (reportTypeId === 7) {
        reportsData = reportData.StudentBadgeImportActionDetails;
    }
    else if (reportTypeId === 8) {
        reportsData = reportData.StudEduHistoryImportActionDetails;
    }
    else if (reportTypeId === 9) {
        reportsData = reportData.JobTitleImportSuccessDetails;
    }
    else if (reportTypeId === 10) {
        reportsData = "";
     }
    else if (reportTypeId === 11) {
        reportsData = reportData.StudPortfolioEduImportSuccessDetails;
        
     }
    return reportsData;
}

function getCsvErrorSectionDetails(reportData, reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return reportData.StudentImportErrorDetails;
        case 2:
            return reportData.StudentJobFunctionErrorDetails;
        case 3:
            return reportData.StudentLicenseImportErrorDetails;
        case 4:
            return reportData.RoleImportErrorDetails;
        case 5:
            return reportData.SupervisorImportErrorDetails;
        case 6:
            return reportData.StudentCertificationImportErrorDetails;
        case 7:
            return reportData.StudentBadgeImportErrorDetails;
        case 8:
            return reportData.StudEduHistoryImportErrorDetails;
        case 9:
            return reportData.JobTitleImportErrorDetails;
        case 10:
            return reportData.SupplementalManagerImportErrorDetails;
        case 11:
            return reportData.StudPortfolioEduImportErrorDetails;
        default:
            return;
    }
}

function getErrorSectionDetails(reportData, reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return reportData.StudentImportErrorDetails;
        case 2:
            return reportData.StudentJobFunctionErrorDetails;
        case 3:
            return reportData.StudentLicenseImportErrorDetails;
        case 4:
            return reportData.RoleImportErrorDetails;
        case 5:
            return reportData.SupervisorImportErrorDetails;
        case 6:
            return reportData.StudentCertificationImportErrorDetails;
        case 7:
            return reportData.StudentBadgeImportErrorDetails;
        case 8:
            return reportData.StudEduHistoryImportErrorDetails;
        case 9:
            return reportData.JobTitleImportErrorDetails;
        case 10:
            return reportData.SupplementalManagerImportErrorDetails;
        case 11:
            return reportData.StudPortfolioEduImportErrorDetails;
        default:
            return;
    }
}

function getSuccessSheetTitle(reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return uiConstants.ExportStudentImportSuccessSheetTitle;
        case 2:
            return uiConstants.ExportJobFunctionSuccessSheetTitle;
        case 3:
            return uiConstants.ExportStudentLicenseSuccessSheetTitle;
        case 4:
            return uiConstants.ExportRoleImportSuccessSheetTitle;
        case 5:
            return uiConstants.ExportSupervisorImportSuccessSheetTitle;
        case 6:
            return uiConstants.ExportStudentCertificationImportSuccessSheetTitle;
        case 7:
            return uiConstants.ExportStudentBadgeImportSuccessSheetTitle;
        case 8:
            return uiConstants.ExportStuEduHistoryImportSuccessSheetTitle;
        case 9:
            return uiConstants.ExportJobTitleImportSuccessSheetTitle;
        case 10:
            return uiConstants.ExportSuppMgrImportSuccessSheetTitle;
        case 11:
            return uiConstants.ExportStudPortfolioEduImportImportSuccessSheetTitle;
        default:
            return;
    }
}

function getErrorSheetTitle(reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return uiConstants.ExportStudentImportErrorSheetTitle;
        case 2:
            return uiConstants.ExportJobFunctionErrorSheetTitle;
        case 3:
            return uiConstants.ExportStudentLicenseErrorSheetTitle;
        case 4:
            return uiConstants.ExportRoleImportErrorSheetTitle;
        case 5:
            return uiConstants.ExportSupervisorImportErrorSheetTitle;
        case 6:
            return uiConstants.ExportStudentCertififcationImportSheetTitle;
        case 7:
            return uiConstants.ExportStudentBadgeImportErrorSheetTitle;
        case 8:
            return uiConstants.ExportStudEduHistoryImportErrorSheetTitle;
        case 9:
            return uiConstants.ExportJobTitleImportErrorSheetTitle;
        case 10:
            return uiConstants.ExportSuppMgrImportErrorSheetTitle;
        case 11 :
            return uiConstants.ExportStudPortfolioEduImportImportErrorSheetTitle;
        default:
            return;
    }
}

function getInProgressSheetTitle(reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return uiConstants.ExportStudentImportInProgressSheetTitle;
        case 2:
            return uiConstants.ExportJobFunctionInProgressSheetTitle;
        case 3:
            return uiConstants.ExportStudentLicenseInProgressSheetTitle;
        case 4:
            return uiConstants.ExportRoleImportInProgressSheetTitle;
        case 5:
            return uiConstants.ExportSupervisorImportInProgressSheetTitle;
        case 6:
            return uiConstants.ExportStudentCertificationImportInProgressSheetTitle;
        case 7:
            return uiConstants.ExportStudentBadgeImportInProgressSheetTitle;
        case 8:
            return uiConstants.ExportStudEduHistoryImportInProgressSheetTitle;
        case 9:
            return uiConstants.ExportJobTitleImportInProgressSheetTitle;
        case 10:
            return uiConstants.ExportSuppMgrImportInProgressSheetTitle;
        case 11:
            return uiConstants.ExportStudPortfolioEduImportInProgressSheetTitle;
        default:
            return;
    }
}

function getResultSectionHeader(reportTypeId, importStatus) {
    let resultSectionHeader = "";

    if (importStatus === uiConstants.ImportStatus.Success) {
        switch (reportTypeId) {
            case 1:
                resultSectionHeader = uiConstants.StudentImportActionDownloadHeaders;
                break;
            case 2:
                resultSectionHeader = uiConstants.StudentJobFunctionActionDownloadHeaders;
                break;
            case 3:
                resultSectionHeader = uiConstants.StudentLicenseActionDownloadHeaders;
                break;
            case 4:
                resultSectionHeader = uiConstants.RoleImportActionDownloadHeaders;
                break;
            case 5:
                resultSectionHeader = uiConstants.SupervisorImportActionDownloadHeaders;
                break;
            case 6:
                resultSectionHeader = uiConstants.StudentCertificationImportActionDownloadHeaders;
                break;
            case 7:
                resultSectionHeader = uiConstants.StudentBadgeImportActionDownloadHeaders;
                break;
            case 8:
                resultSectionHeader = uiConstants.StuEduHistoryImportActionDownloadHeaders;
                break;
            case 9:
                resultSectionHeader = uiConstants.JobTitleImportActionDownloadHeaders;
                break;
            case 10:
                resultSectionHeader = uiConstants.SupplementalManagerActionDownloadHeaders;
                break;
            case 11:
                resultSectionHeader = uiConstants.StudPortfolioEducationImportActionDownloadHeaders;
                break;
            default:
                return;
        }
    }
    else if (importStatus === uiConstants.ImportStatus.Error) {
        switch (reportTypeId) {
            case 1:
                resultSectionHeader = uiConstants.StudentImportErrorDownloadHeaders;
                break;
            case 2:
                resultSectionHeader = uiConstants.StudentJobFunctionErrorDownloadHeaders;
                break;
            case 3:
                resultSectionHeader = uiConstants.StudentLicenseErrorDownloadHeaders;
                break;
            case 4:
                resultSectionHeader = uiConstants.RoleImportErrorDownloadHeaders;
                break;
            case 5:
                resultSectionHeader = uiConstants.SupervisorImportErrorDownloadHeaders;
                break;
            case 6:
                resultSectionHeader = uiConstants.StudentCertificationImportErrorDownloadHeaders;
                break;
            case 7:
                resultSectionHeader = uiConstants.StudentBadgeImportErrorDownloadHeaders;
                break;
            case 8:
                resultSectionHeader = uiConstants.StudEduHistoryImportErrorDownloadHeaders;
                break;
            case 9:
                resultSectionHeader = uiConstants.JobTitleImportErrorDownloadHeaders;
                break;
            case 10:
                resultSectionHeader = uiConstants.SupplementalManagerImportErrorDownloadHeaders;
                break;
            case 11:
                resultSectionHeader = uiConstants.StudPortfolioEducationImportErrorDownloadHeaders;
                break;
            default:
                return;
        }
    }

    return resultSectionHeader;
}

function getActionSectionFields(reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return uiConstants.StudentImportActionFields;
        case 2:
            return uiConstants.StudentJobFunctionActionFields;
        case 3:
            return uiConstants.StudentLicenseImportActionFields;
        case 4:
            return uiConstants.RoleImportActionFields;
        case 5:
            return uiConstants.SupervisorImportActionFields;
        case 6:
            return uiConstants.StudentCertificationImportActionFields;
        case 7:
            return uiConstants.StudentBadgeImportActionFields;
        case 8:
            return uiConstants.StudEduHistoryImportActionFields;
        case 9:
            return uiConstants.JobTitleImportActionFields;
        case 11:
            return uiConstants.StudPortfolioEduActionFields;
        default:
            return;
    }
}

function getErrorSectionFields(reportTypeId) {
    switch (reportTypeId) {
        case 1:
            return uiConstants.StudentImportErrorFields;
        case 2:
            return uiConstants.StudentJobFunctionErrorFields;
        case 3:
            return uiConstants.StudentLicenseImportErrorFields;
        case 4:
            return uiConstants.RoleImportErrorFields;
        case 5:
            return uiConstants.SupervisorImportErrorFields;
        case 6:
            return uiConstants.StudentCertificationImportErrorFields;
        case 7:
            return uiConstants.StudentBadgeImportErrorFields;
        case 8:
            return uiConstants.StuEduHistoryImportErrorFields;
        case 9:
            return uiConstants.JobTitleImportErrorFields;
        case 10:
            return uiConstants.SupplementalManagerImportErrorFields;
        case 11:
            return uiConstants.StudPortfolioEduImportErrorFields;
        default:
            return;
    }
}

function getExportSuccessAndErrorDetails(reportData, importStatus, reportTypeId) {
    let statusSectionDetails;
    let resultSectionDetails;
    let convertedData;

    statusSectionDetails = getCsvStatusSectionDetails(reportData, importStatus, reportTypeId);

    if (importStatus === uiConstants.ImportStatus.Success) {
        resultSectionDetails = getCsvActionSectionDetails(reportData, reportTypeId);
    }
    else if (importStatus === uiConstants.ImportStatus.Error) {
        resultSectionDetails = getCsvErrorSectionDetails(reportData, reportTypeId);
    }

    convertedData = {
        statusSectionDetails,
        resultSectionDetails
    };

    return convertedData;
}

function getExportReportFileName(reportTypeId, importStatus) {
    let exportDate = getExportFileDate();
    let fileName;
    switch (reportTypeId) {
        case 1:
            {
                if (importStatus === uiConstants.ImportStatus.Success) {
                    fileName = uiConstants.ExportSuccessStudentImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Error) {
                    fileName = uiConstants.ExportErrorStudentImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.InProgress) {
                    fileName = uiConstants.ExportInProgressStudentImportFile;
                }
                break;
            }
        case 2:
            {
                if (importStatus === uiConstants.ImportStatus.Success) {
                    fileName = uiConstants.ExportSuccessJobFunctionFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Error) {
                    fileName = uiConstants.ExportErrorJobFunctionFile;
                }
                else if (importStatus === uiConstants.ImportStatus.InProgress) {
                    fileName = uiConstants.ExportInProgressJobFunctionImportFile;
                }
                break;
            }
        case 3:
            {
                if (importStatus === uiConstants.ImportStatus.Success) {
                    fileName = uiConstants.ExportSuccessStudentLicenseFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Error) {
                    fileName = uiConstants.ExportErrorStudentLicenseFile;
                }
                else if (importStatus === uiConstants.ImportStatus.InProgress) {
                    fileName = uiConstants.ExportInProgressStudentLicenseImportFile;
                }
                break;
            }

        case 4:
            {
                if (importStatus === uiConstants.ImportStatus.Success) {
                    fileName = uiConstants.ExportSuccessRoleImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Error) {
                    fileName = uiConstants.ExportErrorRoleImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.InProgress) {
                    fileName = uiConstants.ExportInProgressRoleImportFile;
                }
                break;
            }
        case 5:
            {
                if (importStatus === uiConstants.ImportStatus.InProgress) {
                    fileName = uiConstants.ExportInProgressSupervisorImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Success) {
                    fileName = uiConstants.ExportSuccessSupervisorImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Error) {
                    fileName = uiConstants.ExportErrorSupervisorImportFile;
                }

                break;
            }
        case 6:
            {
                if (importStatus === uiConstants.ImportStatus.InProgress) {
                    fileName = uiConstants.ExportInProgressStudentCertificationImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Success) {
                    fileName = uiConstants.ExportSuccessStudentCertificationImportFile;
                }
                else if (importStatus === uiConstants.ImportStatus.Error) {
                    fileName = uiConstants.ExportErrorStudentCertificationImportFile;
                }
                break;
            }
        case 7: {
            if (importStatus === uiConstants.ImportStatus.InProgress) {
                fileName = uiConstants.ExportInProgressStudentBadgeImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Success) {
                fileName = uiConstants.ExportSuccessStudentBadgeImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Error) {
                fileName = uiConstants.ExportErrorStudentBadgeImportFile;
            }
            break;
        }
        case 8: {
            if (importStatus === uiConstants.ImportStatus.InProgress) {
                fileName = uiConstants.ExportInProgressStuEduHistoryImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Error) {
                fileName = uiConstants.ExportErrorStudEduHistoryImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Success) {
                fileName = uiConstants.ExportSuccessStuEduHistoryImportFile;
            }
            break;
        }
        case 9: {
            if (importStatus === uiConstants.ImportStatus.InProgress) {
                fileName = uiConstants.ExportInProgressJobTitleImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Error) {
                fileName = uiConstants.ExportErrorJobTitleImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Success) {
                fileName = uiConstants.ExportSuccessJobTitleImportFile;
            }
            break;
        }
        case 10: {
            if (importStatus === uiConstants.ImportStatus.InProgress) {
                fileName = uiConstants.ExportInProgressSupplementalManagerImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Error) {
                fileName = uiConstants.ExportErrorSupplementalManagerImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Success) {
                fileName = uiConstants.ExportSuccessSupplementalManagerImportFile;
            }
            break;
        }
        case 11: {
            if (importStatus === uiConstants.ImportStatus.InProgress) {
                fileName = uiConstants.ExportInProgressStudPortfolioImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Error) {
                fileName = uiConstants.ExportErrorStudPortfolioImportFile;
            }
            else if (importStatus === uiConstants.ImportStatus.Success) {
                fileName = uiConstants.ExportSuccessStudPortfolioImportFile;
            }
            break;
        }

        default:
            return;
    }
    return fileName + "-" + exportDate;
}

function getExportSheetNames() {
    let summarySheet = uiConstants.ExportResultsSummary;
    let resultsSheet = uiConstants.ExportResultsDetails;
    let sheetNames = { summarySheet, resultsSheet };
    return sheetNames;
}

function getExportHeader(importStatus) {
    let exportHeader;
    if (importStatus === uiConstants.ImportStatus.Success) {
        exportHeader = uiConstants.ImportActionSummary
    }
    else if (importStatus === uiConstants.ImportStatus.Error) {
        exportHeader = uiConstants.ImportErrorSummary;
    }
    else {
        exportHeader = '';
    }
    return exportHeader;
}

function getFormattedStatusReportData(reportData) {

    let statusData;
    if (reportData !== null && reportData !== undefined) {
        statusData = reportData.map(function (item) {
            item['Records'] = String(item['Records']);
            return item;
        });
    }
    return statusData;
}
 
function getFormattedActionReportData(reportData, reportTypeId, importStatus) {

    let actionData;

    if (importStatus !== uiConstants.ImportStatus.InProgress) {
        if (reportTypeId === 1) {
            actionData = reportData.map(function (item) {
                item['StudentsAdded'] = String(item['StudentsAdded']);
                item['StudentsUpdated'] = String(item['StudentsUpdated']);
                item['UsersRemoved'] = String(item['UsersRemoved']);
                item['DepartmentsAdded'] = String(item['DepartmentsAdded']);
                item['JobTitlesAdded'] = String(item['JobTitlesAdded']);
                return item;
            });
        }
        else if (reportTypeId === 2) {
            actionData = reportData.map(function (item) {
                item['Inserts'] = String(item['Inserts']);
                item['Updates'] = String(item['Updates']);
                item['Deletes'] = String(item['Deletes']);
                return item;
            });
        }
        else if (reportTypeId === 3) {
            actionData = reportData.map(function (item) {
                item['LicensesAdded'] = String(item['LicensesAdded']);
                item['LicensesUpdated'] = String(item['LicensesUpdated']);
                item['LicensesRemoved'] = String(item['LicensesRemoved']);
                return item;
            });
        }
        else if (reportTypeId === 4) {
            actionData = reportData.map(function (item) {
                item['RolesAdded'] = String(item['RolesAdded']);
                item['RolesUpdated'] = String(item['RolesUpdated']);
                item['RolesDeleted'] = String(item['RolesDeleted']);
                return item;
            });
        }
        else if (reportTypeId === 5) {
            actionData = reportData.map(function (item) {
                item['SupervisorsAdded'] = String(item['SupervisorsAdded']);
                item['SupervisorsUpdated'] = String(item['SupervisorsUpdated']);
                item['SupervisorsDeleted'] = String(item['SupervisorsDeleted']);
                return item;
            });
        }
        else if (reportTypeId === 6) {
            actionData = reportData.map(function (item) {
                item['CertificationsAdded'] = String(item['CertificationsAdded']);
                item['CertificationsUpdated'] = String(item['CertificationsUpdated']);
                item['CertificationsDeleted'] = String(item['CertificationsDeleted']);
                return item;
            });
        }
        else if (reportTypeId === 7) {
            actionData = reportData.map(function (item) {
                item['BadgesAdded'] = String(item['BadgesAdded']);
                item['BadgesUpdated'] = String(item['BadgesUpdated']);
                item['BadgesDeleted'] = String(item['BadgesDeleted']);
                return item;
            });
        }
        else if (reportTypeId === 8) {
            actionData = reportData.map(function (item) {
                item['RecordsAdded'] = String(item['RecordsAdded']);
                item['RecordsUpdated'] = String(item['RecordsUpdated']);
                item['RecordsRemoved'] = String(item['RecordsRemoved']);
                return item;
            });
        }
        else if (reportTypeId === 9) {
            actionData = reportData.map(function (item) {
                item['Inserts'] = String(item['Inserts']);
                item['Updates'] = String(item['Updates']);
                item['Deletes'] = String(item['Deletes']);
                return item;
            });
        }
        else if (reportTypeId === 10) {
            actionData = reportData.map(function (item) {
                item['Inserts'] = String(item['Inserts']);
                item['Updates'] = String(item['Updates']);
                item['Deletes'] = String(item['Deletes']);
                return item;
            });
        }
        else if (reportTypeId === 11) {
            actionData = reportData.map(function (item) {
                item['Inserts'] = String(item['Inserts']);
                item['Updates'] = String(item['Updates']);
                item['Deletes'] = String(item['Deletes']);
                return item;
            });
        }
    }
    return actionData;
}


export {
    getStatusSectionDetails,
    getDownloadReportFileName,
    getActionSectionDetails,
    getErrorSectionDetails,
    getStatusTotals,
    getActionTotals,
    getExportReportFileName,
    getExportSheetNames,
    getSuccessSheetTitle,
    getInProgressSheetTitle,
    getErrorSheetTitle,
    getResultSectionHeader,
    getExportSuccessAndErrorDetails,
    getExportHeader,
    getActionTotalsForCsv,
    getActionSectionFields,
    getErrorSectionFields,
    getFormattedStatusReportData,
    getFormattedActionReportData

}