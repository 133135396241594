import axios from 'axios';
import { config } from '../../config';

export const supervisorImportService = {
    getSupervisorImportInProgressData,
    getSupervisorImportSuccessData,
    getSupervisorImportErrorData,
    getSupervisorImportDownloadData
}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

// supervisor import inprogress Service call
function getSupervisorImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
     headerValues["Authorization"] = token.token_type + " " + token.access_token;
     return axios.get((config.api + '/supervisorimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

// supervisor import success service call
function getSupervisorImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
     headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/supervisorimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

// Supervisor import Error service call
function getSupervisorImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/supervisorimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}
// supervisor import download service call
function getSupervisorImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/supervisorimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });

}