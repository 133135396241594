import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'datatables.net-buttons-bs4';
import $ from 'jquery';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import { uiConstants } from '../../Constants/UI.Constants';
import { headerConstants, importConstants } from '../../Constants/Import.Constants';
import { scheduleTaskAction } from '../../Actions/ScheduledTask.Action';
import { importSummaryActions } from '../../Actions/ImportSummary.Action';
import '../../Assets/Css/app.min.css';

class ScheduleCreateDetails extends Component {
    constructor(props) {
        super(props);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.validateDate = this.validateDate.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleDropdownFormatChange = this.handleDropdownFormatChange.bind(this);
        this.fromDate = this.bindFromDate();
        this.toDate = this.bindToDate();
        this.state = {
            requestName: this.reportName(this.props.dashboard.reportType),
            requestNameError: true,
            email: this.props.login.orgNodeData.Email,
            errorEmail: true,
            invalidEmail: true,
            startDate: new Date(),
            endDate: moment(new Date()).add(30, 'days').toDate(),
            isStartAfterEnd: false,
            isEndBeforeStart: false,
            isFromDateInvalid: false,
            isToDateInvalid: false,
            scheduleId: importConstants.SCHEDULE_ID,
            selectReportDateRange: '24-Hrs',
            reportFormat: '256',
            selectedStartDate: '@@Last24HoursStart',
            selectedEndDate: '@@Last24HoursEnd',
            task: '',
            scheduleTaskDetails: '0',
            determineSaveEnable: true,
            determineForSpecificDayTextBox: '',
            errors: {
                requestName: '',
                email: ''
            }
        }
    }
    componentDidMount() {
        if (this.props.dashboard.scheduleDataUpdate != undefined) {
            this.setState({ requestName: this.props.dashboard.scheduleDataUpdate.Description });
            this.setState({ scheduleId: this.props.dashboard.scheduleDataUpdate.ScheduleId });
            this.setState({ email: this.props.dashboard.scheduleDataUpdate.NotificationEmail });
            this.setState({ startDate: this.props.dashboard.scheduleDataUpdate.StartDate });
            this.setState({ endDate: this.props.dashboard.scheduleDataUpdate.EndDate });

            let repFmt = this.props.dashboard.scheduleDataUpdate.ReportFormat;
            if (repFmt == 1 || repFmt == 8) {
                repFmt = 256;
            }

            this.setState({ reportFormat: repFmt });

            this.setState({ selectedStartDate: this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V0 });
            this.setState({ selectedEndDate: this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V1 });

            if (this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V0 == '@@Last24HoursStart' && this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V1 == '@@Last24HoursEnd')
                this.setState({ selectReportDateRange: '24-Hrs' });
            else if (this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V0 == '@@Last72HoursStart' && this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V1 == '@@Last72HoursEnd')
                this.setState({ selectReportDateRange: '72-Hrs' });
            else if (this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V0 == '@@Last7DaysStart' && this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V1 == '@@Last7DaysEnd')
                this.setState({ selectReportDateRange: '7-Days' });
            else if (this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V0 == '@@Last14DaysStart' && this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V1 == '@@Last14DaysEnd')
                this.setState({ selectReportDateRange: '14-Days' });
            else if (this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V0 == '@@Last30DaysStart' && this.props.dashboard.scheduleDataUpdate.ScheduledTaskParameterNVC.V1 == '@@Last30DaysEnd')
                this.setState({ selectReportDateRange: '30-Days' });

            this.bindScheduleTaskAndScheduleTaskDetails();
        }
    }

    bindScheduleTaskAndScheduleTaskDetails() {
        let data = this.props.dashboard.scheduleDataUpdate;
        let scheduleTask = '';
        let scheduleTaskDetails = '-1';

        if (data.FrequencyType == 4) {
            $("#dailyFreq").prop("checked", true);
            scheduleTask = 'DailyTask';
        }
        else if (data.FrequencyType == 8) {
            $("#weeklyFreq").prop("checked", true);
            scheduleTask = 'WeeklyTask';
            scheduleTaskDetails = data.FrequencyInterval;

            let freqInterval = data.FrequencyInterval;
            while (freqInterval > 0) {
                if (freqInterval >= '64') {
                    $("#sat").prop("checked", true);
                    freqInterval = freqInterval - 64;
                }
                else if (freqInterval >= '32') {
                    $("#fri").prop("checked", true);
                    freqInterval = freqInterval - 32;
                }
                else if (freqInterval >= '16') {
                    $("#thur").prop("checked", true);
                    freqInterval = freqInterval - 16;
                }
                else if (freqInterval >= '8') {
                    $("#wed").prop("checked", true);
                    freqInterval = freqInterval - 8;
                }
                else if (freqInterval >= '4') {
                    $("#tue").prop("checked", true);
                    freqInterval = freqInterval - 4;
                }
                else if (freqInterval >= '2') {
                    $("#mon").prop("checked", true);
                    freqInterval = freqInterval - 2;
                }
                else if (freqInterval >= '1') {
                    $("#sun").prop("checked", true);
                    freqInterval = freqInterval - 1;
                }
            }
        }
        else if (data.FrequencyType == 16 || data.FrequencyType == 32) {
            $("#monthlyFreq").prop("checked", true);

            if (data.FrequencyRelativeInterval == '1') {
                $("#firstDayMonthly").prop("checked", true);
                scheduleTask = 'FirstDayMonthlyTask';
            }
            else if (data.FrequencyRelativeInterval == '16') {
                $("#lastDayMonthly").prop("checked", true);
                scheduleTask = 'LastDayMonthlyTask';
            }
            else if (data.FrequencyRelativeInterval == '-1') {
                $("#specificDayMonthly").prop("checked", true);
                $("#month").val(data.FrequencyInterval);
                scheduleTask = 'SpecificDayMonthlyTask';
                scheduleTaskDetails = data.FrequencyInterval;
            }
        }
        else if (data.FrequencyType == 64 || data.FrequencyType == 128) {
            $("#quarRadio").prop("checked", true);

            if (data.FrequencyRelativeInterval == '1') {
                $("#firstDayQuar").prop("checked", true);
                scheduleTask = 'FirstDayQuarterlyTask';
            }
            else if (data.FrequencyRelativeInterval == '16') {
                $("#lastDayQuar").prop("checked", true);
                scheduleTask = 'LastDayQuarterlyTask';
            }
            else if (data.FrequencyRelativeInterval == '-1') {
                $("#specificDayQuar").prop("checked", true);
                $("#quarter").val(data.FrequencyInterval);
                scheduleTask = 'SpecificDayQuarterlyTask';
                scheduleTaskDetails = data.FrequencyInterval
            }
        }
    }

    bindFromDate() {
        if (this.props.dashboard != undefined && this.props.dashboard.scheduleDataUpdate != undefined)
            return new Date(this.props.dashboard.scheduleDataUpdate.StartDate);
        else
            return new Date();
    }

    bindToDate() {
        if (this.props.dashboard != undefined && this.props.dashboard.scheduleDataUpdate != undefined)
            return new Date(this.props.dashboard.scheduleDataUpdate.EndDate);
        else
            return moment(new Date()).add(30, 'days').toDate()
    }

    handleDropdownChange(e) {
        this.setState({ selectReportDateRange: e.target.value });
    }
    handleDropdownFormatChange(e) {
        this.setState({ reportFormat: e.target.value });
    }
    handleFromDateChange(e) {
        this.fromDate = moment(e).format('L');
        this.setState({ isEndBeforeStart: false });
        this.setState({ startDate: this.fromDate });
        this.validateDate();
    }

    handleEndDateChange(e) {
        this.toDate = moment(e).format('L');
        this.setState({ isEndBeforeStart: true });
        this.setState({ endDate: this.toDate });
        this.validateDate();
    }

    validateDate() {
        if (!moment(this.fromDate, 'L').isValid() && this.fromDate !== "") {
            this.setState({ isFromDateInvalid: true });
        }
        else {
            this.setState({ isFromDateInvalid: false });
        }
        if (!moment(this.toDate, 'L').isValid() && this.toDate !== "") {
            this.setState({ isToDateInvalid: true });
        }
        else {
            this.setState({ isToDateInvalid: false });
        }
        if (moment(this.fromDate).isAfter(this.toDate) && this.toDate !== ""
            && this.fromDate !== "") {
            this.setState({ isStartAfterEnd: true });
        }
        else {
            this.setState({ isStartAfterEnd: false });
        }
    }
    dispatchToScheduleAction(data) {
        this.props.dispatch(scheduleTaskAction.insert(data));
    }

    btnClick(e) {

        $("#btnSubmit").attr("disabled", true);

        $("#saveBtn").attr("disabled", true);

        if ($('.radio-daily').is(':checked')) {
            this.state.task = 'DailyTask';
        }

        else if ($('.radio-weekly').is(':checked')) {
            var day = new Array();
            day[0] = 0;
            day[1] = 0;
            day[2] = 0;
            day[3] = 0;
            day[4] = 0;
            day[5] = 0;
            day[6] = 0;
            this.state.task = 'WeeklyTask';

            if ($('#sun').is(':checked')) {
                day[0] = 1;
            }
            if ($('#mon').is(':checked')) {
                day[1] = 2;
            }
            if ($('#tue').is(':checked')) {
                day[2] = 4;
            }
            if ($('#wed').is(':checked')) {
                day[3] = 8;
            }
            if ($('#thur').is(':checked')) {
                day[4] = 16;
            }
            if ($('#fri').is(':checked')) {
                day[5] = 32;
            }
            if ($('#sat').is(':checked')) {
                day[6] = 64;
            }

            var weekDaysCount = day[0] + day[1] + day[2] + day[3] + day[4] + day[5] + day[6];
            this.state.scheduleTaskDetails = weekDaysCount;
        }

        else if ($('#firstDayMonthly').is(':checked')) {
            this.state.task = 'FirstDayMonthlyTask';
        }
        else if ($('#lastDayMonthly').is(':checked')) {
            this.state.task = 'LastDayMonthlyTask';
        }
        else if ($('#specificDayMonthly').is(':checked')) {
            var specificDaySelected = $("#month").val();
            this.state.task = 'SpecificDayMonthlyTask';
            this.state.scheduleTaskDetails = specificDaySelected;
        }

        if ($('#firstDayQuar').is(':checked')) {
            this.state.task = 'FirstDayQuarterlyTask';
        }
        else if ($('#lastDayQuar').is(':checked')) {
            this.state.task = 'LastDayQuarterlyTask';
        }
        else if ($('#specificDayQuar').is(':checked')) {
            var specificDaySelected = $("#quarter").val();
            this.state.task = 'SpecificDayQuarterlyTask';
            this.state.scheduleTaskDetails = specificDaySelected;
        }
        if (this.state.selectReportDateRange == '24-Hrs') {
            this.state.selectedStartDate = '@@Last24HoursStart'
            this.state.selectedEndDate = '@@Last24HoursEnd'
        }
        else if (this.state.selectReportDateRange == '72-Hrs') {
            this.state.selectedStartDate = '@@Last72HoursStart'
            this.state.selectedEndDate = '@@Last72HoursEnd'
        }
        else if (this.state.selectReportDateRange == '7-Days') {
            this.state.selectedStartDate = '@@Last7DaysStart'
            this.state.selectedEndDate = '@@Last7DaysEnd'
        }
        else if (this.state.selectReportDateRange == '14-Days') {
            this.state.selectedStartDate = '@@Last14DaysStart'
            this.state.selectedEndDate = '@@Last14DaysEnd'
        }
        else if (this.state.selectReportDateRange == '30-Days') {
            this.state.selectedStartDate = '@@Last30DaysStart'
            this.state.selectedEndDate = '@@Last30DaysEnd'
        }

        if ((this.state.scheduleId != '' && this.state.scheduleId != undefined) &&
            (this.state.startDate != '' && this.state.startDate != undefined) &&
            (this.state.endDate != '' && this.state.endDate != undefined) &&
            (this.state.requestName != '' && this.state.requestName != undefined) &&
            (this.state.email != '' && this.state.email != undefined && this.state.errorEmail) &&
            (this.state.reportFormat != '' && this.state.reportFormat != undefined) &&
            (this.state.task != '' && this.state.task != undefined) &&
            (this.state.scheduleTaskDetails != '' && this.state.scheduleTaskDetails != undefined) &&
            (this.state.selectedStartDate != '' && this.state.selectedStartDate != undefined) &&
            (this.state.selectedEndDate != '' && this.state.selectedEndDate != undefined)) {
            var data = {
                "ScheduleId": this.state.scheduleId,
                "StartDate": moment.utc((this.state.startDate)).format('YYYY-MM-DD').toString() + ' 00:00:00',
                "EndDate": moment.utc((this.state.endDate)).format('YYYY-MM-DD').toString() + ' 00:00:00',
                "Description": this.state.requestName,
                "ExtendedDescription": null,
                "NotificationEmail": this.state.email,
                "ReportFormat": this.state.reportFormat,
                "Task": this.state.task,
                "ScheduleTaskDetails": this.state.scheduleTaskDetails,
                "ReportTypeId": this.props.dashboard.reportType,
                "ScheduledTaskParameter":
                {
                    "V0": this.state.selectedStartDate,
                    "V1": this.state.selectedEndDate
                }
            };
            this.dispatchToScheduleAction(data);
        }
    }

    handleChange(field, event) {
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'requestName':
                var reqName = (value.length <= 0) ? false : true;
                errors.requestName =
                    (value.length <= 0)
                        ? importConstants.REQUEST_NAME_ERROR_MSG
                        : '';

                this.setState({ requestNameError: reqName });
                break;
            case 'email':
                var isValid = validateMultipleEmailsCommaSeparated(value);
                var email = (value.length <= 0 || !isValid) ? false : true;
                errors.email = (value.length <= 0)
                    ? importConstants.EMAIL_ERROR_MSG : '';
                this.setState({ errorEmail: email })
                this.setState({ invalidEmail: isValid })
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value });
    }

    handleChangeEnable(field, e) {
        this.setState({ determineForSpecificDayTextBox: '' });
    }

    handleChangeDetermineSaveEnable(e) {
        this.setState({ determineForSpecificDayTextBox: '' });
        this.setState({ determineSaveEnable: true })
    }

    handleChangeWeekly(e) {
        this.setState({ determineForSpecificDayTextBox: '' });
    }

    handleChangeSpecificDayMonthAndQuarter(field, event) {
        const { name, value } = event.target;
        switch (name) {
            case 'month':
                this.setState({ determineForSpecificDayTextBox: true });
                var determineMonthValue = $("#month").val();
                if ((determineMonthValue >= 1 && determineMonthValue <= 31) && determineMonthValue != '') {
                    this.setState({ determineSaveEnable: true })
                }
                else if (determineMonthValue == '') {
                    $('.text-limit').text(importConstants.SPECIFIC_DAY_MONTH_ERROR_MSG);
                    this.setState({ determineSaveEnable: false })
                }
                else {
                    this.setState({ determineSaveEnable: false })
                }
                break;
            case 'quarter':
                this.setState({ determineForSpecificDayTextBox: true });
                var determineQuarterValue = $("#quarter").val();
                if ((determineQuarterValue >= 1 && determineQuarterValue <= 90) && determineQuarterValue != '') {
                    this.setState({ determineSaveEnable: true })
                }
                else if (determineQuarterValue == '') {
                    $('.text-limit-quarter').text(importConstants.SPECIFIC_DAY_QUARTER_ERROR_MSG);
                    this.setState({ determineSaveEnable: false })
                }
                else {
                    this.setState({ determineSaveEnable: false })
                }
                break;
            default:
                break;
        }
    }

    reportName(reportTypeId) {
        var date = new Date();
        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";
        var month = month[date.getMonth()];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var seconds = date.getSeconds();
        seconds = seconds < 10 ? '0' + seconds : seconds;
        var getDate = date.getDate() > 10 ? date.getDate() : '0' + date.getDate();
        var reportDateTime = month + '.' + ' ' + getDate + ' ' + date.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
        switch (reportTypeId) {
            case 1: return (headerConstants.STUDENT_IMPORT_REPORT + ' ' + reportDateTime);
            case 2: return (headerConstants.STUDENT_JOB_FUNCTION_REPORT + ' ' + reportDateTime);
            case 3: return (headerConstants.STUDENT_LICENSE_IMPORT_REPORT + ' ' + reportDateTime);
            case 4: return (headerConstants.STUDENT_ROLE_IMPORT_REPORT + ' ' + reportDateTime);
            case 5: return (headerConstants.STUDENT_SUPERVISOR_IMPORT_REPORT + ' ' + reportDateTime);
            case 6: return (headerConstants.STUDENT_CERTIFICATION_IMPORT_REPORT + ' ' + reportDateTime);
            case 7: return (headerConstants.STUDENT_BADGE_IMPORT_REPORT + ' ' + reportDateTime);
            case 8: return (headerConstants.STUDENT_EDUCATION_HISTORY_IMPORT_REPORT + ' ' + reportDateTime);
            case 9: return (headerConstants.JOB_TITLE_IMPORT_REPORT + ' ' + reportDateTime);
            case 10: return (headerConstants.SUPPLEMENTAL_MANAGER_IMPORT_REPORT + ' ' + reportDateTime);
            case 11: return (headerConstants.STUDENT_PORTFOLIO_EDUCATION_IMPORT_REPORT + ' ' + reportDateTime);
            default:
                break;
        }
    }

    render() {

        if (this.state.determineForSpecificDayTextBox != true) {
            if (($("#sun").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#mon").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#tue").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#wed").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#thur").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#fri").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#sat").prop("checked") == true)) {
                this.state.determineSaveEnable = true
            }
            else if (($("#sun").prop("checked") == false) && ($("#mon").prop("checked") == false)
                && ($("#tue").prop("checked") == false)
                && ($("#wed").prop("checked") == false)
                && ($("#thur").prop("checked") == false)
                && ($("#fri").prop("checked") == false)
                && ($("#sat").prop("checked") == false)
                && ($("#dailyFreq").prop("checked") == false)
                && ($("#firstDayMonthly").prop("checked") == false)
                && ($("#lastDayMonthly").prop("checked") == false)
                && ($("#firstDayQuar").prop("checked") == false)
                && ($("#lastDayQuar").prop("checked") == false)
                && ($("#monthlyFreq").prop("checked") == false)
                && ($("#quarRadio").prop("checked") == false)
                && ($("#quarter").prop("checked") == false)
                && ($("#month").prop("checked") == false)
            ) {
                this.state.determineSaveEnable = false
            }
            else {
                this.state.determineSaveEnable = true
            }
        }
        const navigateToManageSchedule = () =>
        (
            (this.props.dashboard.successfulMessage != undefined &&
                this.props.dispatch(importSummaryActions.toggleScheduleManageModal(this.props.dashboard.reportType, this.props.dashboard.successfulMessage))))

        if (this.props.dashboard.successfulMessage == importConstants.SCHEDULE_SUCCESSFUL_MESSAGE) {
            $(function () {
                $('#successfulSaveMessageId').fadeIn();
                navigateToManageSchedule();
            });
        }

        if (this.props.dashboard.unSuccessfulMessage == importConstants.SCHEDULE_UNSUCCESSFUL_MESSAGE) {
            $(function () {
                $('#unSuccessfulSaveMessageId').show();
            });
        }

        $(function () {
            $(".radio-daily").click(function () {
                $(".radio-monthly").prop("checked", false);
                $(".radio-quarter").prop("checked", false);
                $(".radio-weekly").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $("#quarter").val('');
                $("#month").val('');
                $('#chkWeeklyId').hide();
                $('.text-limit-quarter').text('');
                $('.text-limit').text('');
            });
        });

        $(function () {
            $(".radio-weekly").click(function () {
                $(".radio-monthly").prop("checked", false);
                $(".radio-daily").prop("checked", false);
                $(".radio-quarter").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $("#quarter").val('');
                $("#month").val('');
                $('.text-limit-quarter').text('');
                $('.text-limit').text('');
                $('#chkWeeklyId').show();
            });

        });

        $(function () {
            $(".checkbox-weekly").click(function () {
                $(".radio-monthly").prop("checked", false);
                $(".radio-daily").prop("checked", false);
                $(".radio-quarter").prop("checked", false);
                $(".radio-weekly").prop("checked", true);
                $("#quarter").val('');
                $("#month").val('');
                $('.text-limit-quarter').text('');
                $('.text-limit').text('');
                if ($('.checkbox-weekly').is(':checked')) {
                    $('#chkWeeklyId').hide();
                }
                else {
                    $('#chkWeeklyId').show();
                }
            });
        });

        $(function () {
            $("#quarRadio").click(function () {
                $(".radio-monthly").prop("checked", false);
                $(".radio-daily").prop("checked", false);
                $(".radio-weekly").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $("#specificDayQuar").prop("checked", true);
                $("#specificDayMonthly").prop("checked", false);
                $("#quarter").val('1');
                $("#month").val('');
                $('#chkWeeklyId').hide();
                $('.text-limit').text('');
                $('.text-limit-quarter').text('');
            });
        });

        $(function () {
            $("#monthlyFreq").click(function () {
                $(".radio-quarter").prop("checked", false);
                $(".radio-daily").prop("checked", false);
                $(".radio-weekly").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $("#specificDayMonthly").prop("checked", true);
                $("#specificDayQuar").prop("checked", false);
                $("#quarter").val('');
                $("#month").val('1');
                $('#chkWeeklyId').hide();
                $('.text-limit').text('');
                $('.text-limit-quarter').text('');
            });
        });

        $(function () {
            $(".radio-monthly").click(function () {
                $("#monthlyFreq").prop("checked", true);
                $("#quarter").val('');
                $("#month").val('');
                $('.text-limit-quarter').text('');
                $('.text-limit').text('');
                $(".radio-quarter").prop("checked", false);
                $("#specificDayQuar").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $('#chkWeeklyId').hide();
                if ($("#specificDayMonthly").prop("checked")) {
                    $("#month").val('1');
                }
            });
        });

        $(function () {
            $(".radio-quarter").click(function () {
                $("#quarRadio").prop("checked", true);
                $(".radio-monthly").prop("checked", false);
                $("#quarter").val('');
                $("#month").val('');
                $('.text-limit-quarter').text('')
                $('.text-limit').text('');
                $("#specificDayMonthly").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $('#chkWeeklyId').hide();
                if ($("#specificDayQuar").prop("checked")) {
                    $("#quarter").val('1');
                }
            });
        });

        var minLength = 1;
        var maxLength = 31;
        $(function () {
            $('#month').on('keydown keyup change', function () {
                var charLength = $(this).val();
                if ((charLength >= minLength) && (charLength <= maxLength) && charLength != '') {
                    $('.text-limit').text('');
                }
                else if (charLength == '') {
                    $('.text-limit').text(importConstants.SPECIFIC_DAY_MONTH_ERROR_MSG);
                }
                else {
                    $('.text-limit').text(importConstants.MONTH_LIMIT_ERROR_MSG);
                }
            });
        });

        $(function () {
            $("#month").focus(function () {
                $('#chkWeeklyId').hide();
                $(".radio-monthly").prop("checked", true);
                $(".radio-quarter").prop("checked", false);
                $('.text-limit-quarter').text('');
                $(".checkbox-weekly").prop("checked", false);
                $("#quarter").val('');
            });
        });

        $(function () {
            $("#quarter").focus(function () {
                $('#chkWeeklyId').hide();
                $(".radio-quarter").prop("checked", true);
                $(".radio-monthly").prop("checked", false);
                $(".checkbox-weekly").prop("checked", false);
                $('.text-limit').text('');
                $("#month").val('');
            });
        });

        var minLen = 1;
        var maxLen = 90;
        $(function () {
            $('#quarter').on('keydown keyup change', function () {
                var charLength = $(this).val();
                if ((charLength >= minLen) && (charLength <= maxLen) && charLength != '') {
                    $('.text-limit-quarter').text('');
                }
                else if (charLength == '') {
                    $('.text-limit-quarter').text(importConstants.SPECIFIC_DAY_QUARTER_ERROR_MSG);
                }
                else {
                    $('.text-limit-quarter').text(importConstants.QUARTER_LIMIT_ERROR_MSG);
                }
            });
        });

        var institutionDetails = this.props.login;
        return (
            <div>
                <div id="createScheduleHeader" className="text-center report-header">
                    <div className="h3">{institutionDetails.orgNodeData.OrgNodeName}</div>
                    <div className="h2">Schedule {uiConstants.ReportTypes[this.props.dashboard.reportType - 1].label} Results Report</div>
                    <div className="saveMessage" id="unSuccessfulSaveMessageId">{this.props.dashboard.unSuccessfulMessage}</div>
                </div>
                <div id="createScheduleForm">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-sm-5">
                                <p>Required are marked with <span className="text-warning icon-asterisk"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Request Name <span className="text-warning icon-asterisk"></span></label>
                            </div >
                            <div className="col-sm-8">
                                <input type="text" name="requestName" className="form-control" id="requestName" placeholder="Request Name"
                                    onChange={this.handleChange.bind(this, "requestName")} value={this.state.requestName} />
                                {this.state.requestName.length <= 0 &&
                                    <p className="textField">{this.state.errors.requestName}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row mt-2">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Email Address <span className="text-warning icon-asterisk"></span></label>
                            </div >
                            <div className="col-sm-8">
                                <input type="email" name="email" className="form-control" id="email" placeholder="Enter email"
                                    onChange={this.handleChange.bind(this, "email")} value={this.state.email} multiple
                                />
                                {this.state.email.length <= 0 &&
                                    <p className="textField">{this.state.errors.email}</p>}
                                {this.state.invalidEmail === false &&
                                    <p id="textField">Email address must be valid.</p>}
                                <p style={{ marginBottom: 0 }}>Multiple email addresses must be separated by semi-colons.</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row mt-2">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Report Format <span className="text-warning icon-asterisk"></span></label>
                            </div >

                            <div className="col-sm-8">
                                <select name="Format" className="form-control"
                                    onChange={this.handleDropdownFormatChange} value={this.state.reportFormat}>
                                    <option value="256" defaultChecked>CSV</option>
                                    <option value="16">Excel</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row mt-2">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Report Date Range <span className="text-warning icon-asterisk"></span></label>
                            </div >
                            <div className="col-sm-8 ">
                                <select name="reportDateRange" onChange={this.handleDropdownChange}
                                    value={this.state.selectReportDateRange}
                                    className="form-control">
                                    <option value="24-Hrs" defaultChecked>Last 24 hours</option>
                                    <option value="72-Hrs">Last 72 hours</option>
                                    <option value="7-Days">Last 7 days</option>
                                    <option value="14-Days">Last 14 days</option>
                                    <option value="30-Days">Last 30 days</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row mt-2">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Choose frequency of schedule task <span className="text-warning icon-asterisk" ></span></label>
                            </div >
                            <div name="freqOfScheduleTask" className="col-sm-8">
                                <div className="form-check">
                                    <input className="radio-daily" type="radio" name="freqRadio"
                                        onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="dailyFreq" value="option1" defaultChecked="true" />
                                    <label className="form-check-label ml-2" for="dailyFreq">
                                        Daily
                                        </label>
                                </div>

                                <div name="divWeeklyFreq" className={"form-check"}>
                                    <input className="radio-weekly" onClick={this.handleChangeWeekly.bind(this)}
                                        type="radio" name="freqRadio" id="weeklyFreq" value="option2" />
                                    <label className="form-check-label ml-2" for="weeklyFreq" >
                                        Weekly
                                    </label>

                                    <div className="form-inline ml-4 daysWeek">
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)}
                                                    className="checkbox-weekly" id="sun" name="sun" /> Sun
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)} className="checkbox-weekly" id="mon" name="mon" /> Mon
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)} className="checkbox-weekly" id="tue" name="tue" /> Tue
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)} className="checkbox-weekly" id="wed" name="wed" /> Wed
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)} className="checkbox-weekly" id="thur" name="thur" /> Thu
                                        </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)} className="checkbox-weekly" id="fri" name="fri" /> Fri
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" onClick={this.handleChangeEnable.bind(this)} className="checkbox-weekly" id="sat" name="sat" /> Sat
                                            </label>
                                        </div>
                                    </div>
                                    <div id="chkWeeklyId" style={{ display: 'none' }}>
                                        {
                                            <p className="textField">You must select at least one day when creating a weekly schedule.</p>
                                        }
                                    </div>
                                </div>
                                <div name="divMonthlyFreq" className={"mt-2 form-check"}>
                                    <input className="radio-monthly" type="radio" name="freqRadio"
                                        onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="monthlyFreq" value="option3" />
                                    <label className="form-check-label ml-2" for="monthlyFreq">
                                        Monthly
                                    </label>
                                    <div className="form-check radio-btn">
                                        <input className="radio-monthly" type="radio" name="whatDayMonthly"
                                            onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="firstDayMonthly" value="option1" />
                                        <label className="form-check-label ml-2" for="firstDayMonthly">
                                            1st day of month
                                        </label>
                                    </div>
                                    <div className="form-check radio-btn">
                                        <input className="radio-monthly" type="radio" name="whatDayMonthly"
                                            onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="lastDayMonthly" value="option2" />
                                        <label className="form-check-label ml-2" for="lastDayMonthly">
                                            Last day of month
                                        </label>
                                    </div>
                                    <div className="form-check radio-btn">
                                        <input className="radio-monthly" type="radio" name="whatDayMonthly"
                                            onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="specificDayMonthly" value="option3"
                                        />
                                        <label className="form-check-label ml-2" for="specificDayMonthly"
                                        >
                                            Specific day of month
                                        </label>
                                        <input name="month" placeholder="day" id="month" className="mt-2 form-control"
                                            type="text"
                                            onFocus={this.handleChangeSpecificDayMonthAndQuarter.bind(this, "month")}
                                            onChange={this.handleChangeSpecificDayMonthAndQuarter.bind(this, "month")}
                                            onClick={this.handleChangeSpecificDayMonthAndQuarter.bind(this, "month")} />
                                        <span id="textField" className="text-limit"></span>
                                    </div>
                                </div>
                                <div name="divQuarterlyFreq" className={"form-check mt-4"}>
                                    <input className="radio-quarter" type="radio" name="freqRadio"
                                        onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="quarRadio" value="option4" />
                                    <label className="form-check-label" for="quarRadio" style={{ marginLeft: '5px' }}>
                                        Quarterly
                                    </label>
                                    <div className="form-check radio-btn">
                                        <input className="radio-quarter" type="radio" name="whatDayQuar"
                                            onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="firstDayQuar" value="option1" />
                                        <label className="form-check-label" for="firstDayQuar" style={{ marginLeft: '5px' }}    >
                                            1st day of quarter
                                    </label>
                                    </div>
                                    <div className="form-check radio-btn">
                                        <input className="radio-quarter" type="radio" name="whatDayQuar"
                                            onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="lastDayQuar" value="option2" />
                                        <label className="form-check-label" for="lastDayQuar" style={{ marginLeft: '5px' }}>
                                            Last day of quarter
                                        </label>
                                    </div>
                                    <div className="form-check radio-btn">
                                        <input className="radio-quarter" type="radio" name="whatDayQuar"
                                            onClick={this.handleChangeDetermineSaveEnable.bind(this)} id="specificDayQuar" value="option3" />
                                        <label className="form-check-label" for="specificDayQuar" style={{ marginLeft: '5px' }}>
                                            Specific day of quarter
                                        </label>
                                        <input name="quarter" placeholder="day" id="quarter" className="mt-2 form-control"
                                            type="text"
                                            onFocus={this.handleChangeSpecificDayMonthAndQuarter.bind(this, "quarter")}
                                            onChange={this.handleChangeSpecificDayMonthAndQuarter.bind(this, "quarter")}
                                            onClick={this.handleChangeSpecificDayMonthAndQuarter.bind(this, "quarter")}
                                        />
                                        <span id="textField" className="text-limit-quarter"></span>
                                        <p style={{ marginBottom: 0 }}>Note: quarters are defined as calendar quarters.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row mt-2">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Schedule Start Date <span className="text-warning icon-asterisk" ></span></label>
                            </div>
                            <div className="col-sm-3">
                                <DateTimePicker
                                    id="startDate"
                                    name="startDate"
                                    time={false}
                                    format={uiConstants.DateRangeDisplayFormat}
                                    placeholder={uiConstants.DateRangeDisplayFormat}
                                    defaultValue={this.fromDate}
                                    views={['month', 'year', 'decade']}
                                    footer={false}
                                    onChange={this.handleFromDateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                                {this.state.isFromDateInvalid === true &&
                                    <p id="textField">Schedule Start Date must be a valid date.</p>}
                                {(this.state.isStartAfterEnd === true && this.state.isEndBeforeStart === false) &&
                                    <p id="textField">Schedule Start Date cannot be after Schedule End Date.</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row mt-2">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                                <label>Schedule End Date <span className="text-warning icon-asterisk" ></span></label>
                            </div>
                            <div className="col-sm-3">
                                <DateTimePicker
                                    id="endDate"
                                    name="endDate"
                                    time={false}
                                    format={uiConstants.DateRangeDisplayFormat}
                                    placeholder={uiConstants.DateRangeDisplayFormat}
                                    defaultValue={this.toDate}
                                    views={['month', 'year', 'decade']}
                                    footer={false}
                                    onChange={this.handleEndDateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                                {this.state.isToDateInvalid === true &&
                                    <p id="textField">Schedule End Date must be a valid date.</p>}
                                {(this.state.isStartAfterEnd === true && this.state.isEndBeforeStart === true) &&
                                    <p id="textField">Schedule End Date cannot precede Schedule Start Date.</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-sm-7">
                                <p>Note: Scheduled tasks are processed nightly between Midnight and 8 AM ET.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="pull-right">
                            <button type="submit" id="saveBtn" className="btn btn-success"
                                disabled={this.state.errorEmail === false ||
                                    this.state.isFromDateInvalid === true
                                    || this.state.isToDateInvalid === true || this.state.requestNameError === false
                                    || this.state.isStartAfterEnd === true
                                    || this.state.determineSaveEnable === false}
                                onClick={
                                    this.btnClick.bind(this)
                                }>Save</button>

                            <button id="cancelBtn" className="btn btn-secondary ml-2" data-dismiss="modal"
                                onClick={this.props.closeData}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

function validateMultipleEmailsCommaSeparated(value) {
    var result = value.split(/;/);
    if (value !== '') {
        for (var i = 0; i < result.length; i++) {
            if (!validateEmail(result[i])) {
                return false;
            }

        }
    }
    return true;
}

function validateEmail(field) {
    var regex = /^(([\w-\.'\s]+(\.[^<>()\[\]\\.,;:\s*@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-_0-9]+\.)+[a-zA-Z]{2,4}))$/;
    var regexForSpace = /\s/g;
    var result = $.trim(field);
    return (regex.test(result) && !regexForSpace.test(result)) ? true : false;
}

function mapStateToProps(state) {
    const { dashboard, login } = state;
    return {
        dashboard,
        login
    };
}
export default connect(mapStateToProps)(ScheduleCreateDetails);