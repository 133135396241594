import React from 'react';
import Header from '../Common/Header';
import { uiConstants } from '../../Constants/UI.Constants';
class LogOut extends React.Component {
    logSessionOut() {
        sessionStorage.clear(); //clear all the header information
        window.close();
    }
    render() {
        return (
            <div>
                <div>
                    <Header />
                </div>
                <div className="container-fluid">
                    <div className="row import-results-header">
                        <h3 className="logout-msg col-12 py-4 text-center"> {uiConstants.LogOutMessage}</h3>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <button className="btn btn-success" onClick={this.logSessionOut}>Close Tab</button>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default LogOut;
