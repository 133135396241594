import axios from 'axios';
import { config } from '../../config';

export const studentJobFunctionService = {
    getStudentJobFunctionInProgressData,
    getStudentJobFunctionSuccessData,
    getStudentJobFunctionErrorData,
    getStudentJobFunctionDownloadData
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

function getStudentJobFunctionInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    
    return axios.get((config.api + '/studentjobfunction/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

function getStudentJobFunctionSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/studentjobfunction/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

function getStudentJobFunctionErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/studentjobfunction/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

function getStudentJobFunctionDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/studentjobfunction/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}
