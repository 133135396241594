import * as XLSX from 'xlsx';
import * as ExportDetails from './ExportReportDetails';
import { uiConstants } from '../Constants/UI.Constants';
import { getFormattedDate } from './DateFormatter';
import { Parser } from 'json2csv';

//Builds Summary sheet structure for Export Reports Excel Format.
function buildExportExcelReportSummary(dateRange, reportTypeId, importStatus) {
    let reportGenValue = JSON.parse(sessionStorage.getItem("reportGenTime"));
    let InstitutionDetails = JSON.parse(sessionStorage.getItem("login"));
    let convertedDate = getFormattedDate(dateRange.fromDate, dateRange.toDate);
    let summarySheetTitle;

    if (importStatus === uiConstants.ImportStatus.Success) {
        summarySheetTitle = ExportDetails.getSuccessSheetTitle(reportTypeId);
    }
    else if (importStatus === uiConstants.ImportStatus.Error) {
        summarySheetTitle = ExportDetails.getErrorSheetTitle(reportTypeId);
    }
    else if (importStatus === uiConstants.ImportStatus.InProgress) {
        summarySheetTitle = ExportDetails.getInProgressSheetTitle(reportTypeId);
    }

    let workSheet = XLSX.utils.json_to_sheet(summarySheetTitle, { skipHeader: true, origin: "A1" });
    XLSX.utils.sheet_add_json(workSheet, [{ "InstitutionName": "Institution Name: " + InstitutionDetails.loginDetails.OrgNodeName }], { skipHeader: true, origin: "A3" });
    XLSX.utils.sheet_add_json(workSheet, [{ "DateRange": "Date Range: " + convertedDate.fDateVal + " through " + convertedDate.tDateVal }], { skipHeader: true, origin: "A5" });
    XLSX.utils.sheet_add_json(workSheet, [{ "ReportGenerated": "Report Generated: " + reportGenValue.reportGenTime }], { skipHeader: true, origin: "A7" });

    return workSheet;
}

//Builds Report Structure for Download Csv format.
function buildDownloadCsvReportResultSet(reportData, reportTypeId, importStatus) {
    let statusResultData = ExportDetails.getStatusSectionDetails(reportData, reportTypeId);
    let errorResultData = ExportDetails.getErrorSectionDetails(reportData, reportTypeId);
    let csvStatusParser = new Parser({ fields: uiConstants.StatusFields });
    let csvStatusData = csvStatusParser.parse(statusResultData);
    let statusTotals = ExportDetails.getStatusTotals(reportData, false);
    var csvActionData;
    var csvData;
    var csvActionDataResult;
    var actionResultData;
    var csvActionParser;
    var actionTotals;

    let csvErrorParser = new Parser({ fields: ExportDetails.getErrorSectionFields(reportTypeId) });
    let csvErrorData = csvErrorParser.parse(errorResultData);
    let csvStatusDataResult = uiConstants.Bom + '"' + uiConstants.ImportStatusSummary + '"' + uiConstants.NewLineString + csvStatusData + uiConstants.NewLineString + statusTotals + uiConstants.MultiLineString + '"';

    let csvErrorDataResult = uiConstants.ImportErrorHeader + '"' + uiConstants.NewLineString + csvErrorData;

    if (Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val === reportTypeId.toString())) {
        csvData = csvStatusDataResult + csvErrorDataResult;
    }
    else {
        actionResultData = ExportDetails.getActionSectionDetails(reportData, reportTypeId, importStatus);
        csvActionParser = new Parser({ fields: ExportDetails.getActionSectionFields(reportTypeId) });
        csvActionData = csvActionParser.parse(actionResultData);
        actionTotals = ExportDetails.getActionTotalsForCsv(reportData, reportTypeId);
        csvActionDataResult = uiConstants.ImportActionHeader + '"' + uiConstants.NewLineString + csvActionData +
            uiConstants.NewLineString + actionTotals + uiConstants.MultiLineString + '"';
        csvData = csvStatusDataResult + csvActionDataResult + csvErrorDataResult;
    }

    return csvData;
}

//Builds Report structure for Export Excel Format.
function buildExportExcelReportResultSet(reportData, reportTypeId, importStatus) {
    let reportResultData = ExportDetails.getExportSuccessAndErrorDetails(reportData, importStatus, reportTypeId);
    let statusTotals = ExportDetails.getStatusTotals(reportData, true); 
    let actionTotals;
    // To check whether the reportType Id requires Action Section or not.  
    let isAction = Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val == reportTypeId);
    let isSuccess = importStatus === uiConstants.ImportStatus.Success ? true : false;

    if(!isAction){
        actionTotals =  ExportDetails.getActionTotals(reportData, reportTypeId);
    }
    let getHeader;
    let actionDownloadHeaders;
    
    // Modify This conditional Check, if any report requirement is same as SupplementalmanagerImport
    if(isAction&&isSuccess){
        getHeader = '';
        actionDownloadHeaders = [];
    }
    else{
        getHeader = ExportDetails.getExportHeader(importStatus);
        actionDownloadHeaders = ExportDetails.getResultSectionHeader(reportTypeId, importStatus);
    }

    const statusDownloadHeaders = uiConstants.ExportReportStatusHeaders;
    let statusSectionDetails = reportResultData.statusSectionDetails ? reportResultData.statusSectionDetails : [];
    let resultSectionDetails = reportResultData.resultSectionDetails ? reportResultData.resultSectionDetails : [];

    let workSheet = XLSX.utils.json_to_sheet(statusSectionDetails, { skipHeader: true, origin: "A3" });

    XLSX.utils.sheet_add_json(workSheet, [{ A: uiConstants.ImportStatusSummary }], { skipHeader: true, origin: "A1" });
    XLSX.utils.sheet_add_json(workSheet, statusDownloadHeaders, { skipHeader: true, origin: "A2" });
    XLSX.utils.sheet_add_json(workSheet, statusTotals, { skipHeader: true, origin: statusSectionDetails.length + 2 });
    XLSX.utils.sheet_add_json(workSheet, [getHeader], { skipHeader: false, origin: statusSectionDetails.length + 4 });
   

    if (importStatus !== uiConstants.ImportStatus.InProgress) {
        XLSX.utils.sheet_add_json(workSheet, resultSectionDetails, { skipHeader: true, origin: statusSectionDetails.length + 6 });
        XLSX.utils.sheet_add_json(workSheet, actionDownloadHeaders, { skipHeader: true, origin: statusSectionDetails.length + 5 });
    }

    if (importStatus === uiConstants.ImportStatus.Success && !isAction) {
        XLSX.utils.sheet_add_json(workSheet, actionTotals, { skipHeader: true, origin: statusSectionDetails.length + reportResultData.resultSectionDetails.length + 6 });
    }

    return workSheet;
}

//Build Report Structure for Export CSV format.
function buildExportCsvReportResultSet(reportData, reportTypeId, importStatus) {
    let reportResultData = ExportDetails.getExportSuccessAndErrorDetails(reportData, importStatus, reportTypeId);
    let statusTotals = ExportDetails.getStatusTotals(reportData, false);
    // To check whether the reportType Id requires Action Section or not.
    let isAction = Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val == reportTypeId);
    let actionTotals =  importStatus==uiConstants.ImportStatus.Success ? ExportDetails.getActionTotalsForCsv(reportData, reportTypeId) : "";
    let formattedStatusData;
    let formattedActionData;
    let csvResultSectionData;
    let csvActionHeader;

    formattedStatusData = ExportDetails.getFormattedStatusReportData(reportResultData.statusSectionDetails, reportTypeId, importStatus);
    let csvStatusParser = new Parser({ fields: uiConstants.StatusFields });
    let csvStatusData = csvStatusParser.parse(formattedStatusData);

    if (importStatus === uiConstants.ImportStatus.Success && !isAction) {
        formattedActionData = ExportDetails.getFormattedActionReportData(reportResultData.resultSectionDetails, reportTypeId, importStatus);
        let csvActionParser = new Parser({ fields: ExportDetails.getActionSectionFields(reportTypeId) });
        let csvResultsHeader = csvActionParser.parse(formattedActionData);

        csvResultSectionData = csvResultsHeader;
        csvActionHeader = uiConstants.ImportActionHeader;
    }
    else if (importStatus === uiConstants.ImportStatus.Error) {
        let csvErrorParser = new Parser({ fields: ExportDetails.getErrorSectionFields(reportTypeId) });
        let csvErrorData = csvErrorParser.parse(reportResultData.resultSectionDetails);

        csvResultSectionData = csvErrorData;
        csvActionHeader = uiConstants.ImportErrorHeader;
    }
    let csvData;
    
     csvData = uiConstants.Bom + '"' + uiConstants.ImportStatusSummary + '"' + uiConstants.NewLineString + csvStatusData + uiConstants.NewLineString + statusTotals + uiConstants.MultiLineString
        + '"' + csvActionHeader + '"' + uiConstants.NewLineString + csvResultSectionData + uiConstants.NewLineString + actionTotals;

    if (importStatus === uiConstants.ImportStatus.InProgress || (isAction && importStatus === uiConstants.ImportStatus.Success)) {
        csvData = uiConstants.Bom + '"' + uiConstants.ImportStatusSummary + '"' + uiConstants.NewLineString + csvStatusData + uiConstants.NewLineString + statusTotals;
    }

    return csvData;
}

export { buildExportExcelReportSummary, buildDownloadCsvReportResultSet, buildExportExcelReportResultSet, buildExportCsvReportResultSet }

