import axios from 'axios';
import { config } from '../../config';

export const importSummaryService = {
    getReportWidgetsData,
    getReportWidgetDataById
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';
    
function getReportWidgetsData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/dashboard/widgets?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });  
}

function getReportWidgetDataById(fromDate, toDate, id, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/dashboard/widgets/' + id + '?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}