// Follow JSON's syntax - 
//Always use double quotes 
//Always quotify your keys

export const uiConstants = {

    //Import Report types
    ReportTypes:
        [
            { value: 1, label: "Student Import" },
            { value: 2, label: "Student Job Function Import" },
            { value: 3, label: "Student License Import" },
            { value: 4, label: "Role Import" },
            { value: 5, label: "Supervisor Import" },
            { value: 6, label: "Student Certification Import" },
            { value: 7, label: "Student Badge Import" },
            { value: 8, label: "Student Education History Import" },
            { value: 9, label: "Job Title Import" },
            { value: 10, label: "Supplemental Manager Import" },
            { value: 11, label: "Student Portfolio Education Import"}
        ],

    //Date drop down list values
    DateRangeFilters:
        [
            { value: "24-hr", label: "Last 24 hours" },
            { value: "72-hr", label: "Last 72 hours" },
            { value: "7-day", label: "Last 7 days" },
            { value: "14-day", label: "Last 14 days" },
            { value: "30-day", label: "Last 30 days" },
            { value: "custom", label: "Custom Range" }
        ],

    ReportTypeHeaders:
        [
            { value: "1s", label: "Student Import Success Report" },
            { value: "1e", label: "Student Import Error Report" },
            { value: "1i", label: "Student Import In Progress Report" },
            { value: "2s", label: "Student Job Function Import Success Report" },
            { value: "2e", label: "Student Job Function Import Error Report" },
            { value: "2i", label: "Student Job Function Import In Progress Report" },
            { value: "3s", label: "Student License Import Success Report" },
            { value: "3e", label: "Student License Import Error Report" },
            { value: "3i", label: "Student License Import In Progress Report" },
            { value: "4s", label: "Role Import Success Report" },
            { value: "4e", label: "Role Import Error Report" },
            { value: "4i", label: "Role Import In Progress Report" },
            { value: "5s", label: "Supervisor Import Success Report" },
            { value: "5e", label: "Supervisor Import Error Report" },
            { value: "5i", label: "Supervisor Import In Progress Report" },
            { value: "6s", label: "Student Certification Import Success Report" },
            { value: "6e", label: "Student Certification Import Error Report" },
            { value: "6i", label: "Student Certification Import In Progress Report" },
            { value: "7s", label: "Student Badge Import Success Report" },
            { value: "7e", label: "Student Badge Import Error Report" },
            { value: "7i", label: "Student Badge Import In Progress Report" },
            { value: "8s", label: "Student Education History Import Success Report" },
            { value: "8e", label: "Student Education History Import Error Report" },
            { value: "8i", label: "Student Education History Import In Progress Report" },
            { value: "9s", label: "Job Title Import Success Report" },
            { value: "9e", label: "Job Title Import Error Report" },
            { value: "9i", label: "Job Title Import In Progress Report" },
            { value: "10s", label: "Supplemental Manager Import Success Report" },
            { value: "10e", label: "Supplemental Manager Import Error Report" },
            { value: "10i", label: "Supplemental Manager Import In Progress Report" },
            { value: "11s", label: "Student Portfolio Education Import Success Report"},
            { value: "11e", label: "Student Portfolio Education Import Error Report"},
            { value: "11i", label: "Student Portfolio Education Import In Progress Report" }
        ],
    ReportDetailStatusHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Status" },
            { title: "Records" }
        ],
    StudentImportActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Students Added" },
            { title: "Students Updated" },
            { title: "Users Removed" },
            { title: "Departments Added" },
            { title: "Job Titles Added" },
            { title: "Custom Values Added" }
        ],
    StudentImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student" },
            { title: "Student ID" },
            { title: "Error Message" }
        ],
    StudentJobFunctionActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Inserts" },
            { title: "Updates" },
            { title: "Deletes" }
        ],
    StudentJobFunctionErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student ID" },
            { title: "Job Function" },
            { title: "Error Message" }
        ],
    StudentLicenseActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Licenses Added" },
            { title: "Licenses Updated" },
            { title: "Licenses Removed" }
        ],
    StudentLicenseErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student" },
            { title: "Student ID" },
            { title: "Error Message" }
        ],
    RoleImportActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Roles Added" },
            { title: "Roles Updated" }, 
            { title: "Roles Deleted" }
        ],
    RoleImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student ID" },
            { title: "Role" },
            { title: "Error Message" }
        ],
    SupervisorImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Supervisor ID" },
            { title: "Error Message" }
        ],
    SupervisorImportActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Supervisors Added" },
            { title: "Supervisors Updated" },
            { title: "Supervisors Deleted" }
        ],
    StudentCertificationActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Certifications Added" },
            { title: "Certifications Updated" },
            { title: "Certifications Deleted" }
        ],
    StudentCertificationImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student ID" },
            { title: "Error Message" }
        ],

    StudentBadgeActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Badges Added" },
            { title: "Badges Updated" },
            { title: "Badges Removed" }
        ],
    StudentBadgeImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student ID" },
            { title: "Error Message" }
        ],

    StudentEducationHistoryActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Records Added" },
            { title: "Records Updated" },
            { title: "Records Removed" }
        ],
    StudEduHistoryImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Student ID" },
            { title: "Course" },
            { title: "Completion Date" },
            { title: "Error Message" },
        ],
    JobTitleImportErrorDetailHeaders:
        [
            { title: "Date" },
            { title: "Institution" },
            { title: "Job Title" },
            { title: "Error Message" },
        ],


     JobTitleImportActionDetailHeaders:
         [
             { title: "Update Day" },
             { title: "Institution" },
             { title: "Job Title Added" },
             { title: "Job Title Updated" },
             { title: "Job Title Removed" }
         ],
    
    
    StudPortfolioEduImportActionDetailHeaders:
         [
             { title: "Update Day" },
             { title: "Institution" },
             { title: "Educations Added" },
             { title: "Educations Updated" },
             { title: "Educations Deleted" }
         ],    
    
    StudPortfolioEduImportErrorDetailHeaders :
        [
            {title: "Date"},
            {title: "Institution"},
            {title: "Student ID"},
            {title: "Error Message"}
        ],  

    SupplementalManagerErrorDetailHeaders :
        [
            {title: "Date"},
            {title: "Institution"},
            {title: "Student ID"},
            {title: "Error Message"},
        ],   

     SupplementalManagerImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",
                Status: "Error Message"
            }
        ],

    JobTitleImportActionDetailHeaders:
        [
            { title: "Update Day" },
            { title: "Institution" },
            { title: "Job Title Added" },
            { title: "Job Title Updated" },
            { title: "Job Title Removed" }
        ],
    StudentImportStatusDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                Status: "Status",
                StudentsCount: "Records"
            }
        ],
    StudentJobFunctionStatusDownloadHeaders:
        [
            {
                UpdateDatetime: "Update Day",
                InstitutionDisplayName: "Institution",
                Status: "Status",
                JobFunctions: "Records"
            }
        ],

    ExportReportStatusHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                Status: "Status",
                StudentsCount: "Records"
            }
        ],
    StudentImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                StudentsAdded: "Students Added",
                StudentsUpdated: "Students Updated",
                UsersRemoved: "Users Removed",
                DepartmentsAdded: "Departments Added",
                JobTitlesAdded: "Job Titles Added",
                CustomFieldValues: "Custom Values Added"
            }
        ],
    StudentJobFunctionActionDownloadHeaders:
        [
            {
                UpdateDatetime: "Update Day",
                InstitutionDisplayName: "Institution",
                UserStudentJobFunctionInserts: "Inserts",
                UserStudentJobFunctionUpdates: "Updates",
                UserStudentJobFunctionDeletes: "Deletes"
            }
        ],
    StudentLicenseActionDownloadHeaders:
        [
            {
                UpdateDatetime: "Update Day",
                InstitutionDisplayName: "Institution",
                UserStudentLicenseInserts: "Licenses Added",
                UserStudentLicenseUpdates: "Licenses Updated",
                UserStudentLicenseDeletes: "Licenses Removed"
            }
        ],
    RoleImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                RolesAdded: "Roles Added",
                RolesUpdated: "Roles Updated",
                RolesDeleted: "Roles Deleted"
            }
        ],
    StudentCertificationImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                CertificationsAdded: "Certifications Added",
                CertificationsUpdated: "Certifications Updated",
                CertificationsDeleted: "Certifications Deleted"
            }
        ],

    StudentImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserDisplayName: "Student",
                UserName: "Student ID",
                Status: "Error Message"
            }
        ],
    StudentJobFunctionErrorDownloadHeaders:
        [
            {
                UpdateDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",
                JobFunctionName: "Job Function",
                ImportStatusDescription: "Error Message"
            }
        ],
    StudentLicenseErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserDisplayName: "Student",
                UserName: "Student ID",
                Status: "Error Message"
            }
        ],
    RoleImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",
                RoleName: "Role",
                Status: "Error Message"
            }
        ],
    SupervisorImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                SupervisorsAdded: "Supervisors Added",
                SupervisorsUpdated: "Supervisors Updated",
                SupervisorsDeleted: "Supervisors Deleted"
            }
        ],
    SupervisorImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                SupervisorUserName: "Supervisor ID",
                Status: "Error Message"
            }
        ],
    StudentCertificationImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",
                Status: "Error Message"
            }
        ],
    StudentBadgeImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                SupervisorsAdded: "Badges Added",
                SupervisorsUpdated: "Badges Updated",
                SupervisorsDeleted: "Badges Removed"
            }
        ],
    StudentBadgeImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",
                Status: "Error Message"
            }
        ],
    StudEduHistoryImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",
                Course: "Course",
                CompletionDate: "Completion Date",
                Status: "Error Message"
            }
        ],

    JobTitleImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                JobTitle: "Job Title",
                Status: "Error Message"
            }
        ],
    SupervisorImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                SupervisorsAdded: "Supervisors Added",
                SupervisorsUpdated: "Supervisors Updated",
                SupervisorsDeleted: "Supervisors Deleted"
            }
        ],
    SupervisorImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                SupervisorUserName: "Supervisor ID",              
                Status: "Error Message"
            }
        ],
    StudentCertificationImportErrorDownloadHeaders:
        [
            {
                UpdatedDateTime: "Date",
                InstitutionDisplayName: "Institution",
                UserName: "Student ID",              
                Status: "Error Message"
            }
        ],

    StuEduHistoryImportActionDownloadHeaders:
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                RecordsAdded: "Records Added",
                RecordsUpdated: "Records Updated",
                RecordsRemoved: "Records Removed"
            }
        ],
    JobTitleImportActionDownloadHeaders :
        [
            {
                UpdatedDateTime: "Update Day",
                InstitutionDisplayName: "Institution",
                JobTitlesAdded: "Job Title Added",
                JobTitleUpdated: "Job Title Updated",
                JobTitlesRemoved: "Job Title Removed"
            }
        ],
    SupplementalManagerActionDownloadHeaders :
   [
       {title: "Update Day"},
       {title: "Institution"},
       {title: "Status"},
       {title: "Records"},
   ],

    StudPortfolioEducationImportActionDownloadHeaders :
   [
       {
           UpdatedDateTime: "Update Day",
           InstitutionDisplayName: "Institution",
           EducationsAdded: "Educations Added",
           EducationsUpdated: "Educations Updated",
           EducationsDeleted: "Educations Deleted"
       }
   ],

   StudPortfolioEducationImportErrorDownloadHeaders:
   [
       {
           UpdatedDateTime: "Date",
           InstitutionDisplayName: "Institution",
           EducationsUserName: "Student ID",
           Status: "Error Message"
       }
   ],




    ExportStudentImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Student Import Success Report" }],
    ExportStudentImportErrorSheetTitle:
        [{ Title: "Import Results Title: Student Import Error Report" }],
    ExportStudentImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Student Import In Progress Report" }],
    ExportJobTitleImportErrorSheetTitle:
        [{ Title: "Import Results Title: Job Title Import Error Report" }],
    ExportJobFunctionSuccessSheetTitle:
        [{ Title: "Import Results Title: Student Job Function Import Success Report" }],
    ExportJobFunctionErrorSheetTitle:
        [{ Title: "Import Results Title: Student Job Function Import Error Report" }],
    ExportJobFunctionInProgressSheetTitle:
        [{ Title: "Import Results Title: Student Job Function Import In Progress Report" }],

    ExportStudentLicenseSuccessSheetTitle:
        [{ Title: "Import Results Title: Student License Import Success Report" }],
    ExportStudentLicenseErrorSheetTitle:
        [{ Title: "Import Results Title: Student License Import Error Report" }],
    ExportStudentLicenseInProgressSheetTitle:
        [{ Title: "Import Results Title: Student License Import In Progress Report" }],

    ExportRoleImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Role Import Success Report" }],
    ExportRoleImportErrorSheetTitle:
        [{ Title: "Import Results Title: Role Import Error Report" }],
    ExportRoleImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Role Import In Progress Report" }],

    ExportSupervisorImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Supervisor Import In Progress Report" }],
    ExportSupervisorImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Supervisor Import Success Report" }],
    ExportSupervisorImportErrorSheetTitle:
        [{ Title: "Import Results Title: Supervisor Import Error Report" }],
    ExportStudentCertififcationImportSheetTitle:
        [{ Title: "Import Results Title:  Student Certification Import Error Report" }],
    ExportStudentCertificationImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Student Certification Import Success Report" }],

    ExportStudentCertificationImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Student Certification Import In Progress Report" }],

    ExportStudentBadgeImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Student Badge Import In Progress Report" }],

    ExportStudentBadgeImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Student Badge Import Success Report" }],
    ExportStudentBadgeImportErrorSheetTitle:
        [{ Title: "Import Results Title: Student Badge Import Error Report" }],
    ExportStuEduHistoryImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Student Education History Import Success Report" }],
    ExportStudEduHistoryImportErrorSheetTitle:
        [{ Title: "Import Results Title: Student Education History Import Error Report" }],
    ExportStudEduHistoryImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Student Education History Import In Progress Report" }],
    ExportJobTitleImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Job Title Import Success Report" }],
    ExportJobTitleImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Job Title Import In Progress Report" }],

    ExportSuppMgrImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Supplemental Manager Import In Progress Report" }],
    ExportSuppMgrImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Supplemental Manager Import Success Report" }],
    ExportSuppMgrImportErrorSheetTitle:
        [{ Title: "Import Results Title: Supplemental Manager Import Error Report" }],

    ExportStudPortfolioEduImportInProgressSheetTitle:
        [{ Title: "Import Results Title: Student Portfolio Education Import In Progress Report" }],
    ExportStudPortfolioEduImportImportSuccessSheetTitle:
        [{ Title: "Import Results Title: Student Portfolio Education Import Success Report" }],
    ExportStudPortfolioEduImportImportErrorSheetTitle:
        [{ Title: "Import Results Title: Student Portfolio Education Import Error Report" }],

    ImportStatus: {
        InProgress: "InProgress",
        Success: "Success",
        Error: "Error"
    },

    ActionNotRequiredReportTypeIds: {
        1: "10",
    },

    StatusSectionHeader: ["Import Status Summary"],
    ActionSectionHeader: ["Import Action Summary"],
    ErrorSectionHeader: ["Import Error Detail"],

    ServiceNotAvailable: "Service not available.",
    NoImportData: "No import data for selected date range.",
    TryAgainLater: "Please try again later.",

    CustomDateCriteria: "Dates selected must be within last one year from today. Selected date range should not be more than 90 days.",
    InvalidDateRange: "Invalid Date Range\n",
    StartDateCantBeAfterEndDate: "Start Date cannot be after End Date\n",
    InvalidDateFormatErrorMessage: "You must use a valid date. Date must be in MM/DD/YYYY format.",

    ImportStatusSummary: "IMPORT STATUS SUMMARY",
    ImportActionSummary: { "IMPORT ACTION SUMMARY": "" },
    ImportErrorSummary: { "IMPORT ERROR DETAIL": "" },

    StudentImportFile: "stuimport-",
    StudentJobFunctionFile: "stujobfunction-",
    StudentLicenseImportFile: "stulicimport-",
    RoleImportFile: "roleimport-",
    StudentCertificationImportFile: "stucerimport-",
    StudentBadgeImportFile: "stubadgeimport-",
    StudEduHistoryImportFile: "stueduhisimport-",
    JobTitleImportFile: "jobtitleimport-",
    SuppMgrImportFile: "suppmgrimport-",
    StudPortFolioEduImportFile: "stuporteduimport-",


    SupervisorImportFile: "supervisorimport-",
    ExportSuccessSupervisorImportFile: "success-supervisorimport",
    ExportErrorSupervisorImportFile: "error-supervisorimport",

    ExportInProgressStudentImportFile: "inprog-stuimport",
    ExportSuccessStudentImportFile: "success-stuimport",
    ExportErrorStudentImportFile: "error-stuimport",

    ExportInProgressJobFunctionImportFile: "inprog-stujobfunctionimport",
    ExportSuccessJobFunctionFile: "success-stujobfunctionimport",
    ExportErrorJobFunctionFile: "error-stujobfunctionimport",

    ExportInProgressStudentLicenseImportFile: "inprog-stulicimport",
    ExportSuccessStudentLicenseFile: "success-stulicimport",
    ExportErrorStudentLicenseFile: "error-stulicimport",

    ExportInProgressRoleImportFile: "inprog-roleimport",
    ExportSuccessRoleImportFile: "success-roleimport",
    ExportErrorRoleImportFile: "error-roleimport",

    ExportInProgressSupervisorImportFile: "inprog-supervisorimport",
    ExportInProgressStudentCertificationImportFile: "inprog-stucertimport",
    ExportSuccessStudentCertificationImportFile: "success-stucertimport",
    ExportErrorStudentCertificationImportFile: "error-stucertimport",
    ExportErrorJobTitleImportFile: "error-jobtitleimport-",

    ExportInProgressStudentBadgeImportFile: "inprog-stubadgeimport",
    ExportErrorStudentBadgeImportFile: "error-stubadgeimport",
    ExportSuccessStudentBadgeImportFile: "success-stubadgeimport",

    ExportErrorStudEduHistoryImportFile: "error-stueduhisimport",
    ExportSuccessStuEduHistoryImportFile: "success-stueduhisimport",
    ExportInProgressStuEduHistoryImportFile: "inprog-stueduhisimport",

    ExportSuccessJobTitleImportFile: "success-jobtitleimport",
    ExportErrorJobTitleImportFile: "error-jobtitleimport",
    ExportInProgressJobTitleImportFile: "inprog-jobtitleimport",

    ExportSuccessSupplementalManagerImportFile: "success-suppmgrimport",
    ExportErrorSupplementalManagerImportFile: "error-suppmgrimport",
    ExportInProgressSupplementalManagerImportFile: "inprog-suppmgrimport",

    ExportSuccessStudPortfolioImportFile : "success-stuporteduimport",
    ExportErrorStudPortfolioImportFile : "error-stuporteduimport",
    ExportInProgressStudPortfolioImportFile : "inprog-stuporteduimport",

    ExportResultsSummary: "Summary",
    ExportResultsDetails: "Details",

    DateRangeDisplayFormat: "MM/DD/YYYY",
    DataTableMessageInfo: "Only showing top 100 records. Please export to view all records.",

    ImportStatusSuccess: "Success",
    ImportStatusError: "Error",
    EmptyString: '""',
    NewLineString: "\n",
    MultiLineString: "\n\n",
    Bom: "\uFEFF",
    ExcelFileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    CsvFileType: 'text/csv;charset=utf-8',
    ImportActionHeader: "IMPORT ACTION SUMMARY",
    ImportErrorHeader: "IMPORT ERROR DETAIL",
    LogOutMessage: "You have been logged out due to inactivity.",

    StatusFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Status', value: 'Status' },
        { label: 'Records', value: 'Records' }
    ],

    StudentImportActionFields: [
        { label: 'Update Day', value: 'UpdatedDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Students Added', value: 'StudentsAdded' },
        { label: 'Students Updated', value: 'StudentsUpdated' },
        { label: 'Users Removed', value: 'UsersRemoved' },
        { label: 'Departments Added', value: 'DepartmentsAdded' },
        { label: 'Job Titles Added', value: 'JobTitlesAdded' },
        { label: 'Custom Values Added', value: 'CustomFieldValues' }
    ],

    StudentImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student', value: 'UserDisplayName' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    StudentJobFunctionActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Inserts', value: 'Inserts' },
        { label: 'Updates', value: 'Updates' },
        { label: 'Deletes', value: 'Deletes' },
    ],

    StudentJobFunctionErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Job Function', value: 'JobFunctionName' },
        { label: 'Error Message', value: 'ErrorMessage' },
    ],

    StudentLicenseImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Licenses Added', value: 'LicensesAdded' },
        { label: 'Licenses Updated', value: 'LicensesUpdated' },
        { label: 'Licenses Removed', value: 'LicensesRemoved' },
    ],

    StudentLicenseImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student', value: 'UserDisplayName' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    RoleImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Roles Added', value: 'RolesAdded' },
        { label: 'Roles Updated', value: 'RolesUpdated' },
        { label: 'Roles Deleted', value: 'RolesDeleted' },
    ],

    RoleImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Role', value: 'RoleName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    SupervisorImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Supervisors Added', value: 'SupervisorsAdded' },
        { label: 'Supervisors Updated', value: 'SupervisorsUpdated' },
        { label: 'Supervisors Deleted', value: 'SupervisorsDeleted' },
    ],

    StudentCertificationImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Certifications Added', value: 'CertificationsAdded' },
        { label: 'Certifications Updated', value: 'CertificationsUpdated' },
        { label: 'Certifications Deleted', value: 'CertificationsDeleted' },
    ],

    StudentBadgeImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Badges Added', value: 'BadgesAdded' },
        { label: 'Badges Updated', value: 'BadgesUpdated' },
        { label: 'Badges Removed', value: 'BadgesDeleted' },
    ],

    StudEduHistoryImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Records Added', value: 'RecordsAdded' },
        { label: 'Records Updated', value: 'RecordsUpdated' },
        { label: 'Records Removed', value: 'RecordsRemoved' },
    ],

    JobTitleImportActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Job Title Added', value: 'Inserts' },
        { label: 'Job Title Updated', value: 'Updates' },
        { label: 'Job Title Removed', value: 'Deletes' },
    ],

    SupplementalManagerActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Status', value: 'Status' },
        { label: 'Records', value: 'Records' }
    ], 

    StudPortfolioEduActionFields: [
        { label: 'Update Day', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Educations Added', value: 'Inserts' },
        { label: 'Educations Updated', value: 'Updates' },
        { label: 'Educations Deleted', value: 'Deletes' }
    ], 

    SupervisorImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Supervisor ID', value: 'SupervisorUserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    StudentCertificationImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    StudentBadgeImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    StuEduHistoryImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Course', value: 'Course' },
        { label: 'Completion Date', value: 'CompletionDate' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    JobTitleImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Job Title', value: 'JobTitle' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],
    
    SupplementalManagerImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],

    StudPortfolioEduImportErrorFields: [
        { label: 'Date', value: 'UpdateDay' },
        { label: 'Institution', value: 'Institution' },
        { label: 'Student ID', value: 'UserName' },
        { label: 'Error Message', value: 'ErrorMessage' }
    ],
    

    ActionNotRequiredReportTypeIds: {
        1: "10",
        },

    ImportStatusInProgress: "In Progress",

    PrintNoMatchingRecords: "<hr><table><tfoot><tr><td class='printNoRecords'></td><td> No matching records found </td></tr></tfoot></table> <hr>",
    PrintNoRecordsAvailable: "<hr><table><tfoot><tr><td class='printNoRecords'></td><td> No data available in table </td></tr></tfoot></table> <hr>", PrintHrTagAppend: "<div style='border:1px solid black;height:1px'><p></p></div>",
    PrintCopyRights: " Healthstream,inc. All rights Reserved.",
    PrintHrTagActionRecord: "<table><tfoot><tr><td> No matching records found </td><td></td><td></td><td style='width:13.2%'></td></tr></tfoot></table> <hr>",
    PrintTotalSum: "<table class='table table-striped dataTable no-footer'><tfoot><tr><td style='font-weight: bold'>Total:</td><td></td><td></td><td style='width:70px;font-weight: bold'>",
    PrintfooterTagAppend: "</td></tr></tfoot></table> <div style='border:1px solid black;height:1px'><p></p></div><div style='height:25px'></div>",
    PrintImageLogo: "<div><img id='imgPr' style='border-top:#ffffff;position:absolute;width:15%;background-size:90%, auto, 150px!important;float:right !important;margin-left:84%!important;margin-top:0%' src=",
    PrintInstitutionStyle: "><div class='text-center report-header'><div class='h4'>",
    PrintDateRange: "</div><div class='info-header-text-block'><strong>Date Range:</strong> <span id='lblDateRange'>",
    PrintReportGen: "</span></div><div class='info-header-text-block'><strong>Report Generated:</strong> <span id='lblReportGenerated'>",
}