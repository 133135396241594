import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { uiConstants } from '../../Constants/UI.Constants';
import ScheduleManageDetails from '../Common/ScheduleManageDetails';
import { scheduleTaskAction } from '../../Actions/ScheduledTask.Action';
import $ from 'jquery';

class ScheduleManage extends Component {
    constructor(props) {
        super(props);
        this.reportType = uiConstants.ReportTypes[this.props.dashboard.reportType - 1].label + " Results";
    }

    componentDidMount() {
        console.log(React.version);
        this.props.dispatch(scheduleTaskAction.getScheduledTasksReportDetails(this.props.dashboard.reportType, this.reportType));
    }

    renderReportDetails() {
        let scheduleData = [];
        if (this.props.dashboard.scheduleData !== undefined) {
            scheduleData = this.props.dashboard.scheduleData ? this.props.dashboard.scheduleData : [];
        }
        return (scheduleData.length > 0 && <ScheduleManageDetails reportTypeId={this.props.dashboard.reportType} scheduleData={scheduleData} closeData={this.props.onHide} />)
    }

    render() {
        var institutionDetails = this.props.login;
        if (this.props.dashboard.loading === false && this.props.dashboard.successMessage !== undefined) {
            $(function () {
                $('#successfulSaveMessageId').fadeIn();
                setTimeout(function () {
                    $('#successfulSaveMessageId').hide();
                }, 5000);
            });
        }
        return (
            <Modal
                size="lg"
                centered
                dialogClassName="custom-modal modal-xl"

                {...this.props}
            >
                <Modal.Body style={{ 'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto', 'overflowX': 'hidden' }} >
                    <Button variant={"light"} className="close" onClick={this.props.onHide}>
                        <i className="icon-close"></i>
                    </Button>
                    <div id="manageScheduleHeader" className="text-center report-header">

                        <div className="h3">{institutionDetails.orgNodeData.OrgNodeName}</div>
                        <div className="h2">Scheduled {this.reportType} Report</div>
                        {this.props.dashboard.loading === false && this.props.dashboard.successMessage !== undefined &&
                            <div className="saveMessage" id="successfulSaveMessageId">{this.props.dashboard.successMessage}</div>}
                        <div className="additional-data"></div>
                    </div>
                    {this.renderReportDetails()}
                </Modal.Body>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { dashboard, login } = state;
    return {
        dashboard,
        login
    };
}

export default connect(mapStateToProps)(ScheduleManage);