import { uiConstants } from '../Constants/UI.Constants';
import { importConstants } from '../Constants/Import.Constants';

export function ImportSummaryReducer(state = {}, action) {
    switch (action.type) {
        case importConstants.GET_ALL_IMPORT_REQUEST:
            return {
                ...state,
                loading: true,
                dateRangeFilter: uiConstants.DateRangeFilters[0],
                appliedDateRange: uiConstants.DateRangeFilters[0].label,
                selectedDateRange: 0,
                showReportModal: false,
                showDatePickerModal: false,
                disableApplyCustomDates: true,
                customFromDate: state.customFromDate !== "" ? state.customFromDate : "",
                customToDate: state.customToDate !== "" ? state.customToDate : "",
                error: false,
                showScheduleCreateModal: false,
                showScheduleManageModal: false,
            };
        case importConstants.GET_ALL_IMPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                importWidgetData: action.data,
                error: false,
            };
        case importConstants.GET_ALL_IMPORT_ERROR:
            return {
                ...state,
                loading: false,
                importWidgetData: {},
                error: true,
            };
        case importConstants.GET_IMPORT_REQUEST:
            return {
                ...state,
                importWidgetData: state.importWidgetData.map((widget) => (widget.ReportTypeId === action.id ? { ...widget, loading: true } : widget)),
                error: false,
                isRefereshed:false
            };
        case importConstants.GET_IMPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                importWidgetData: state.importWidgetData.map((widget) => (widget.ReportTypeId === action.id ? action.data : widget)),
                error: false,
                reportType: action.id,
                isRefereshed:true
            };
        case importConstants.GET_IMPORT_ERROR:
            return {
                ...state,
                importWidgetData: state.importWidgetData.map((widget) => (widget.ReportTypeId === action.id ? { ...widget, loading: false, hasError: true } : widget)),
                isRefereshed:true
            };
        case importConstants.GET_IMPORT_SUMMARY_DOWNLOAD_REQUEST:
            return {
                ...state,
                importWidgetData: state.importWidgetData.map((widget) => (widget.ReportTypeId === action.id ? { ...widget, loading: true } : widget)),
                error: false,
            };
        case importConstants.GET_IMPORT_SUMMARY_DOWNLOAD_SUCCESS:
            return {
                ...state,
                importWidgetData: state.importWidgetData.map((widget) => (widget.ReportTypeId === action.reportsData.reportTypeId ? { ...widget, loading: false } : widget)),
                downloadData: action.reportsData,
                error: false,
            };
        case importConstants.GET_IMPORT_SUMMARY_DOWNLOAD_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case importConstants.SET_DATE_RANGE_FILTER:
            return {
                ...state,
                dateRangeFilter: uiConstants.DateRangeFilters[action.index],
                appliedDateRange: action.text ? action.text : uiConstants.DateRangeFilters[action.index].label,
                selectedDateRange: action.index,
                showChangeCustomDatesBtn: action.showChangeBtn,
                showReportModal: false,
                error: false,
            };
        case importConstants.TOGGLE_REPORT_MODAL:
            return {
                ...state,
                showReportModal: !state.showReportModal,
                importStatus: action.importStatus,
                isExport: false,
                error: false,
            };
        case importConstants.TOGGLE_DATE_PICKER_MODAL:
            return {
                ...state,
                showDatePickerModal: !state.showDatePickerModal,
                showChangeCustomDatesBtn: state.selectedDateRange === uiConstants.DateRangeFilters.length - 1,
                isFromDateInvalid: false,
                isToDateInvalid: false,
                isStartAfterEnd: false,
                isInvalidRange: false,
                disableApplyCustomDates: true,
                error: false,
            };
        case importConstants.SHOW_REPORT_MODAL:
            return {
                ...state,
                showReportModal: !state.showReportModal,
                isExport: false,
                reportType: action.reportType,
                importStatus: action.importStatus,
                error: false,
            };
        case importConstants.VALIDATE_CUSTOM_DATES:
            return {
                ...state,
                isFromDateInvalid: action.validation.isFromDateInvalid,
                isToDateInvalid: action.validation.isToDateInvalid,
                isStartAfterEnd: action.validation.isStartAfterEnd,
                isInvalidRange: action.validation.isInvalidRange,
                disableApplyCustomDates: action.validation.disableApplyCustomDates,
                error: false,
            };
        case importConstants.SET_CUSTOM_DATES:
            return {
                ...state,
                customFromDate: action.dates.fromDate,
                customToDate: action.dates.toDate,
                error: false,
            };
        case importConstants.TOGGLE_SCHEDULE_CREATE_MODAL:
            return {
                ...state,
                showReportModal: false,
                showDatePickerModal: false,
                isExport: false,
                reportType: action.reportType,
                scheduleId: action.scheduleId,
                importStatus: action.importStatus,
                error: false,
                showScheduleCreateModal: !state.showScheduleCreateModal,
                showScheduleManageModal:false,
                successfulMessage: '',
                unSuccessfulMessage: '',
                showScheduleManageModal: false,
                scheduleDataUpdate:undefined
            };
        case importConstants.TOGGLE_SCHEDULE_MANAGE_MODAL:
            return {
                ...state,
                showReportModal: false,
                showDatePickerModal: false,
                isExport: false,
                reportType: action.reportType,
                importStatus: action.importStatus,
                error: false,
                showScheduleManageModal: !state.showScheduleManageModal,
                showScheduleCreateModal: false,
                scheduleData : action.data,
                successMessage: action.successMessage,
                unSuccessfulMessage: '',
                
            };
        case importConstants.GET_REQUEST:
            return {
                ...state,
            }
        case importConstants.SCHEDULE_ID:
            return {
                ...state,
                successfulMessage: action.successfulMessage,
            };
        case importConstants.ERROR:
            return {
                ...state,
                unSuccessfulMessage: action.unSuccessfulMessage,
            };

            case importConstants.GET_SCHEDULED_TASKS_REQUEST:
                return {
                    ...state,
                    loading: true,                    
                    error: false,
            }

            case importConstants.GET_SCHEDULED_TASKS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    scheduleData: action.reportsData.data,//.map((widget, index) => (index === action.id ? action.data : widget)),
                    error: false,
            }

            case importConstants.GET_SCHEDULED_TASK_REQUEST:
                return {
                    ...state,
                    loading: true,                    
                    error: false,
            }

            case importConstants.GET_SCHEDULED_TASK_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    scheduleDataUpdate: action.reportsData.data,//.map((widget, index) => (index === action.id ? action.data : widget)),
                    error: false,
            }

            case importConstants.DELETE_SCHEDULED_TASKS_REQUEST:
                return {
                    ...state,
                    loading: true,                    
                    error: false,
            }

            case importConstants.DELETE_SCHEDULED_TASKS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    response: action.reportsData.data,//.map((widget, index) => (index === action.id ? action.data : widget)),
                    error: false,
                    successMessage: '',
                    unSuccessfulMessage: '',
            }

            case importConstants.GET_SCHEDULED_TASKS_UPDATE_SUCCESS:
                return {
                ...state,
                scheduleDataUpdate: action.reportsData.data,
                error: false,
                loading: true,
            }

            case importConstants.GET_SCHEDULED_TASKS_UPDATE_REQUEST:
                return {
                    ...state,
                }
            case importConstants.GET_SCHEDULED_TASKS_UPDATE_ERROR:
                return {
                    ...state,
                }


        default:
            return state
    }
}