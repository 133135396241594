import { uiConstants } from '../Constants/UI.Constants';

function getReportHeaders(reportType, category) {
    switch (reportType) {
        case 1:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[0].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[1].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[2].label;
                }
            }
        case 2:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[3].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[4].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[5].label;
                }
            }
        
        case 3:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[6].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[7].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[8].label;
                }
            }
        case 4:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[9].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[10].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[11].label;
                }
            }
        case 5:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[12].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[13].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[14].label;
                }
            }
        case 6:
            {               
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[15].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[16].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[17].label;
                }
            }
        case 7:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[18].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[19].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[20].label;
                }
            }
        case 8:
            {
                if (category === uiConstants.ImportStatus.Success) {
                    return uiConstants.ReportTypeHeaders[21].label;
                }
                else if (category === uiConstants.ImportStatus.Error) {
                    return uiConstants.ReportTypeHeaders[22].label;
                }
                else {
                    return uiConstants.ReportTypeHeaders[23].label;
                }
           
            }
            case 9:
                {
                    if (category === uiConstants.ImportStatus.Success) {
                        return uiConstants.ReportTypeHeaders[24].label;
                    }
                    else if (category === uiConstants.ImportStatus.Error) {
                        return uiConstants.ReportTypeHeaders[25].label;
                    }
                    else {
                        return uiConstants.ReportTypeHeaders[26].label;
                    }
               
                }
                case 10:
                    {
                        if (category === uiConstants.ImportStatus.Success) {
                            return uiConstants.ReportTypeHeaders[27].label;
                        }
                        else if (category === uiConstants.ImportStatus.Error) {
                            return uiConstants.ReportTypeHeaders[28].label;
                        }
                        else {
                            return uiConstants.ReportTypeHeaders[29].label;
                        }
                    }
                case 11:
                        {
                            if (category === uiConstants.ImportStatus.Success) {
                                return uiConstants.ReportTypeHeaders[30].label;
                            }
                            else if (category === uiConstants.ImportStatus.Error) {
                                return uiConstants.ReportTypeHeaders[31].label;
                            }
                            else {
                                return uiConstants.ReportTypeHeaders[32].label;
                            }
                        }    
        default:
            return;
}
}

function getResultSectionTableHeaders(reportType) {
    switch (reportType) {
        case 1:
            return uiConstants.StudentImportActionDetailHeaders;
        case 2:
            return uiConstants.StudentJobFunctionActionDetailHeaders;
        case 3: 
             return uiConstants.StudentLicenseActionDetailHeaders;
        case 4:
            return uiConstants.RoleImportActionDetailHeaders;
        case 5:
            return uiConstants.SupervisorImportActionDetailHeaders;
        case 6:
            return uiConstants.StudentCertificationActionDetailHeaders;
        case 7:
            return uiConstants.StudentBadgeActionDetailHeaders;
        case 8:
            return uiConstants.StudentEducationHistoryActionDetailHeaders;
        case 9:
            return uiConstants.JobTitleImportActionDetailHeaders;
        case 11:
            return uiConstants.StudPortfolioEduImportActionDetailHeaders;
        default:
            return;
    }
}

function getResultSectionTableErrorHeaders(reportType) {
    switch (reportType) {
        case 1:
            return uiConstants.StudentImportErrorDetailHeaders;
        case 2:
            return uiConstants.StudentJobFunctionErrorDetailHeaders;
        case 3:
            return uiConstants.StudentLicenseErrorDetailHeaders;
        case 4:
            return uiConstants.RoleImportErrorDetailHeaders;
        case 5:
            return uiConstants.SupervisorImportErrorDetailHeaders;
        case 6:
            return uiConstants.StudentCertificationImportErrorDetailHeaders;
        case 7:
            return uiConstants.StudentBadgeImportErrorDetailHeaders;
        case 8:
            return uiConstants.StudEduHistoryImportErrorDetailHeaders;
        case 9:
            return uiConstants.JobTitleImportErrorDetailHeaders;   
        case 10:
            return uiConstants.SupplementalManagerErrorDetailHeaders;    
        case 11:
            return uiConstants.StudPortfolioEduImportErrorDetailHeaders;       
        default: 
            return;
    }
}


export { getReportHeaders, getResultSectionTableHeaders, getResultSectionTableErrorHeaders }
