import axios from 'axios';
import { config } from '../../config';

export const studentBadgeImportService = {
    getStudentBadgeImportErrorData,
    getStudentBadgeImportSuccessData,
    getStudentBadgeImportInProgressData,
    getStudentBadgeImportDownloadData
}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

//service call to fetch Download details.
function getStudentBadgeImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentbadgeimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}

// student badge import inprogress service call
function getStudentBadgeImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentbadgeimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

//service call to fetch Badge Import Error Details
function getStudentBadgeImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentbadgeimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Badge Import Success Details
function getStudentBadgeImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentbadgeimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}