import { loginService } from "../Services/Dashboard/Login.Service";
import { tokenService } from "../Services/Dashboard/Token.Service";
import { loginConstants, userLogoConstants,footerDetailsConstants } from '../Constants/Login.Constants';

export const loginActions = {
    setHeaders,
    getOrgNodeDetails,
    getUserLogoDetails,
    getFooterDetails
}

function setHeaders() {
    return loginService.storeRequestHeaders();
}

function getOrgNodeDetails() {
    return dispatch => {
        dispatch(request());
        tokenService.getAuthToken()
        .then(
            response => {
                loginService.getOrgNodeDetails(response.data)
                    .then(
                        response => {
                            if (response.status === 200) {
                                loginService.storeOrgNodedetails(response.data);
                                dispatch(success(response.data));
                            }
                            else {
                                dispatch(failure());
                            }
                        }
                    ).catch(error => {
                        dispatch(failure());
                    });
           });
    };

    function request() { return { type: loginConstants.GET_ORG_NODE_DETAILS_REQUEST} }
    function success(data) { return { type: loginConstants.GET_ORG_NODE_DETAILS_SUCCESS, data } }
    function failure() { return { type: loginConstants.GET_ORG_NODE_DETAILS_ERROR } }
}

function getUserLogoDetails() {
    return dispatch => {
        dispatch(request());
        tokenService.getAuthToken()
        .then(
            response => {
                loginService.getUserLogoDetails(response.data)
            .then(
                response => {
                    if (response.status === 200) {
                        dispatch(success(response.data));
                    }
                    else {
                        dispatch(failure());
                    }
                }
            ).catch(error => {
                dispatch(failure());
            });
        });
    };

    function request() { return { type: userLogoConstants.GET_ADMIN_LOGO_REQUEST} }
    function success(data) { return { type: userLogoConstants.GET_ADMIN_LOGO_SUCCESS, data } }
    function failure() { return { type: userLogoConstants.GET_ADMIN_LOGO_ERROR } }
}	

function getFooterDetails() {
    return dispatch => {
        dispatch(request());
        tokenService.getAuthToken()
        .then(
            response => {
                loginService.getFooterDetails(response.data)
            .then(
                response => {
                    if (response.status === 200) {
                        dispatch(success(response.data));
                    }
                    else {
                        dispatch(failure());
                    }
                }
            ).catch(error => {
                dispatch(failure());
            });
        });
    };

    function request() { return { type: footerDetailsConstants.GET_FOOTER_REQUEST} }
    function success(data) { return { type: footerDetailsConstants.GET_FOOTER_SUCCESS, data } }
    function failure() { return { type: footerDetailsConstants.GET_FOOTER_ERROR } }
}	