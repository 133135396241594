import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { ImportSummaryReducer } from './Reducers/ImportSummary.Reducer';
import { ImportReducer } from './Reducers/Import.Reducer';
import { LoginReducer } from './Reducers/Login.Reducer';

const loggerMiddleware = createLogger();

const reducer = combineReducers({
    dashboard: ImportSummaryReducer,
    importReport: ImportReducer,
    login:LoginReducer
});

const reportStore = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware, loggerMiddleware
        )
    )
);

export default reportStore;