import axios from 'axios';
import { config } from '../../config';

export const studEduHistoryImportService = {
    getStudEduHistoryImportErrorData,
    getStudEduHistoryImportSuccessData,
    getStudEduHistoryImportDownloadData,
    getStudEduHistoryImportInProgressData
}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

//service call to fetch Education History Import Error Details
function getStudEduHistoryImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studeduhistoryimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Education History Import Inprogress Details
function getStudEduHistoryImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studeduhistoryimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Education History Import Success Details
function getStudEduHistoryImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studeduhistoryimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Education History Import download Details
function getStudEduHistoryImportDownloadData(fromDate, toDate, token) {
   let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studeduhistoryimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues
    });
}
