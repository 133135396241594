/*
* Constants used in for getting the org node details 
*/

export const loginConstants = {
    GET_ORG_NODE_DETAILS_REQUEST: 'GET_ORG_NODE_DETAILS_REQUEST',
    GET_ORG_NODE_DETAILS_SUCCESS: 'GET_ORG_NODE_DETAILS_SUCCESS',
    GET_ORG_NODE_DETAILS_ERROR: 'GET_ORG_NODE_DETAILS_ERROR'
}

export const userLogoConstants = {
    GET_ADMIN_LOGO_REQUEST: 'GET_ADMIN_LOGO_REQUEST',
    GET_ADMIN_LOGO_SUCCESS: 'GET_ADMIN_LOGO_SUCCESS',
    GET_ADMIN_LOGO_ERROR: 'GET_ADMIN_LOGO_ERROR'
}

export const footerDetailsConstants = {
    GET_FOOTER_REQUEST: 'GET_FOOTER_REQUEST',
    GET_FOOTER_SUCCESS: 'GET_FOOTER_SUCCESS',
    GET_FOOTER_ERROR: 'GET_FOOTER_ERROR',
    GET_BUILD_REACT: 'Build R:'  
}