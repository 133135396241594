import axios from 'axios';
import { config } from '../../config';

export const tokenService = {
    getAuthToken
};

function getAuthToken(){
    axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.defaults.headers.get['Pragma'] = 'no-cache';
    
    var stsUrl = config.stsService + "/" + config.stsTokenPath;
    const params = new URLSearchParams();
    params.append('scope', config.stsScope);
    params.append('grant_type', config.stsGrantType);

    return axios.post(stsUrl, params , {
        headers: {'Authorization': 'Basic ' + config.apikey}}
    );
}
