import axios from 'axios';
import { config } from '../../config';

export const roleImportService = {
    getRoleImportInProgressData,
    getRoleImportSuccessData,
    getRoleImportDownloadData,
    getRoleImportErrorData
}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

function getRoleImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/roleimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });

}
function getRoleImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/roleimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

function getRoleImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/roleimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}

//RoleImport error service call
function getRoleImportErrorData(fromDate, toDate, isExport, token)
{
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/roleimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}