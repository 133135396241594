import { importConstants } from '../Constants/Import.Constants';
import { calculateDateFromIndex, formatAndStoreCustomDates } from '../Utils/DateFormatter';
import { importSummaryService } from '../Services/Dashboard/ImportSummary.Service';
import { tokenService } from "../Services/Dashboard/Token.Service";
import { loginService } from '../Services/Dashboard/Login.Service';
import { studentImportService } from '../Services/ImportReports/StudentImport.Service';
import { studentJobFunctionService } from '../Services/ImportReports/StudentJobFunction.Service';
import { studentLicenseImportService } from '../Services/ImportReports/StudentLicenseImport.Service';
import { roleImportService } from '../Services/ImportReports/RoleImport.Service';
import { uiConstants } from '../Constants/UI.Constants';
import { supervisorImportService } from '../Services/ImportReports/SupervisorImport.Service';
import { studentCertificationImportService } from '../Services/ImportReports/StudentCertificationImport.Service';
import { studentBadgeImportService } from '../Services/ImportReports/StudentBadgeImport.Service';
import { studEduHistoryImportService } from '../Services/ImportReports/StudEduHistoryImport.Service';
import { jobTitleImportService } from '../Services/ImportReports/JobTitleImport.Service';
import { supplementalManagerImportService } from '../Services/ImportReports/SupplementalManagerImport.Service';
import {studPortfolioEduImportService } from '../Services/ImportReports/StudPortfolioEduImport.Service'

export const importSummaryActions = {
    getImportWidgets,
    getReportWidgetById,
    getImportSummaryDownloadDetails,
    setDateRangeFilter,
    toggleReportModal,
    toggleDatePickerModal,
    validateCustomDateRange,
    setCustomDates,
    showReportModal,
    toggleScheduleCreateModal,
    toggleScheduleManageModal
}

function getImportWidgets(selectedDateRange = 0) {
    return dispatch => {

        dispatch(request());
        var dates = calculateDateFromIndex(selectedDateRange);

        tokenService.getAuthToken()
            .then(
                response => {
                    importSummaryService.getReportWidgetsData(dates.fromDate, dates.toDate, response.data)
                        .then(
                            response => {
                                if (response.status === 200) {
                                    dispatch(success(response.data));
                                }
                                else {
                                    dispatch(failure());
                                }
                            }
                        ).catch(error => {
                            dispatch(failure());
                        });
                }
            );
    };

    function request() { return { type: importConstants.GET_ALL_IMPORT_REQUEST } }
    function success(data) { return { type: importConstants.GET_ALL_IMPORT_SUCCESS, data } }
    function failure() { return { type: importConstants.GET_ALL_IMPORT_ERROR } }
}

function getReportWidgetById(selectedDateRange, widgetId) {
    return dispatch => {

        dispatch(request(widgetId));
        var dates = calculateDateFromIndex(selectedDateRange);

        tokenService.getAuthToken()
            .then(
                response => {
                    importSummaryService.getReportWidgetDataById(dates.fromDate, dates.toDate, widgetId, response.data)
                        .then(
                            response => {
                                if (response.status === 200) {
                                    dispatch(success(response.data, widgetId));
                                }
                                else {
                                    dispatch(failure(widgetId));
                                }
                            }
                        ).catch(error => {
                            dispatch(failure(widgetId));
                        });
                }
            );
    };

    function request(id) { return { type: importConstants.GET_IMPORT_REQUEST, id } }
    function success(data, id) { return { type: importConstants.GET_IMPORT_SUCCESS, data, id } }
    function failure(id) { return { type: importConstants.GET_IMPORT_ERROR, id } }
}

//Gets full summary of Reports for download.
function getImportSummaryDownloadDetails(selectedDates, reportTypeId, downloadSummaryReport) {
    return dispatch => {

        dispatch(request(reportTypeId));

        let dataObject;
        tokenService.getAuthToken()
            .then(
                response => {
                    getDownloadServiceData(selectedDates.fromDate, selectedDates.toDate, reportTypeId, response.data)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data !== undefined) {
                                    dataObject = {
                                        reportTypeId: reportTypeId,
                                        //To Do: To be changed as part of download card - need to determine if this property is even needed here.
                                        importStatus: true,
                                        data: res.data,
                                    }
                                }
                                loginService.storeTimedetails(res.data);
                                dispatch(success(dataObject));
                                downloadSummaryReport();
                            }
                            else {
                                dispatch(failure('API request failed'));
                            }
                        }).catch(error => {
                            dispatch(failure());
                        });
                }
            );
    };

    function request(id) { return { type: importConstants.GET_IMPORT_SUMMARY_DOWNLOAD_REQUEST, id } }
    function success(reportsData) { return { type: importConstants.GET_IMPORT_SUMMARY_DOWNLOAD_SUCCESS, reportsData } }
    function failure() { return { type: importConstants.GET_IMPORT_SUMMARY_DOWNLOAD_ERROR } }
}

function getDownloadServiceData(fromDate, toDate, reportType, token) {
    switch (reportType) {
        case 1:
            return studentImportService.getStudentImportDownloadData(fromDate, toDate, token);
        case 2:
            return studentJobFunctionService.getStudentJobFunctionDownloadData(fromDate, toDate, token);
        case 3:
            return studentLicenseImportService.getStudentLicenseImportDownloadData(fromDate, toDate, token);
        case 4:
            return roleImportService.getRoleImportDownloadData(fromDate, toDate, token);
        case 5:
            return supervisorImportService.getSupervisorImportDownloadData(fromDate, toDate, token);
        case 6:
            return studentCertificationImportService.getStudentCertificationImportDownloadData(fromDate, toDate, token);
        case 7:
            return studentBadgeImportService.getStudentBadgeImportDownloadData(fromDate, toDate, token);
        case 8:
            return studEduHistoryImportService.getStudEduHistoryImportDownloadData(fromDate, toDate, token);
        case 9:
            return jobTitleImportService.getJobTitleImportDownloadData(fromDate, toDate, token);
        case 10:
            return supplementalManagerImportService.getSupplementalManagerImportDownloadData(fromDate, toDate, token);  
        case 11:
                return studPortfolioEduImportService.getStudPortfolioEduImportDownloadData(fromDate, toDate, token);    
        default:
            return;
    }
}

function setDateRangeFilter(selectedDateRangeIndex, fromDate = "", toDate = "", isSubmit = false) {
    return dispatch => {

        //Handle custom date range selection
        if (selectedDateRangeIndex === uiConstants.DateRangeFilters.length - 1) {
            dispatch(toggleDatePickerModal());

            if (isSubmit) {
                dispatch(request());
                dispatch(setDateRangeFilter(selectedDateRangeIndex, fromDate + " to " + toDate, true));
                formatAndStoreCustomDates(fromDate, toDate);
                let dates = calculateDateFromIndex(selectedDateRangeIndex);

                tokenService.getAuthToken()
                    .then(
                        response => {
                            importSummaryService.getReportWidgetsData(dates.fromDate, dates.toDate, response.data)
                                .then(
                                    response => {
                                        if (response.status === 200) {
                                            dispatch(success(response.data));
                                        }
                                        else {
                                            dispatch(failure());
                                        }
                                    }
                                ).catch(error => {
                                    dispatch(failure());
                                });
                        }
                    );
            }
        }
        //Handle normal flow of the date range picker
        else {
            dispatch(request());
            dispatch(setDateRangeFilter(selectedDateRangeIndex, null, false));
            let customDates = {
                fromDate: "",
                toDate: ""
            };
            dispatch(setCustomDates(customDates));
            let dates = calculateDateFromIndex(selectedDateRangeIndex);

            tokenService.getAuthToken()
                .then(
                    response => {
                        importSummaryService.getReportWidgetsData(dates.fromDate, dates.toDate, response.data)
                            .then(
                                response => {
                                    if (response.status === 200) {
                                        dispatch(success(response.data));
                                    }
                                    else {
                                        dispatch(failure());
                                    }
                                }
                            ).catch(error => {
                                dispatch(failure());
                            });
                    }
                );
        }
    };

    function request() { return { type: importConstants.GET_ALL_IMPORT_REQUEST } }
    function success(data) { return { type: importConstants.GET_ALL_IMPORT_SUCCESS, data } }
    function failure() { return { type: importConstants.GET_ALL_IMPORT_ERROR } }
    function setDateRangeFilter(selectedDateRange, appliedDateRange, showChangeBtn) { return { type: importConstants.SET_DATE_RANGE_FILTER, index: selectedDateRange, text: appliedDateRange, showChangeBtn: showChangeBtn } }
    function toggleDatePickerModal() { return { type: importConstants.TOGGLE_DATE_PICKER_MODAL } }
    function setCustomDates(dates) { return { type: importConstants.SET_CUSTOM_DATES, dates } }
}

function validateCustomDateRange(isFromDateInvalid = false, isToDateInvalid = false, isStartAfterEnd = false, isInvalidRange = false, disableApplyCustomDates) {
    return dispatch => {

        let customDateValidation = {
            isFromDateInvalid: isFromDateInvalid,
            isToDateInvalid: isToDateInvalid,
            isStartAfterEnd: isStartAfterEnd,
            isInvalidRange: isInvalidRange,
            disableApplyCustomDates: disableApplyCustomDates
        };

        dispatch(validateCustomDateRange(customDateValidation));
    };

    function validateCustomDateRange(validation) { return { type: importConstants.VALIDATE_CUSTOM_DATES, validation } }
}

function setCustomDates(fromDate, toDate) {
    return dispatch => {

        let dates = {
            fromDate: fromDate,
            toDate: toDate
        };
        dispatch(setCustomDates(dates));
    };

    function setCustomDates(dates) { return { type: importConstants.SET_CUSTOM_DATES, dates } }
}

function toggleReportModal() {
    return dispatch => {
        dispatch(toggleReportModal());
    };

    function toggleReportModal() { return { type: importConstants.TOGGLE_REPORT_MODAL } }
}

function toggleDatePickerModal() {
    return dispatch => {
        dispatch(toggleDatePickerModal());
    };

    function toggleDatePickerModal() { return { type: importConstants.TOGGLE_DATE_PICKER_MODAL } }
}

function toggleScheduleCreateModal(reportType, selectedDateRange, isClose) {
    return dispatch => {
        dispatch(toggleScheduleCreateModal());
        if(isClose === true){
            dispatch(this.getReportWidgetById(selectedDateRange, reportType));
        }
    };

     function toggleScheduleCreateModal() { 
         return { type: importConstants.TOGGLE_SCHEDULE_CREATE_MODAL ,
         reportType: reportType
     }} 
}

function toggleScheduleManageModal(reportType, successData, selectedDateRange, isClose) {    
    return dispatch => {
        
        dispatch(toggleScheduleManageModal(reportType,successData));
        if(isClose === true){
            dispatch(this.getReportWidgetById(selectedDateRange, reportType));
        }
        
    };
 
    function toggleScheduleManageModal() { return { type: importConstants.TOGGLE_SCHEDULE_MANAGE_MODAL, reportType: reportType, successMessage:successData} }
}

function showReportModal(reportType, importStatus) {
    return dispatch => {
        dispatch(showReportModal(reportType, importStatus));
    };

    function showReportModal(reportType, importStatus) { return { type: importConstants.SHOW_REPORT_MODAL, reportType: reportType, importStatus: importStatus } }
}
