import axios from 'axios';
import { config } from '../../config';

export const loginService = {
    storeRequestHeaders,
    getOrgNodeDetails,
    storeOrgNodedetails,
    storeTimedetails,
    getUserLogoDetails,
    getFooterDetails
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

function createCookie(name, value, days) {
    var expires;
    var domainValue;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
        domainValue = "; domain=" + config.domainValue;
    }
    else expires = "";
    document.cookie = name + "=" + value + domainValue + expires + "; path=/";
}
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function storeRequestHeaders() {
    var isAuthenticated = false;
    var import_management = readCookie("import_management")
    if (import_management !== null && import_management !== undefined && import_management !== "") {
        sessionStorage.setItem('headers', import_management); 
        isAuthenticated = true;
        createCookie("import_management", "", -1);
    }

    if(sessionStorage.length > 0 && sessionStorage.getItem('headers') !== null){
        isAuthenticated = true;
    }

    return isAuthenticated;
}

function getOrgNodeDetails(token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/usersettings/orgnodesettings'), {
        headers: headerValues,       
    });  
}

function getUserLogoDetails(token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/usersettings/userlogo'), {
        headers: headerValues,       
    });  
}

function getFooterDetails(token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/diagnostic/footerdetails'), {
        headers: headerValues,       
    });  
}

function storeOrgNodedetails(data){
    sessionStorage.setItem('login', JSON.stringify({
        "loginDetails":data,
    }));
}

function storeTimedetails(data){
    sessionStorage.removeItem("reportGenTime");
    sessionStorage.setItem("reportGenTime", JSON.stringify({
        "reportGenTime":data.UserTimeZone,
    }));
}

