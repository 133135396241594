/*
* Constants used in application
*/

export const importConstants = {

    GET_ALL_IMPORT_REQUEST: 'GET_ALL_IMPORT_REQUEST',
    GET_ALL_IMPORT_SUCCESS: 'GET_ALL_IMPORT_SUCCESS',
    GET_ALL_IMPORT_ERROR: 'GET_ALL_IMPORT_ERROR',

    GET_IMPORT_REQUEST: 'GET_IMPORT_REQUEST',
    GET_IMPORT_SUCCESS: 'GET_IMPORT_SUCCESS',
    GET_IMPORT_ERROR: 'GET_IMPORT_ERROR',

    GET_IMPORT_REPORT_REQUEST: 'GET_IMPORT_REPORT_REQUEST',
    GET_IMPORT_REPORT_SUCCESS: 'GET_IMPORT_REPORT_SUCCESS',
    GET_IMPORT_REPORT_ERROR: 'GET_IMPORT_REPORT_ERROR',
    GET_IMPORT_REPORT_INPROGRESS: 'GET_IMPORT_REPORT_INPROGRESS',
    GET_IMPORT_REPORT_ERROR_DETAIL: "GET_IMPORT_REPORT_ERROR_DETAIL",

    GET_IMPORT_SUMMARY_DOWNLOAD_REQUEST: 'GET_IMPORT_SUMMARY_DOWNLOAD_REQUEST',
    GET_IMPORT_SUMMARY_DOWNLOAD_SUCCESS: 'GET_IMPORT_SUMMARY_DOWNLOAD_SUCCESS',
    GET_IMPORT_SUMMARY_DOWNLOAD_ERROR: 'GET_IMPORT_SUMMARY_DOWNLOAD_ERROR',

    GET_EXPORT_REPORT_REQUEST: 'GET_EXPORT_REPORT_REQUEST',
    GET_EXPORT_REPORT_SUCCESS: 'GET_EXPORT_REPORT_SUCCESS',
    GET_EXPORT_REPORT_ERROR: 'GET_EXPORT_REPORT_ERROR',
    GET_EXPORT_REPORT_INPROGRESS: 'GET_EXPORT_REPORT_INPROGRESS',
    GET_EXPORT_REPORT_ERROR_DETAIL: "GET_EXPORT_REPORT_ERROR_DETAIL",

    GET_SCHEDULED_TASKS_REQUEST: "GET_SCHEDULED_TASKS_REQUEST",
    GET_SCHEDULED_TASKS_SUCCESS: 'GET_SCHEDULED_TASKS_SUCCESS',
    GET_SCHEDULED_TASKS_ERROR: 'GET_SCHEDULED_TASKS_ERROR',

    GET_SCHEDULED_TASK_REQUEST: "GET_SCHEDULED_TASK_REQUEST",
    GET_SCHEDULED_TASK_SUCCESS: 'GET_SCHEDULED_TASK_SUCCESS',
    GET_SCHEDULED_TASK_ERROR: 'GET_SCHEDULED_TASK_ERROR',

    DELETE_SCHEDULED_TASKS_REQUEST: "DELETE_SCHEDULED_TASKS_REQUEST",
    DELETE_SCHEDULED_TASKS_SUCCESS: 'DELETE_SCHEDULED_TASKS_SUCCESS',
    DELETE_SCHEDULED_TASKS_ERROR: 'DELETE_SCHEDULED_TASKS_ERROR',

    GET_SCHEDULED_TASKS_UPDATE_REQUEST: 'GET_SCHEDULED_TASKS_UPDATE_REQUEST',
    GET_SCHEDULED_TASKS_UPDATE_SUCCESS:'GET_SCHEDULED_TASKS_UPDATE_SUCCESS',
    GET_SCHEDULED_TASKS_UPDATE_ERROR : 'GET_SCHEDULED_TASKS_UPDATE_ERROR',

    SET_DATE_RANGE_FILTER: 'SET_DATE_RANGE_FILTER',
    TOGGLE_REPORT_MODAL: 'TOGGLE_REPORT_MODAL',
    TOGGLE_DATE_PICKER_MODAL: 'TOGGLE_DATE_PICKER_MODAL',
    SHOW_REPORT_MODAL: 'SHOW_REPORT_MODAL',
    VALIDATE_CUSTOM_DATES: 'VALIDATE_CUSTOM_DATES',
    SET_CUSTOM_DATES: 'SET_CUSTOM_DATES',
    TOGGLE_SCHEDULE_CREATE_MODAL: 'TOGGLE_SCHEDULE_CREATE_MODAL',
    TOGGLE_SCHEDULE_MANAGE_MODAL: 'TOGGLE_SCHEDULE_MANAGE_MODAL',
    GET_REQUEST:'GET_REQUEST',
    ERROR:'ERROR',
    SCHEDULE_SUCCESSFUL_MESSAGE:'Report is scheduled.',
    SCHEDULE_UNSUCCESSFUL_MESSAGE:'Report is not scheduled. Please try again.',
    SCHEDULE_ID:'00000000-0000-0000-0000-000000000000',
    REQUEST_NAME_ERROR_MSG:'Request Name cannot be blank.',
    EMAIL_ERROR_MSG:'Email address cannot be blank.',
    SPECIFIC_DAY_MONTH_ERROR_MSG:'Specific day of month is a required field when selecting this type of schedule.',
    SPECIFIC_DAY_QUARTER_ERROR_MSG:'Specific day of quarter is a required field when selecting this type of schedule.',
    MONTH_LIMIT_ERROR_MSG:'Enter number 1 - 31 for Specific day of month text box.',
    QUARTER_LIMIT_ERROR_MSG:'Enter number 1 - 90 for Specific day of quarter text box.'
}

export const headerConstants =
{
STUDENT_IMPORT_REPORT : 'Student Import Results - Created',
STUDENT_JOB_FUNCTION_REPORT : 'Student Job Function Import Results - Created',
STUDENT_LICENSE_IMPORT_REPORT : 'Student License Import Results - Created',
STUDENT_ROLE_IMPORT_REPORT : 'Role Import Results - Created',
STUDENT_SUPERVISOR_IMPORT_REPORT : 'Supervisor Import Results - Created',
STUDENT_CERTIFICATION_IMPORT_REPORT : 'Student Certification Import Results - Created',
STUDENT_BADGE_IMPORT_REPORT : 'Student Badge Import Results - Created',
STUDENT_EDUCATION_HISTORY_IMPORT_REPORT : 'Student Education History Import Results - Created',
JOB_TITLE_IMPORT_REPORT : 'Job Title Import Results - Created',
SUPPLEMENTAL_MANAGER_IMPORT_REPORT : 'Supplemental Manager Import Results - Created',
STUDENT_PORTFOLIO_EDUCATION_IMPORT_REPORT : 'Student Portfolio Education Import Results - Created'
}