import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import DateSelector from '../ReportConfiguration/DateSelector';
import SummaryWidget from '../Reports/ImportSummaryWidgets/SummaryWidget';
import PageLoader from '../Common/PageLoader';
import PageError from '../Common/PageError';
import {importSummaryActions} from '../../Actions/ImportSummary.Action';

class ImportsDashboard extends React.Component {

    componentDidMount() {
        this.props.dispatch(importSummaryActions.getImportWidgets());
    }

    render() {
        const {dashboard} = this.props;
        const widgetData = (dashboard.importWidgetData !== undefined && dashboard.importWidgetData.length > 0) ? dashboard.importWidgetData : [];

        return (
          <div>
              <Row>
                  <Col md={6}>
                      <h2>Import Results
                          <small className="import-results-date-range-label"> - {dashboard.appliedDateRange}</small>
                      </h2>
                  </Col>
                  <Col md={6}>
                      <DateSelector customIndex={dashboard.customDateIndex} />
                  </Col>
              </Row>
              {
                  (dashboard.loading || dashboard.error) &&
                  <Row>
                      {
                          (dashboard.loading === true) && <PageLoader />
                      }
                      {
                          (dashboard.error === true) && <PageError />
                      }
                  </Row>
              }
              {
                  !dashboard.loading &&
                  <Row>
                      {
                          widgetData.map((reportSummary) => {
                              return (
                                <Col lg={12} key={reportSummary.ReportTypeId}>
                                    <SummaryWidget
                                      showModal={dashboard.showReportModal}
                                      reportSummaryData={reportSummary}
                                      reportTypeId={reportSummary.ReportTypeId}
                                      activeDateRange={dashboard.selectedDateRange}
                                      hasError={dashboard.error}
                                    />
                                </Col>
                              )
                          })
                      }
                  </Row>
              }
          </div>
        );
    }
}

function mapStateToProps(state) {
    const {dashboard} = state;
    return {
        dashboard
    };
}

export default connect(mapStateToProps)(ImportsDashboard);