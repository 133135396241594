import axios from 'axios';
import { config } from '../../config';

export const studentImportService = {
    getStudentImportSuccessData,
    getStudentImportErrorData,
    getStudentImportDownloadData,
    getStudentImportInProgressData
};

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

function getStudentImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/studentimport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

function getStudentImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/studentimport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });
}

function getStudentImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;

    return axios.get((config.api + '/studentimport/download?fromDate=' + fromDate + '&toDate=' + toDate), {
        headers: headerValues,
    });
}

function getStudentImportInProgressData(fromDate, toDate, isExport, token){
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/studentimport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues,
    });

}