import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../Assets/Css/Footer.css';
import { loginActions } from '../../Actions/Login.Action';
import { config } from '../../config';
import { footerDetailsConstants } from '../../Constants/Login.Constants';
import footerLogo from '../../Assets/Images/logo-hstream-exclusive.svg';

class Footer extends Component {
    componentDidMount() {
        this.props.dispatch(loginActions.getFooterDetails());
    }

    render() {
        var buildVersion = footerDetailsConstants.GET_BUILD_REACT + config.buildVersion;
        var footerDetails = this.props.login;
        if (footerDetails.isAuthorizedToPageFooter) {
            return (
                <div>
                    <div style={{ padding: '50px' }} />
                    <div id="bid" />
                    <div id="cd">
                        <a id="footerlogo" target="_blank" href="http://www.healthstream.com/">
                            <img alt="Healthstream" src={footerLogo} style={{width:'150px'}}/>
                        </a>
                        <div id="txt">
                            &nbsp;{footerDetails.copyWritesDetails}
                            <br />
                            {footerDetails.buildNumber} &nbsp; {buildVersion}
               &nbsp; {footerDetails.userTimeZone}
                            <br />
                            <br />{footerDetails.currentApiServerName}
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (<div />)
        }
    }
}

function mapStateToProps(state) {
    const { login } = state;
    return {
        login
    };
}
export default connect(mapStateToProps)(Footer);