import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExportDetails from './ExportReportDetails';
import * as ExportResults from './ExportReportResults';
import { uiConstants } from '../Constants/UI.Constants';


function exportReports(reportTypeId, reportData, isExcel, isDownload, importStatus, dateRange) {
    let wsReportDetails;
    let wsSummary;
    let reportFileName;
    let workBook;
    let csvData;
    let data;

    let fileExtension = isExcel ? '.xlsx' : '.csv';
    reportFileName = ExportDetails.getExportReportFileName(reportTypeId, importStatus);

    if (isExcel) {
        wsReportDetails = ExportResults.buildExportExcelReportResultSet(reportData, reportTypeId, importStatus);
        wsSummary = ExportResults.buildExportExcelReportSummary(dateRange, reportTypeId, importStatus);

        var sheetNames = ExportDetails.getExportSheetNames(); 
        workBook = XLSX.utils.book_new();

        workBook.SheetNames.push(sheetNames.summarySheet);
        workBook.Sheets[sheetNames.summarySheet] = wsSummary;
        XLSX.utils.book_append_sheet(workBook, wsReportDetails, sheetNames.resultsSheet);

        const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
        data = new Blob([excelBuffer], { type: uiConstants.ExcelFileType });
    }
    else {
        if (isDownload) {
            reportFileName = ExportDetails.getDownloadReportFileName(reportTypeId);
            csvData = ExportResults.buildDownloadCsvReportResultSet(reportData, reportTypeId, importStatus);
        }
        else {
            csvData = ExportResults.buildExportCsvReportResultSet(reportData, reportTypeId, importStatus);
        }

        data = new Blob([csvData], { type: uiConstants.CsvFileType });
    }

    FileSaver.saveAs(data, reportFileName + fileExtension);
}

export { exportReports } 