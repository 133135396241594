import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { calculateDateFromIndex } from '../../Utils/DateFormatter';
import { getResultSectionTableHeaders, getResultSectionTableErrorHeaders } from '../../Utils/ImportDetailsResult';
import { importActions } from '../../Actions/Import.Action';
import PopupHeader from './PopupHeader';
import PageLoader from './PageLoader';
import ReportDetailsSummary from '../Reports/ImportReports/ReportDetailsSummary';
import PopupFooter from './PopupFooter';
import { uiConstants } from '../../Constants/UI.Constants';

let dates;
let importStatus;

class ReportDetails extends Component {

    componentDidMount() {
        dates = calculateDateFromIndex(this.props.dashboard.selectedDateRange);
        const isExport = this.props.dashboard.isExport;
        const reportTypeId = this.props.dashboard.reportType;
        importStatus = this.props.dashboard.importStatus;
        this.props.dispatch(importActions.getImportReportSummaryDetails(dates, isExport, importStatus, reportTypeId));
    }

    renderReportDetails() {
        let statusSection,
            resultsSection,
            reportType,
            resultSectionTableHeaders,
            resultSectionTableErrorHeaders;
        const { importReport } = this.props;

        //Section headers
        let resultSectionHeader;
        if (importReport.summaryData !== undefined && importReport.summaryData.importStatus === uiConstants.ImportStatus.Success) {
            resultSectionHeader = uiConstants.ActionSectionHeader;
        }
        else if (importReport.summaryData !== undefined && importReport.summaryData.importStatus === uiConstants.ImportStatus.Error) {
            resultSectionHeader = uiConstants.ErrorSectionHeader;
        }

        if (importReport.summaryData !== undefined && importReport.summaryData.data.statusDetailArr !== undefined) {
            //Report Category data
            reportType = importReport.summaryData.reportTypeId;
            var reportCategory = importReport.summaryData.importStatus;
            //status section data 
            statusSection = importReport.summaryData.data.statusDetailArr ? importReport.summaryData.data.statusDetailArr : [];
            //action / error section data 
            resultsSection = importReport.summaryData.data.resultDetailsArr ? importReport.summaryData.data.resultDetailsArr : [];
        }

        //Result-Action section table headers.
        resultSectionTableHeaders = getResultSectionTableHeaders(reportType);

        //Result-Error Section table header.        
        resultSectionTableErrorHeaders = getResultSectionTableErrorHeaders(reportType);

        if (importReport.summaryData !== undefined && importReport.summaryData.importStatus === uiConstants.ImportStatus.Success) {

            //Success popup data
            //Condition to check whether the Success pop up require Action/Error details to show.
            if (Object.values(uiConstants.ActionNotRequiredReportTypeIds).some(val => val === reportType.toString()))
                return ((statusSection.length > 0 || resultsSection.length > 0) &&
                    <ReportDetailsSummary statusData={statusSection} actionData={resultsSection} reportType={reportType} reportCategory={reportCategory} actionHeader={resultSectionTableHeaders} resSecHeader={resultSectionHeader} />)
            else
            return ((statusSection.length > 0 && resultsSection.length > 0) &&
              <ReportDetailsSummary statusData={statusSection} actionData={resultsSection} reportType={reportType} reportCategory={reportCategory} actionHeader={resultSectionTableHeaders} resSecHeader={resultSectionHeader} />)
        }
        else if (importReport.summaryData !== undefined && importReport.summaryData.importStatus === uiConstants.ImportStatus.Error) {

            //Error popup data - Changing this condition to display any of the content is available
            return ((statusSection.length > 0 || resultsSection.length > 0) &&
              <ReportDetailsSummary statusData={statusSection} actionData={resultsSection} reportType={reportType} reportCategory={reportCategory} actionHeader={resultSectionTableErrorHeaders} resSecHeader={resultSectionHeader} />)
        }
        else if (importReport.summaryData !== undefined && importReport.summaryData.importStatus === uiConstants.ImportStatus.InProgress) {

            //Inprogress popup data
            return ((statusSection.length > 0 ) &&
              <ReportDetailsSummary statusData={statusSection} actionData={resultsSection} reportType={reportType} reportCategory={reportCategory} actionHeader={resultSectionTableErrorHeaders} resSecHeader={resultSectionHeader} />)
        }
    }
    render() {

        var rangeDate = calculateDateFromIndex(this.props.dashboard.selectedDateRange);
        const { importReport } = this.props;

        if (importReport.summaryData !== undefined && importReport.summaryData.data.statusDetailArr !== undefined) {
            //Report Category data
            var reportType = importReport.summaryData.reportTypeId;
            var reportCategory = importReport.summaryData.importStatus;
        }

        return (
          <Modal
            size="lg"
            centered
            dialogClassName="custom-modal modal-xl"
            {...this.props}
          >
              <Modal.Body>
                  <div>
                      <Button variant={"light"} className="close" onClick={this.props.onHide}>
                          <i className="icon-close"></i>
                      </Button>
                      <PopupHeader dat={rangeDate} reportType={reportType} reportCategory={reportCategory} />
                      {
                          importReport.loading &&
                          <div className="text-center">
                              <PageLoader />
                          </div>
                      }
                      {
                          this.renderReportDetails()
                      }
                  </div>
              </Modal.Body>
              <PopupFooter onHide={this.props.onHide} dateRange={rangeDate} reportType={reportType} importStatus={importStatus} />
          </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { dashboard, importReport } = state;
    return {
        dashboard,
        importReport
    };
}

export default connect(mapStateToProps)(ReportDetails);
