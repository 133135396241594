import React from 'react';
import { connect } from 'react-redux';
import Header from '../Common/Header';
import ImportsDashboard from '../Containers/ImportsDashboard';
import ReportDetails from '../Common/ReportDetails';
import CustomDatePicker from '../Common/CustomDatePicker';
import { Container } from 'react-bootstrap';
import { importSummaryActions } from '../../Actions/ImportSummary.Action';
import MetaTags from 'react-meta-tags';
import ScheduleCreate from '../Common/ScheduleCreate';
import ScheduleManage from '../Common/ScheduleManage';
import Footer from '../Common/Footer';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleReportModalClose = this.handleReportModalClose.bind(this);
    this.handleDatePickerModalClose = this.handleDatePickerModalClose.bind(this);
    this.handleScheduleCreateModalClose = this.handleScheduleCreateModalClose.bind(this);
    this.handleScheduleManageModalClose = this.handleScheduleManageModalClose.bind(this);
  }

  handleReportModalClose() {
    this.props.dispatch(importSummaryActions.toggleReportModal());
  }

  handleDatePickerModalClose() {
    this.props.dispatch(importSummaryActions.toggleDatePickerModal());
  }

  handleScheduleCreateModalClose() {
    this.props.dispatch(importSummaryActions.toggleScheduleCreateModal(this.props.dashboard.reportType, this.props.dashboard.selectedDateRange, true));
  }

  handleScheduleManageModalClose() {
    this.props.dispatch(importSummaryActions.toggleScheduleManageModal(this.props.dashboard.reportType,'', this.props.dashboard.selectedDateRange, true));
  }

  render() {
    const { dashboard } = this.props;
    return (
      <div className="App">
        <MetaTags>
          <meta httpEquiv="X-UA-Compatible" content="IE=Edge"></meta>
        </MetaTags>
        <Header />
        <Container className={"mb-5 mt-4"}>
          <ImportsDashboard />
          {dashboard.showReportModal &&
          <ReportDetails
            show={dashboard.showReportModal}
            onHide={this.handleReportModalClose}
          />
          }
          {dashboard.showDatePickerModal &&
          <CustomDatePicker
            show={dashboard.showDatePickerModal}
            onHide={this.handleDatePickerModalClose}
          />
          }
          {dashboard.showScheduleCreateModal &&
          <ScheduleCreate
            show={dashboard.showScheduleCreateModal}
            reportType={dashboard.reportType}
            scheduleId={dashboard.scheduleId}
            onHide={this.handleScheduleCreateModalClose}
          />
          }
          {dashboard.showScheduleManageModal &&
          <ScheduleManage
            show={dashboard.showScheduleManageModal}
            onHide={this.handleScheduleManageModalClose}
          />
          } 
        </Container>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard } = state;
  return {
    dashboard
  };
}

export default connect(mapStateToProps)(App);