import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, ButtonToolbar, Row, Col, Form } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { importSummaryActions } from '../../Actions/ImportSummary.Action';
import { uiConstants } from '../../Constants/UI.Constants';

momentLocalizer();

class CustomDatePicker extends Component {
    constructor(props) {
        super(props);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.validateDate = this.validateDate.bind(this);

        this.fromDate = "";
        this.toDate = "";
    }

    handleOnSubmit() {
        let fromDate = this.fromDate;
        let toDate = this.toDate;

        this.props.dispatch(importSummaryActions.setCustomDates(fromDate, toDate));
        this.props.dispatch(importSummaryActions.setDateRangeFilter(uiConstants.DateRangeFilters.length - 1, moment(fromDate).format('L'), moment(toDate).format('L'), true));
    }

    handleFromDateChange(e) {
        this.fromDate = moment(e).format('L');
        this.validateDate();
    }

    handleEndDateChange(e) {
        this.toDate = moment(e).format('L');
        this.validateDate();
    }

    validateDate() {
        if ((this.fromDate !== "" && this.fromDate !== undefined) && (this.toDate !== "" && this.toDate !== undefined)) {
            let isFromDateInvalid = false;
            let isToDateInvalid = false;
            let disableApplyCustomDates = true;
            let isStartAfterEnd = false;
            let isInvalidRange = false;

            if (!moment(this.fromDate, 'L').isValid()) {
                isFromDateInvalid = true;
            }
            if (!moment(this.toDate, 'L').isValid()) {
                isToDateInvalid = true;
            }

            if (!isFromDateInvalid && !isToDateInvalid) {
                if (moment(this.fromDate).isAfter(this.toDate)) {
                    isStartAfterEnd = true;
                }
                if ((this.fromDate !== this.toDate) && (!moment(this.toDate).isBetween(this.fromDate, moment(this.fromDate).add(90, 'd')))) {
                    isInvalidRange = true;
                }
                if ((moment(this.fromDate).isBefore(moment().subtract(1, 'year').format('L')) || (moment(this.fromDate).isAfter(moment().format('L')))) ||
                    (moment(this.toDate).isBefore(moment().subtract(1, 'year').format('L')) || (moment(this.toDate).isAfter(moment().format('L'))))) {
                    isInvalidRange = true;
                }
            }

            disableApplyCustomDates = !(!isFromDateInvalid && !isToDateInvalid && !isStartAfterEnd && !isInvalidRange);
            this.props.dispatch(importSummaryActions.validateCustomDateRange(isFromDateInvalid, isToDateInvalid, isStartAfterEnd, isInvalidRange, disableApplyCustomDates));
        }
    }

    render() {
        const { dashboard } = this.props;

        return (
            <Modal
                {...this.props}
            >
                <Modal.Header>
                    <h4 className="modal-title">Custom Range</h4>
                    <Button variant={"light"} className="close" onClick={this.props.onHide}>
                        <i className="icon-close"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Form>
                            <div className={"py-2"}>{uiConstants.CustomDateCriteria}</div>
                            <div className={dashboard.disableApplyCustomDates ? "has-error" : ""}>
                                {dashboard.isStartAfterEnd && <Form.Text>{uiConstants.StartDateCantBeAfterEndDate}</Form.Text>}
                                {dashboard.isInvalidRange && <Form.Text>{uiConstants.InvalidDateRange}</Form.Text>}
                            </div>
                            <Form.Group>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group validationState={dashboard.isFromDateInvalid || dashboard.isStartAfterEnd || dashboard.isInvalidRange ? "error" : null}>
                                            <Form.Label htmlFor="StartDate">
                                                Start Date:
                                          </Form.Label>
                                            <DateTimePicker
                                                id="StartDate"
                                                name="StartDate"
                                                time={false}
                                                format={uiConstants.DateRangeDisplayFormat}
                                                placeholder={uiConstants.DateRangeDisplayFormat}
                                                defaultValue={(dashboard.customFromDate === "" || dashboard.customFromDate === undefined) ? null : moment(dashboard.customFromDate).toDate()}
                                                min={moment().subtract(1, 'year').toDate()}
                                                max={moment().toDate()}
                                                views={['month', 'year', 'decade']}
                                                footer={false}
                                                onChange={this.handleFromDateChange}
                                                className={dashboard.isFromDateInvalid || dashboard.isStartAfterEnd || dashboard.isInvalidRange ? "has-error" : ""}
                                            />
                                            <Form.Text>{dashboard.isFromDateInvalid && uiConstants.InvalidDateFormatErrorMessage}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group validationState={dashboard.isToDateInvalid || dashboard.isStartAfterEnd || dashboard.isInvalidRange ? "error" : null}>
                                            <Form.Label htmlFor="EndDate">
                                                End Date:
                                          </Form.Label>
                                            <DateTimePicker
                                                id="EndDate"
                                                name="EndDate"
                                                time={false}
                                                format={uiConstants.DateRangeDisplayFormat}
                                                placeholder={uiConstants.DateRangeDisplayFormat}
                                                defaultValue={(dashboard.customToDate === "" || dashboard.customToDate === undefined) ? null : moment(dashboard.customToDate, 'L').toDate()}
                                                min={moment().subtract(1, 'year').toDate()}
                                                max={moment().toDate()}
                                                views={['month', 'year', 'decade']}
                                                footer={false}
                                                onChange={this.handleEndDateChange}
                                                className={dashboard.isToDateInvalid || dashboard.isStartAfterEnd || dashboard.isInvalidRange ? "has-error" : ""}
                                            />
                                            <Form.Text>{dashboard.isToDateInvalid && uiConstants.InvalidDateFormatErrorMessage}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle={"success"}
                        disabled={dashboard.disableApplyCustomDates}
                        onClick={this.handleOnSubmit}
                    >
                        Apply
                  </Button>
                    <Button className={"btn-default"} onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { dashboard } = state;
    return {
        dashboard
    };
}

export default connect(mapStateToProps)(CustomDatePicker);