import logo from '../Assets/Images/logo-healthstream-large.png';
import $ from 'jquery';
import { uiConstants } from '../Constants/UI.Constants';

function getPrintFormattedData(titleActionSuccess, printWindow, instituionName, dateRange, reportGen, statusDataTable, 
    reportHeader, category, reportType, statusDataCount) {

    //GETTING SUM OF RECORDS ON STATUS TABLE
    var columnRecords = statusDataTable.column(3, { filter: 'applied' }).data();
    var sumOfRecords = 0;
    var columnLength = 0;
    var totalAppend;
    var hrAppend;
    var i = 0;
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    for (i = 0; i < columnRecords.length; i++) {
        sumOfRecords = columnRecords[i] + sumOfRecords;
    }
  
    //APPENDING TOTAL NO OF RECORDS/ NO MATCHING FOUND ROW
    if (sumOfRecords === 0) {
        totalAppend = (statusDataCount) ? uiConstants.PrintNoMatchingRecords : uiConstants.PrintNoRecordsAvailable;
    }
    else {
        totalAppend = uiConstants.PrintTotalSum + sumOfRecords + uiConstants.PrintfooterTagAppend;
    }

    //APPENDING HEADERS ON PRINT
    var printHeaders = uiConstants.PrintImageLogo
        .concat(logo)
        .concat(uiConstants.PrintInstitutionStyle)
        .concat(instituionName)
        .concat("</div><div class='h3'>")
        .concat(reportHeader)
        .concat(uiConstants.PrintDateRange)
        .concat(dateRange)
        .concat(uiConstants.PrintReportGen)
        .concat(reportGen)
        .concat("</span>")
        .concat("<br/><br/>")
        .concat(uiConstants.PrintHrTagAppend);

    //FOR SUCCESS/ERROR WIDGET PRINT
    if (!(category === uiConstants.ImportStatus.InProgress)) {
        let titleAction = titleActionSuccess[0].toUpperCase();
        var table = $('#action').DataTable();
        var data = table.rows({ filter: 'applied' }).data();
        var actionRecordCount = data.length;

        //DYNAMICALLY GETTING THE COLUMN HEADER
        var colhead = [];
        var columns = $('#action').dataTable().dataTableSettings[1].aoColumns;
        $.each(columns, function (i, v) {
            colhead.push(v.sTitle);
        });
        var col = [];
        for (i = 0; i < data.length; i++) {
            for (var key in data[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }
        // CREATE DYNAMIC TABLE.
        var actionTable = document.createElement("table");
        actionTable.className = "table table-striped dataTable";

        // CREATE HTML TABLE HEADER ROW USING THE EXTRACTED HEADERS ABOVE.
        var tr = actionTable.insertRow(-1);
        for (i = 0; i < col.length; i++) {
            var th = document.createElement("th");
            th.width = '100px';
            th.innerHTML = colhead[i];
            if (colhead[i] !== undefined) {
                tr.appendChild(th);
                columnLength = columnLength + 1;
            }
        }
        // ADD JSON DATA TO THE TABLE AS ROWS.
        for (i = 0; i < data.length; i++) {
            tr = actionTable.insertRow(-1);
            for (var j = 0; j < col.length; j++) {
                if (j < columnLength) {
                    var tabCell = tr.insertCell(-1);
                    tabCell.innerHTML = data[i][col[j]];
                }
            }
        }
        // ADDING TOTAL ON SUCCESS SUMMARY TABLE.
        if (category === uiConstants.ImportStatus.Success) {
            tr = actionTable.insertRow(-1);
            tr.style.fontWeight="Bold";
            for (var item = 0; item < col.length; item++) {
                if (item < columnLength) {
                    var tableCell = tr.insertCell(-1);
                    if (item === 0) {
                        tableCell.innerHTML = "Total:";
                    }//THIS IS FOR SUMMING COLUMNS.
                    else { 
                        tableCell.innerHTML = getTotal(item);
                        //THIS IS FOR CUSTOMIZING THE SUM VALUES.
                        if (reportType === 1 && item === 7) 
                        {
                            tableCell.innerHTML = getCustomValue(0) + "/" + getCustomValue(1) + "/" + getCustomValue(2) + "/" + getCustomValue(3);
                        }
                    }
                }
            }
        }
        //CREATING REPORT HEADER ELEMENTS
        var wrapper = document.createElement("h1");
        var tableString = $(actionTable)[0].outerHTML;
        var Actiontext = document.createTextNode(titleAction);
        wrapper.appendChild(Actiontext);
        //NO MATCHING FOUND
        if (actionRecordCount === 0)
            hrAppend = uiConstants.PrintHrTagActionRecord;
        else
            hrAppend = uiConstants.PrintHrTagAppend;
       
        // FINALLY ADD THE NEWLY CREATED TABLE WITH JSON DATA TO A CONTAINER AND APPENDING/PREPENDING CREATED HEADER ELEMENTS.
        $(printWindow.document.body).find('th:last').addClass('display').css('width', '63px')
        $(printWindow.document.body)
            .css('font-size', '10pt')
            .append('<div>' + totalAppend + wrapper.outerHTML + '<br/> <hr style="margin-top:1px">' + tableString + hrAppend + "<div style='padding-top:20px;''> Copyright © " + currentYear + uiConstants.PrintCopyRights + "</div>")
            .prepend(printHeaders);
    }
    //FOR INPROGRESS WIDGET PRINT
    else {

        $(printWindow.document.body).find('th:last').addClass('display').css('width', '63px')
        $(printWindow.document.body)
            .css('font-size', '10pt')
            .append(
                '<div>' + totalAppend + "<div style='padding-top:20px;''> Copyright © " + currentYear + uiConstants.PrintCopyRights + "</div></div>"
            ).prepend(printHeaders);
    }
}

//SUMMING THE VALUES OF GIVEN PARAMETER COLUMN ID
function getTotal(columnInt) {
    var totalAmount = 0;
    var columnActionTotal = $('#action').DataTable().column(columnInt, { filter: 'applied' }).data();
    for (var i = 0; i < columnActionTotal.length; i++) {
        if (isNaN(columnActionTotal[i]))
            return "";
        totalAmount += parseInt(columnActionTotal[i]);
    }
    return totalAmount;
}

//SUMMING THE VALUES OF CUSTOM VALUES FOR THE STUDENT IMPORT REPORT
function getCustomValue(splitArrayId) {
    var totalAmount = 0;
    var columnActionTotal = $('#action').DataTable().column(7, { filter: 'applied' }).data();
    for (var i = 0; i < columnActionTotal.length; i++) {
        var splitTotal = columnActionTotal[i].split("/")[splitArrayId];
        totalAmount += parseInt(splitTotal);
    }
    return totalAmount;
}
export { getPrintFormattedData }