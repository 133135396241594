import axios from 'axios';
import { config } from '../../config';

export const studPortfolioEduImportService = {
    getStudPortfolioEduImportSuccessData,
    getStudPortfolioEduImportInProgressData,
    getStudPortfolioEduImportErrorData,
    getStudPortfolioEduImportDownloadData

}

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Pragma'] = 'no-cache';

//service call to fetch Student Portfolio Education Import Success Details
function getStudPortfolioEduImportSuccessData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/StudPortfolioEduImport/success?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Student Portfolio Education Import In Progress Details
function getStudPortfolioEduImportInProgressData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/StudPortfolioEduImport/inprogress?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Student Portfolio Education Import Error Details
function getStudPortfolioEduImportErrorData(fromDate, toDate, isExport, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/StudPortfolioEduImport/error?fromDate=' + fromDate + '&toDate=' + toDate + '&isExport=' + isExport), {
        headers: headerValues
    });
}

//service call to fetch Student Portfolio Education Import Download Details
function getStudPortfolioEduImportDownloadData(fromDate, toDate, token) {
    let headerValues = JSON.parse(sessionStorage.getItem('headers'));
    headerValues["Authorization"] = token.token_type + " " + token.access_token;
    return axios.get((config.api + '/StudPortfolioEduImport/download?fromDate=' + fromDate + '&toDate=' + toDate ), {
        headers: headerValues
    });
}