//To process student import success Data
function transformStudentImportSuccessData(data) {

    let formattedData;
    let resultStatusDetail = data.StudentImportStatusDetails ? data.StudentImportStatusDetails : [];
    let resultActionDetail = data.StudentImportActionDetails ? data.StudentImportActionDetails : [];
    var statusDetailArr = convertImportData(resultStatusDetail);
    var resultDetailsArr = convertImportData(resultActionDetail);
    var { RecordsTotal, TotalStudentsAdded, TotalStudentsUpdated, TotalStudentsDeleted,
        TotalDepartmentsAdded, TotalJobTitlesAdded, TotalCustomValuesAdded, UserTimeZone } = data;
      
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        TotalStudentsAdded,
        TotalStudentsUpdated,
        TotalStudentsDeleted,
        TotalDepartmentsAdded,
        TotalJobTitlesAdded,
        TotalCustomValuesAdded,
        UserTimeZone
    };

    return formattedData;
}

//To process student import error data.
function transformStudentImportErrorData(data) {

    let formattedData;
    let resultStatusDetail = data.StudentImportStatusDetails ? data.StudentImportStatusDetails : [];
    let resultErrorDetail = data.StudentImportErrorDetails ? data.StudentImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetail);
    var resultDetailsArr = convertImportData(resultErrorDetail);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    };

    return formattedData;
}

//To process Student Job Function Error data
function transformStudentJobFunctionErrorData(data) {
    let formattedData;
    let resultErrorDetails = data.StudentJobFunctionErrorDetails ? data.StudentJobFunctionErrorDetails : [];
    let resultStatusDetails = data.StudentJobFunctionStatusDetails ? data.StudentJobFunctionStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To process Student License Error Data
function transformStudentLicenseErrorData(data) {
    let formattedData;
    let resultErrorDetails = data.StudentLicenseImportErrorDetails ? data.StudentLicenseImportErrorDetails : [];
    let resultStatusDetails = data.StudentLicenseImportStatusDetails ? data.StudentLicenseImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}
//To process Student Job Function Success data
function transformStudentJobFunctionSuccessData(data) {

    let formattedData;
    let resultSuccessDetails = data.StudentJobFunctionSuccessDetails ? data.StudentJobFunctionSuccessDetails : [];
    let resultStatusDetails = data.StudentJobFunctionStatusDetails ? data.StudentJobFunctionStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);
    var { RecordsTotal, Inserts, Updates, Deletes, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        Inserts,
        Updates,
        Deletes,
        UserTimeZone
    }

    return formattedData;
}

//To process Student Import in progress data
function transformStudentImportInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To process Student Job Function in progress data
function transformStudentJobFunctionInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To Process Student License Success data
function transformStudentLicenseSuccessData(data) {

    let formattedData;
    let resultSuccessDetails = data.StudentLicenseImportActionDetails ? data.StudentLicenseImportActionDetails : [];
    let resultStatusDetails = data.StudentLicenseImportStatusDetails ? data.StudentLicenseImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);
    var { RecordsTotal, LicensesAdded, LicensesUpdated, LicensesRemoved, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        LicensesAdded,
        LicensesUpdated,
        LicensesRemoved,
        UserTimeZone
    }
    return formattedData;
}

//To Process Student License InProgress data
function transformStudentLicenseInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To process Role Import in progress data
function transformRoleImportInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To Process Role Import Success data
function transformRoleImportSuccessData(data) {
    let formattedData;
    let resultSuccessDetails = data.RoleImportActionDetails ? data.RoleImportActionDetails : [];
    let resultStatusDetails = data.RoleImportStatusDetails ? data.RoleImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);
    var { RecordsTotal, UserTimeZone, TotalRolesAdded, TotalRolesUpdated, TotalRolesDeleted } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone,
        TotalRolesAdded,
        TotalRolesUpdated,
        TotalRolesDeleted,
    }
    return formattedData;
}

//To Process Role Import Error Data
function transformRoleImportErrorData(data) {
    let formattedData;
    let resultStatusDetails = data.RoleImportStatusDetails ? data.RoleImportStatusDetails : [];
    let resultErrorDetails = data.RoleImportErrorDetails ? data.RoleImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process Supervisor Import in progress data
function transformSupervisorImportInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To Process Supervisor Import Success data
function transformSupervisorImportSuccessData(data) {
    let formattedData;
    let resultSuccessDetails = data.SupervisorImportActionDetails ? data.SupervisorImportActionDetails : [];
    let resultStatusDetails = data.SupervisorImportStatusDetails ? data.SupervisorImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);
    var { RecordsTotal, UserTimeZone, TotalSupervisorsAdded, TotalSupervisorsUpdated, TotalSupervisorsDeleted } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone,
        TotalSupervisorsAdded,
        TotalSupervisorsUpdated,
        TotalSupervisorsDeleted,
    }
    return formattedData;
}

//To Process Supervisor Import Error Data
function transformSupervisorImportErrorData(data) {
    let formattedData;
    let resultStatusDetails = data.SupervisorImportStatusDetails ? data.SupervisorImportStatusDetails : [];
    let resultErrorDetails = data.SupervisorImportErrorDetails ? data.SupervisorImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process StudentCertification Import in progress data
function transformStudentCertificationImportInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}
//To Process Student Certification Import Error Data
function transformStudentcertificationImportErrorData(data) {
    let formattedData;
    let resultStatusDetails = data.StudentCertificationImportStatusDetails ? data.StudentCertificationImportStatusDetails : [];
    let resultErrorDetails = data.StudentCertificationImportErrorDetails ? data.StudentCertificationImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}


//To Process Student Certification Import Success data
function transformStudentCertificationSuccessData(data) {
    let formattedData;
    let resultSuccessDetails = data.StudentCertificationImportActionDetails ? data.StudentCertificationImportActionDetails : [];
    let resultStatusDetails = data.StudentCertificationImportStatusDetails ? data.StudentCertificationImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);
    var { RecordsTotal, UserTimeZone, TotalCertificationsAdded, TotalCertificationsUpdated, TotalCertificationsRemoved } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone,
        TotalCertificationsAdded,
        TotalCertificationsUpdated,
        TotalCertificationsRemoved,
    }
    return formattedData;
}

//To Process Student Badge Import Success data
function transformStudentBadgeImportSuccessData(data)
{
    let formattedData;
    let resultSuccessDetails = data.StudentBadgeImportActionDetails ? data.StudentBadgeImportActionDetails : [];
    let resultStatusDetails = data.StudentBadgeImportStatusDetails ? data.StudentBadgeImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);   
    var { RecordsTotal,  UserTimeZone, TotalBadgesAdded, TotalBadgesUpdated, TotalBadgesRemoved } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone,
        TotalBadgesAdded,
        TotalBadgesUpdated,
        TotalBadgesRemoved,
    }
    return formattedData;
}
//To Process Student Badge Import Error data
function transformStudentBadgeImportErrorData(data)
{
    let formattedData;
    let resultStatusDetails = data.StudentBadgeImportStatusDetails ? data.StudentBadgeImportStatusDetails : [];
    let resultErrorDetails = data.StudentBadgeImportErrorDetails ? data.StudentBadgeImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process Student Badge Import In Progress data
function transformStudentBadgeImportInProgressData(data) {
    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}


//To Process Student Education History Import Success data
function transformStuEduHistoryImportSuccessData(data)
{
    let formattedData;
    let resultSuccessDetails = data.StudEduHistoryImportActionDetails ? data.StudEduHistoryImportActionDetails : [];
    let resultStatusDetails = data.StudEduHistoryImportStatusDetails ? data.StudEduHistoryImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);   
    var { RecordsTotal,  UserTimeZone, TotalRecordsAdded, TotalRecordsUpdated, TotalRecordsRemoved } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone,
        TotalRecordsAdded,
        TotalRecordsUpdated,
        TotalRecordsRemoved
    }
    return formattedData;
}

//To Process Student Education History Import Error Data
function transformStudEduHistoryImportErrorData(data) {
    let formattedData;
    let resultStatusDetails = data.StudEduHistoryImportStatusDetails ? data.StudEduHistoryImportStatusDetails : [];
    let resultErrorDetails = data.StudEduHistoryImportErrorDetails ? data.StudEduHistoryImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process Job Title Import InProgress Data
function transformJobTitleImportInProgressData(data) {
    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To Process Supplemental Manager Import InProgress Data
function transformSupplementalManagerImportInProgressData(data) {
    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To Process Student Education History Import Error Data
function transformStudEduHistoryImportInProgressData(data) {
    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process Job Title Import Error Data
function transformJobTitleImportErrorData(data) {
    let formattedData;
    let resultStatusDetails = data.JobTitleImportStatusDetails ? data.JobTitleImportStatusDetails : [];
    let resultErrorDetails = data.JobTitleImportErrorDetails ? data.JobTitleImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process Supplemental Manager Import Error Data
function transformSupplementalManagerImportErrorData(data) {
    let formattedData;
    let resultStatusDetails = data.SupplementalManagerImportStatusDetails ? data.SupplementalManagerImportStatusDetails : [];
    let resultErrorDetails = data.SupplementalManagerImportErrorDetails ? data.SupplementalManagerImportErrorDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultErrorDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;

}

//To Process Job Title Import InProgress data
function transformJobTitleInProgressData(data) {

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal, UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
}

//To Process Job Title Import Success data
function transformJobTitleImportSuccessData(data)
{
    let formattedData;
    let resultSuccessDetails = data.JobTitleImportSuccessDetails? data.JobTitleImportSuccessDetails : [];
    let resultStatusDetails = data.JobTitleImportStatusDetails ? data.JobTitleImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var resultDetailsArr = convertImportData(resultSuccessDetails);   
    var { RecordsTotal,  UserTimeZone, TotalRecordsAdded, TotalRecordsUpdated, TotalRecordsRemoved } = data;
    formattedData = {
        statusDetailArr,
        resultDetailsArr,
        RecordsTotal,
        UserTimeZone,
        TotalRecordsAdded,
        TotalRecordsUpdated,
        TotalRecordsRemoved
    }
    return formattedData;
}

// To process Student Portfolio Education Import Inprogress Data
 function transformStudPortfolioEduImportInProgressData(data){

    let formattedData;
    let resultStatusDetails = data.ImportStatusDetails ? data.ImportStatusDetails : [];

    var statusDetailArr = convertImportData(resultStatusDetails);
    var { RecordsTotal , UserTimeZone } = data;
    formattedData = {
        statusDetailArr,
        RecordsTotal,
        UserTimeZone
    }
    return formattedData;
 }
// To Process Student Portfolio Education Import Success Data
 function transformStudPortfolioEduImportSuccessData(data)
 {
     let formattedData;     
     let resultSuccessDetails = data.StudPortfolioEduImportSuccessDetails ? data.StudPortfolioEduImportSuccessDetails : [];
     let resultStatusDetails = data.StudPortfolioEduImportStatusDetails ? data.StudPortfolioEduImportStatusDetails : [];

     var statusDetailArr = convertImportData(resultStatusDetails);
     var resultDetailsArr = convertImportData(resultSuccessDetails);
     var { RecordsTotal, UserTimeZone, TotalEducationsAdded,TotalEducationsUpdated,TotalEducationsRemoved } = data;
     formattedData = {
         statusDetailArr,
         resultDetailsArr,
         RecordsTotal,
         UserTimeZone,
         TotalEducationsAdded,
         TotalEducationsUpdated,
         TotalEducationsRemoved
     }
     return formattedData;
 }

 // To Process Student Portfolio Education Import Error Data
 function transformStudPortfolioEduImportErrorData(data){
     let formattedData;
     let resultStatusDetails = data.StudPortfolioEduImportStatusDetails ? data.StudPortfolioEduImportStatusDetails : []; 
     let resultErrorDetails = data.StudPortfolioEduImportErrorDetails ? data.StudPortfolioEduImportErrorDetails : [];

     var statusDetailArr = convertImportData(resultStatusDetails);
     var resultDetailsArr = convertImportData(resultErrorDetails);
     var { RecordsTotal,UserTimeZone } = data;
     formattedData = {
         statusDetailArr,
         resultDetailsArr,
         RecordsTotal,
         UserTimeZone
     }
     return formattedData;
    
 }

//Convert report result detail
function convertImportData(resultDetail) {
    let data = [];
    if (resultDetail !== undefined && resultDetail.length > 0) {
        data = resultDetail.map(Object.values);
    }
    return data;
}

export {
    transformStudentImportInProgressData,
    transformStudentImportSuccessData,
    transformStudentImportErrorData,
    transformStudentJobFunctionInProgressData,
    transformStudentJobFunctionErrorData,
    transformStudentJobFunctionSuccessData,
    transformStudentLicenseSuccessData,
    transformStudentLicenseErrorData,
    transformStudentLicenseInProgressData,
    transformRoleImportInProgressData,
    transformRoleImportSuccessData,
    transformRoleImportErrorData,
    transformSupervisorImportSuccessData,
    transformSupervisorImportInProgressData,
    transformSupervisorImportErrorData,
    transformStudentCertificationImportInProgressData,
    transformStudentcertificationImportErrorData,
    transformStudentCertificationSuccessData,
    transformStudentBadgeImportInProgressData,
    transformStudentBadgeImportErrorData,
    transformStudentBadgeImportSuccessData,
    transformStudEduHistoryImportErrorData,
    transformStuEduHistoryImportSuccessData,
    transformStudEduHistoryImportInProgressData,
    transformJobTitleImportSuccessData,
    transformJobTitleInProgressData,
    transformJobTitleImportErrorData,
    transformJobTitleImportInProgressData,
    transformSupplementalManagerImportInProgressData,
    transformSupplementalManagerImportErrorData,
    transformStudPortfolioEduImportInProgressData,
    transformStudPortfolioEduImportSuccessData,
    transformStudPortfolioEduImportErrorData
}